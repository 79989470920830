import React from 'react';
import { Blackout, Wrapper, Background, Content, Message, Button, Title, Close } from './style';

// Popup component is deprecated, use Modal.jsx

const Popup = ({ isOpen, onOk, onCancel, ok, cancel, title, children, ...props }) => {
	return (
		isOpen && (<Blackout>
			<Wrapper>
                <Background>
                    <Title>
                        {title || 'Popup'}
                        <Close onClick={onOk}></Close>
                    </Title>
                </Background>
                <Content>
                    <Message>{children}</Message>
                    <Button onClick={onOk}>{ok === true ? 'ok' : ok ? ok : 'ok'}</Button>
                    {cancel && <Button onClick={onCancel}>{cancel === true ? 'Cancel' : cancel}</Button>}
                </Content>
            </Wrapper>
		</Blackout>)
	);
};

export default Popup;
