import styled from 'styled-components';
import { colors, ContentGrid } from '../../sharedStyles';

export const Content = styled(ContentGrid)`
	grid-row: 2;
	grid-template-columns: 70px 1fr;
	grid-template-rows: 2fr 1fr;

	@media screen and (min-width: 321px) {
		grid-template-columns: 90px 1fr;
	}
`;

export const Input = styled.input`
	background-color: rgba(${colors.mainBlackOP}, 0.1);
	outline: none;
	width: 100%;
	height: 50px;
	border: none;
	border-radius: 0px;
	border-bottom: 1px solid ${({ error }) => (error ? 'red' : colors.mainBlack)};
	padding: 15px 10px 15px 10px;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 140%;

	&::placeholder {
		color: ${colors.mainBlack};
		opacity: 0.7;
	}
`;

export const VerticalContent = styled.div`
	display: flex;
	margin-left: 20px;
	grid-row: 1;
	grid-column: 1;
`;

export const MainContent = styled.div`
	grid-row: 1;
	grid-column: 2;
	justify-self: end;
	width: 90%;
	display: grid;
	grid-template-rows: 1fr 1fr;
	height: 17vh;
	row-gap: 10px;
`;

export const Text = styled.div`
	margin-bottom: 3px;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 16px;
	letter-spacing: 0.05em;
`;

export const WrapMobile = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 0.3fr 2fr;

	& > ${Text} {
		grid-column: 1;
		grid-row: 1;
	}

	& > ${Input} {
		grid-column: 1;
		grid-row: 2;
	}
`;
