import styled from 'styled-components';

export const PinWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding: 0 15%;
`;
