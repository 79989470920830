import React from 'react';
import PropTypes from 'prop-types';
import { Trapezoid } from '../Trapezoid';
import { Wrapper } from './style';

export const BackgroundWithTrapezoid = (props) => {
	const {
		black,
		color,
		fixed,
	} = props;
	return (
		<Wrapper black={black} color={color} fixed={fixed}>
			<Trapezoid top />
		</Wrapper>
	);
};

BackgroundWithTrapezoid.propTypes = {
	black: PropTypes.bool,
	fixed: PropTypes.bool,
	color: PropTypes.string,
};
