import styled from 'styled-components';
import { colors } from '../sharedStyles';

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	background-color: ${({ white }) => (white ? '#ffffff' : colors.darkOrange)};
	display: grid;
	grid-row: 2;
	grid-column: 1 / -1;
	grid-template-areas: 'icon title icon2' 'icon text icon2';
	grid-template-rows: 1fr 1fr;
	grid-template-columns: auto 4fr auto;
	align-self: start;
	padding: 20px 0 20px 0;
`;

export const Title = styled.div`
	width: 100%;
	height: 100%;
	font-weight: bold;
	font-size: 18px;
	letter-spacing: 0.05em;
	display: flex;
	align-items: center;
	grid-area: title;
`;

export const Text = styled(Title)`
	font-weight: normal;
	font-size: 13px;
	opacity: 0.6;
	grid-area: text;
`;

export const RightButton = styled.div`
	width: 24px;
	height: 24px;
	position: relative;
	align-self: center;
	justify-self: center;
	grid-area: icon2;
	margin: 0 20px;

	&:before {
		position: absolute;
		content: '';
		width: 100%;
		height: 100%;
		background-image: ${({ icon }) => (icon ? `url(${icon})` : `url()`)};
		background-repeat: no-repeat;
		background-size: 100%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;

export const LeftButton = styled(RightButton)`
	width: 40px;
	height: 40px;
	background-color: ${colors.mainBlack};
	grid-area: icon;
	margin: 0 20px;

	&:before {
		position: absolute;
		content: '';
		width: 24px;
		height: 24px;
		background-image: ${({ icon }) => (icon ? `url(${icon})` : `url()`)};
		background-repeat: no-repeat;
		background-size: 100%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;
