import styled from 'styled-components';

import { BackButton } from '../sharedStyles';

export const ContentWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

export const Content = styled.div`
    padding: 20px;
`;

export const Button = styled(BackButton)`
    position: relative;
    
    &:after {
        position: absolute;
        content: '';
        right: 15px;
        width: 14px;
        height: 18px;
        background: url(${({ icon }) => icon});
        background-repeat: no-repeat;
        background-size: 100%;
    }
`;

export const InputWithLabel = styled.div`
    width: 90%;
    align-self: flex-end;
    padding: 30px 0 10px 0;
`