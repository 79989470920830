const initState = [
	{ accountName: 'AIB', api: 'aib' },
	{ accountName: 'Atom Bank' },
	{ accountName: 'Bank of Ireland', api: 'boi' },
	{ accountName: 'Bank of Scotland', api: 'bos' },
	{ accountName: 'Barclays', api: 'barclays' },
	{ accountName: 'Barclays Business' },
	{ accountName: 'Citibank' },
	{ accountName: 'Clydesdale Bank' },
	{ accountName: 'Coutts' },
	{ accountName: 'Danske', api: 'danske' },
	{ accountName: 'Danske TEST', api: 'danske-test' },
	{ accountName: 'First Direct' },
	{ accountName: 'First Trust Bank' },
	{ accountName: 'HSBC', api: 'hsbc' },
	{ accountName: 'Halifax', api: 'halifax' },
	{ accountName: 'Lloyds Bank', api: 'lloyds' },
	{ accountName: 'Marks & Spencer', api: 'marks_spencer' },
	{ accountName: 'Metro Bank' },
	{ accountName: 'Monzo', api: 'monzo' },
	{ accountName: 'Nationwide', api: 'nationwide' },
	{ accountName: 'Natwest', api: 'natwest' },
	{ accountName: 'Revolut' },
	{ accountName: 'Royal Bank of Scotland', api: 'rbs' },
	{ accountName: "Sainsbury's Bank" },
	{ accountName: 'Santander', api: 'santander' },
	{ accountName: 'Starling' },
	{ accountName: 'TSB', api: 'tsb' },
	{ accountName: 'Tesco Bank', api: 'tesco' },
	{ accountName: 'The Co-Operative Bank' },
	{ accountName: 'Ulster Bank', api: 'ulsterbank' },
	{ accountName: 'Vanquis' },
];

const banksData = (state = initState, action) => {
	switch (action.type) {
		case 'BANKS_DATA_RECEIVED':
			return [...action.banksData];
		case 'BANKS_DATA_REQUEST':
		default:
			return state;
	}
};

export default banksData;
