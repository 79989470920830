import React, { useState } from 'react';
import styled from 'styled-components';

import { Background, ClearLayout, Text, ButtonWrap, NextButton } from '../sharedStyles';
import { PlainHeader, SubHeader, ButtonWLeftIcon, Popup } from '../../components';
import { iconSettings, bankInvert } from '../../assets/images/icons';

const BankScreen = ({ history }) => {
    const [open, setOpen] = useState(false);

    const togglePopup = () => {
        setOpen(current => !current);
    }

	return (
		<Background>
			<ClearLayout tRows={'1fr 1fr 8fr'}>
				<PlainHeader
					text={'Bank'}
					controls={{ cross: true }}
					rClick={() => history.goBack}
				/>
				<SubHeader
                    // TODO: dynamic bank
                    leftIcon={bankInvert}
                    rightIcon={iconSettings}
                    rClick={() => history.push('/bank-settings')}
                    title={'Barclays'}
                    text={'Bank'}
                />
                <ContentWrap>
                    <Text bold uppercase margin={'10px 0 30px 0'}>add bank account?</Text>
                    <Text small margin={'0 0 20px 0'}>- By linking specific accounts we can make the payment journey even quicker for you.</Text>
                    <Text small margin={'0 0 30px 0'}>- If you choose to, you can view all your bank account transaction here in one place, not just the bopp payments</Text>
                    <ButtonWLeftIcon onClick={() => history.push('/bank-linked-accounts')} icon='plus'>
						Link account
					</ButtonWLeftIcon>
                </ContentWrap>
                <ButtonWrap row={4}>
                    <NextButton onClick={history.goBack}>Close</NextButton>
                </ButtonWrap>
			</ClearLayout>
            <Popup title={'Bank Added'} ok isOpen={open} onOk={togglePopup}>
                You successfully added the ((Bank_Name)) to Bopp
            </Popup>
		</Background>
	);
};

export default BankScreen;

const ContentWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    height: 100%;
`;