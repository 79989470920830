import axios from 'axios';
import { put, all, takeLatest, take, select, call, retry } from 'redux-saga/effects';
import { fetchedBanksData, fetchedContactsData } from '../actions';
import { generateUUID, parseJwt } from '../utils';
import {
	BANKS_DATA_REQUEST,
	CONTACTS_DATA_REQUEST,
	REQUEST_PAYMENT_LINK,
	GENERATE_BOPP_PAYEE_PAYMENT_LINK,
	SUBMIT_PAYMENT,
	REHYDRATE,
	REDIRECT,
	ERROR_REQUEST_PAYMENT_LINK,
	PENDING,
	BOPP_PAYEE_PAYMENT_LINK,
	PAYMENT_LINK_RESPONSE,
	SAVE_CONTACT,
	ADD_RECEIVER_BANK_ACC,
	SET_PREFER_RECEIVER_ACC,
} from '../actions/constants';

const devBankState = [
	{ name: 'Barclays', details: '' },
	{ name: 'Lloyds', details: '' },
	{ name: 'Natwest', details: '' },
	{ name: 'Monzo', details: '' },
	{ name: 'Danske', details: '' },
];

// const Dev_contactState = [
// 	{ name: 'Ian Gass Starling', details: { accountNumber: '12345678', sortCode: '121212' } },
// 	{ name: 'Migle V Lloyds', details: { accountNumber: '12345678', sortCode: '121212' } },
// ];

const generateReqData = (recipientData) => {
	const endToEndId = generateUUID();
	const instructionId = generateUUID();
	return {
		sortCode: recipientData.account.sortCode.split(' ').join('-'),
		accountNumber: recipientData.account.accountNumber.split(' ').join(''),
		total: recipientData.amount,
		name: recipientData.account.accountName,
		paymentReference: 'ref',
		endToEndIdentification: endToEndId,
		instructionIdentification: instructionId,
	};
};

async function generatePaymentLink(recipientData, bank = 'tsb', data) {
	const url = process.env.REACT_APP_OBD_API_LINK + process.env.REACT_APP_GENERATE_PAYMENT_LINK_API + bank;
	return axios({
		url,
		method: 'post',
		data,
	});
}

const submitLink = (data) => {
	const { code, paymentConsent, paymentRequestData, bank } = data;
	const url = process.env.REACT_APP_OBD_API_LINK + process.env.REACT_APP_SUBMIT_PAYMENT_API + bank;

	return axios({
		url,
		method: 'post',
		data: {
			code,
			paymentConsent,
			paymentRequestData,
		},
	});
};

function* processOutgoingPayment(code) {
	try {
		const localData = JSON.parse(localStorage.lastTransaction);
		yield put({ type: REHYDRATE, localData });
		const dataTosubmit = { ...localData, code };
		const { data } = yield retry(10, 2000, submitLink, dataTosubmit);

		if (data && data.Data && data.Data.DomesticPaymentId) {
			const url = `${process.env.REACT_APP_OBD_API_LINK}${process.env.REACT_APP_DOMESTIC_PAYMENT_INFO}${dataTosubmit.bank}/${data.Data.DomesticPaymentId}`;
			const {
				data: { Data },
			} = yield retry(10, 2000, axios, {
				url,
				headers: { Authorization: `Bearer ${dataTosubmit.access_token}` },
				method: 'get',
			});

			console.log('Status : ', Data.Status);

			if (Data && Data.Status && Data.Status.includes('Accepted')) {
				yield put({ type: REDIRECT });
			}
		}
	} catch (err) {
		console.log(err);
		yield put({ type: ERROR_REQUEST_PAYMENT_LINK });
	}
}

const submitToOB = async (url, data) => {
	return axios({
		url,
		method: 'post',
		mode: 'no-cors',
		data,
	});
};

function* processBoppButtonPayment(code, idToken) {
	try {
		const { openbanking_intent_id: openbankingIntentId } = parseJwt(idToken);
		const URL = `${process.env.REACT_APP_OB_PROCESSOR_API_URL}/api/submit/${code}/${openbankingIntentId}`;
		const submitConsentRequest = {
			paymentConsent: idToken,
		};
		const { data } = yield retry(10, 2000, submitToOB, URL, submitConsentRequest);
		console.log('response data', data);
	} catch (err) {
		console.log(err);
		yield put({ type: ERROR_REQUEST_PAYMENT_LINK });
	}
}

function* submitPayment() {
	while (true) {
		const { code, state, idToken } = yield take(SUBMIT_PAYMENT);
		yield put({ type: PENDING });

		if ((code && !state) || (code && state === '-')) {
			yield call(processOutgoingPayment, code);
		}

		if (code && state === 'bopp-button') {
			yield call(processBoppButtonPayment, code, idToken);
		}
	}
}

function* requestPaymentLink() {
	while (true) {
		try {
			yield take(REQUEST_PAYMENT_LINK);
			yield put({ type: PENDING });

			const [recipientData, bank] = yield select((state) => [
				state.dataForOutgoingPayment,
				state.preferableMethods.api,
			]);
			const reqData = generateReqData(recipientData);
			const { data } = yield call(generatePaymentLink, recipientData, bank, reqData);

			yield put({ type: PAYMENT_LINK_RESPONSE, payload: data });

			localStorage.lastTransaction = JSON.stringify({
				paymentConsent: data.paymentConsent,
				access_token: data.accessToken.access_token,
				paymentRequestData: reqData,
				bank,
			});
		} catch (err) {
			yield put({ type: ERROR_REQUEST_PAYMENT_LINK });
		}
	}
}

function* getPayeeData() {
	const payeeData = yield select((state) => ({
		total: state.accountDetails.amount,
		...state.accountDetails.account,
	}));
	/* eslint prefer-template: off */
	const link =
		process.env.REACT_APP_BOPP +
		`/send-payment?total=${payeeData.total}` +
		`&acc=${payeeData.accountNumber.split(' ').join('')}` +
		`&sort=${payeeData.sortCode.split(' ').join('-')}` +
		`&name=${payeeData.accountName}`;
	yield put({ type: BOPP_PAYEE_PAYMENT_LINK, link });
}

function* boppGetPaymentLink() {
	yield takeLatest(GENERATE_BOPP_PAYEE_PAYMENT_LINK, getPayeeData);
}

function* fetchContactsData() {
	const data = localStorage.contactsData ? JSON.parse(localStorage.contactsData) : [];
	yield put(fetchedContactsData(data));
}

function* fetchBanksData() {
	yield put(fetchedBanksData(devBankState));
}

function* requestBanks() {
	yield takeLatest(BANKS_DATA_REQUEST, fetchBanksData);
}

function* requestContacts() {
	yield takeLatest(CONTACTS_DATA_REQUEST, fetchContactsData);
}

function saveContactToLocalStorage({ contact }) {
	const data = localStorage.contactsData ? JSON.parse(localStorage.contactsData) : [];
	data.push(contact);
	localStorage.contactsData = JSON.stringify(data);
}

function saveReceiverAccountToLocalStorage({ acc }) {
	const data = localStorage.receiveAccounts ? JSON.parse(localStorage.receiveAccounts) : [];
	data.push(acc);
	localStorage.receiveAccounts = JSON.stringify(data);
}

function savePrefAccToLocalStorage({ acc }) {
	localStorage.prefAcc = JSON.stringify(acc);
}

function* saveContact() {
	yield takeLatest(SAVE_CONTACT, saveContactToLocalStorage);
}

function* saveReceiver() {
	yield takeLatest(ADD_RECEIVER_BANK_ACC, saveReceiverAccountToLocalStorage);
}

function* savePrefAcc() {
	yield takeLatest(SET_PREFER_RECEIVER_ACC, savePrefAccToLocalStorage);
}

export default function* rootSaga() {
	yield all([
		savePrefAcc(),
		saveContact(),
		saveReceiver(),
		requestBanks(),
		requestContacts(),
		requestPaymentLink(),
		boppGetPaymentLink(),
		submitPayment(),
	]);
}
