import {
	SCAN_SUCCESS,
	SET_PAYMENT_FROM_URL,
	SET_PAYEE_ACCOUNT,
	REHYDRATE,
	SET_PAYMENT_AMOUNT,
	CLEAR_DATA_OF_PAYMENT_RECIPIENT,
} from '../actions/constants';

const initialState = { account: '', amount: '0.00' };

const dataForOutgoingPayment = (state = initialState, action) => {
	switch (action.type) {
		case REHYDRATE:
			const { paymentRequestData, bank } = action.localData;
			const reh = {
				amount: paymentRequestData.total,
				account: {
					sortCode: paymentRequestData.sortCode,
					accountNumber: paymentRequestData.accountNumber,
					accountName: paymentRequestData.name,
					bank,
				},
			};
			return { ...reh };
		case SCAN_SUCCESS:
			return { ...action.qrData };
		case SET_PAYMENT_FROM_URL:
			return { ...action.queryData };
		case SET_PAYEE_ACCOUNT:
			return { ...state, account: action.account };
		case SET_PAYMENT_AMOUNT:
			return { ...state, amount: action.amount };
		case CLEAR_DATA_OF_PAYMENT_RECIPIENT:
			return { ...initialState };
		default:
			return state;
	}
};

export default dataForOutgoingPayment;
