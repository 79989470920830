import styled from 'styled-components';
import { colors } from '../sharedStyles';
import * as icons from '../../assets/images/icons';

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	background-color: ${({ white }) => (white ? '#ffffff' : colors.darkOrange)};
	display: grid;
	grid-template-rows: ${({ extend }) => (extend ? '1fr 1fr' : '1fr')};
	grid-template-columns: 1fr 4fr 1fr;
	position: relative;
	grid-row: 1;
`;

export const Text = styled.div`
	grid-row: 1;
	grid-column: 2;
	width: 100%;
	height: 100%;
	font-weight: bold;
	font-size: 18px;
	line-height: 100%;
	letter-spacing: 0.05em;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const RightButton = styled.div`
	width: 24px;
	height: 24px;
	position: relative;
	align-self: center;
	justify-self: center;
	grid-row: 1;

	&:before {
		position: absolute;
		content: '';
		width: 100%;
		height: 100%;
		background-image: ${({ cross, edit, burger }) => {
			if (cross) {
				return `url(${icons.close})`;
			}
			if (edit) {
				return `url(${icons.edit})`;
			}
			if (burger) {
				return `url(${icons.burger})`;
			}
		}};
		background-repeat: no-repeat;
		background-size: 100%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;

export const LeftButton = styled(RightButton)`
	&:before {
		position: absolute;
		content: '';
		width: 100%;
		height: 100%;
		background-image: ${({ back, plus, search }) => {
			if (back) {
				return `url(${icons.arrowBack})`;
			}
			if (plus) {
				return `url(${icons.thinPlus})`;
			}
			if (search) {
				return `url(${icons.iconSearch})`;
			}
		}};
		background-repeat: no-repeat;
		background-size: 100%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;
