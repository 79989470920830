import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PlainHeader } from '../../components';
import {
	Background,
	ClearLayout,
} from '../../components/sharedStyles';
import { WithAccount } from './WithAccount';
import { WithoutAccount } from './WithoutAccount';

const RequestPayment = (props) => {
	const {
		amount,
		listOfBanks,
		history,
		prefAcc,
	} = props;
	const clickBack = () => history.push('/');

	return (
		<Background>
			<ClearLayout tRows="1fr 10fr">
				<PlainHeader lClick={clickBack} controls={{ back: true }} text="Request Payment" />
				{ listOfBanks.length
					? <WithAccount amount={amount} prefAcc={prefAcc} />
					: <WithoutAccount /> }
			</ClearLayout>
		</Background>
	);
};

const mapStateToProps = (state) => ({
	amount: state.accountDetails.amount,
	listOfBanks: state.receiverListOfBanks,
	prefAcc: state.preferableReceiverAccount,
});

export const RequestPaymentPage = connect(mapStateToProps, null)(RequestPayment);

RequestPayment.propTypes = {
	listOfBanks: PropTypes.arrayOf(PropTypes.object).isRequired,
	amount: PropTypes.string.isRequired,
	prefAcc: PropTypes.shape({
		accountName: PropTypes.string,
		accountNumber: PropTypes.string,
		sortCode: PropTypes.string,
	}).isRequired,
};
