import React from 'react';
import { Layout, ButtonWithArrow } from '../../components';
import styled from 'styled-components';
import { VerticalText, Text, colors } from '../sharedStyles';
import { Link, withRouter } from 'react-router-dom';

const Signup = ({ history }) => {
	return (
		<Layout cross goBack={history.goBack}>
			<ContentWrap>
				<VerticalText>Signup</VerticalText>
				<Content>
					<Text color={`rgba(${colors.mainBlackOP}, 0.6)`}>Please, select a prefered signup method</Text>
					<SignupButton to='/signup/step'>
						<span>Signup</span>
					</SignupButton>
				</Content>

				<ButtonWithArrow
					bottom
					reverse
					shorttext
					to={sessionStorage.phone || localStorage.phone ? '/login' : '/first-login'}
					header={'Login'}
					descr={'Already have an account?'}
				/>
			</ContentWrap>
		</Layout>
	);
};

export default withRouter(Signup);

const ContentWrap = styled.div`
	grid-row: 2;
	display: grid;
	grid-template-rows: 1fr;
	grid-auto-columns: 50px 1fr;
	grid-column-gap: 10px;

	@media screen and (min-width: 321px) {
		grid-auto-columns: 70px 1fr;
		grid-column-gap: 30px;
	}
`;

const Content = styled.div`
	grid-column: 2;
	display: flex;
	flex-direction: column;
	padding-right: 20px;
`;

export const SignupButton = styled(Link)`
	height: 56px;
	width: 100%;
	background-color: ${colors.mainBlack};
	color: #ffffff;
	font-style: normal;
	font-weight: 800;
	font-size: 16px;
	line-height: 22px;
	text-transform: uppercase;
	display: flex;
	justify-content: center;
	align-items: center;
`;
