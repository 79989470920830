import styled from 'styled-components';
import { colors, CardWithShadow } from '../sharedStyles';
import * as icons from '../../assets/images/icons';

export const Card = styled(CardWithShadow)``;


export const DescriptionContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	flex-wrap: wrap;
	width: 180px;
`;

Card.Header = styled.div`
	font-size: ${({ size }) => size || '15px'};
	line-height: 140%;
	color: ${colors.mainBlack};
	font-style: normal;
	font-weight: bold;
	margin-bottom: 5px;
	display: flex;
	justify-content: space-between;
`;

Card.Description = styled.div`
	font-size: 13px;
	line-height: 130%;
	color: rgba(${colors.mainBlackOP}, 0.4);
	font-style: normal;
	font-weight: normal;
	letter-spacing: 0.04em;
`;

Card.DescriptionKey = styled(Card.Description)`
	color: ${colors.mainBlack};
	display: inline;
	width: 80px;
`;

Card.Icon = styled.div`
	width: 40px;
	height: 40px;
	background-size: 100%;
	background-repeat: no-repeat;
	margin-bottom: 10px;
	background-image: ${({ type }) => (icons[type] ? `url(${icons[type]})` : `url()`)};
`;
