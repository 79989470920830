import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import { colors } from '../../sharedStyles';
import { logo } from '../../../assets/images/icons';
import { trapezoid } from '../../../assets/images';


const Splash = props => {
	const [element, setElement] = useState(null);
	const [doc, setDoc] = useState(null);

	useEffect(() => setElement(document.createElement('div')), []);
	useEffect(() => setDoc(document), []);

	useEffect(() => {
		if (element && doc) {
			doc.body.appendChild(element);
			return () => {
				doc.body.removeChild(element);
			};
		}
	}, [element, doc]);

	if (!element) {
		return null;
	}
	return ReactDOM.createPortal(
		<>
			{props.show && (
				<Wrapper>
					<Content>
						<Loader>
							<div className='spinner'></div>
							<Loader.Text>Loading..</Loader.Text>
						</Loader>
						<Text>{props.children}</Text>
					</Content>
					<Figure>
						<VerticalText>Your personal payment assistant</VerticalText>
						<Figure.Logo />
					</Figure>
				</Wrapper>
			)}
		</>,
		element
	);
};

export default Splash;

export const VerticalText = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	width: 40px;
	height: 100%;
	display: block;
	transform: translateY(-18%);
	text-transform: uppercase;
	font-style: normal;
	font-weight: 800;
	font-size: 14px;
	line-height: 24px;
	text-align: right;
	text-transform: uppercase;
	text-align: left;
	writing-mode: vertical-lr;
`;

const Loader = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	& > .spinner {
		margin-bottom: 50px;
	}
`;
Loader.Text = styled.div`
	font-style: normal;
	font-weight: 800;
	font-size: 14px;
	line-height: 24px;
	text-align: center;
	text-transform: uppercase;
`;

const Wrapper = styled.div`
	position: absolute;
	background: ${colors.mainOrange};
	width: 100vw;
	height: 100vh;
	top: 0;
	z-index: 5;
	display: flex;
	justify-content: center;
	grid-column: 1;
`;

const Content = styled.div`
	z-index: 6;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	padding: 20px 20px 60px;
`;

const Text = styled.div`
	font-family: Muli;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 140%;
	align-items: center;
	text-align: center;
	letter-spacing: 0.05em;
	margin-top: 10px;
`;

const Figure = styled.div`
	width: 100vw;
	height: 300px;
	background-repeat: no-repeat;
	background-size: 100%;
	background-image: url(${trapezoid});
	position: absolute;
	z-index: 10;
	top: 46%;
	transform: translateY(-50%);
`;

Figure.Logo = styled.div`
	position: absolute;
	background-image: url(${logo});
	background-repeat: no-repeat;
	background-size: 100%;
	top: 0;
	transform: translate(70%, -5%);
	width: 40%;
	height: 200px;
`;
