import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { colors } from '../sharedStyles';
import * as icons from '../../assets/images/icons';
import arrowNext from '../../assets/images/arrow-next.svg';

export const ButtonWLeftIcon = (props) => {
	const {
		alignSelf,
		children,
		to,
		icon,
		twoRows,
		desc,
		width,
		white,
		disabled,
		largeIcon,
		rightIcon,
		onClick,
		change,
		arrow,
		error,
		stretch,
	} = props;

	const InnerElement = () => {
		if (twoRows) {
			if (typeof desc === 'string') {
				return (
					<>
						<h5>{children}</h5>
						<p>{desc.charAt(0).toUpperCase() + desc.slice(1)}</p>
						{change && <Optional>Change</Optional>}
					</>
				);
			}

			if (typeof desc === 'object') {
				const descriptionString = Object.values(desc).join(' ');
				return (
					<>
						<h5>{children}</h5>
						<p>{descriptionString}</p>
						{change && <Optional>Change</Optional>}
					</>
				);
			}
		}

		return <Span white={white}>{children}</Span>;
	};

	return (
		<Wrapper
			stretch={stretch}
			alignSelf={alignSelf}
			twoRows={twoRows}
			width={width}
			rightIcon={rightIcon}
			onClick={onClick}
		>
			{to ? (
				<ButtonLink to={to} disabled={disabled} white={white ? 1 : 0} arrow={arrow ? 1 : 0}>
					<Icon error={error} icon={icon} largeIcon={largeIcon} white={white} disabled={disabled} />
					<TextWrap twoRows={twoRows}>
						<InnerElement />
					</TextWrap>
				</ButtonLink>
			) : (
				<Button disabled={disabled} white={white ? 1 : 0} arrow={arrow ? 1 : 0}>
					<Icon error={error} icon={icon} largeIcon={largeIcon} white={white} disabled={disabled} />
					<TextWrap twoRows={twoRows}>
						<InnerElement />
					</TextWrap>
				</Button>
			)}
		</Wrapper>
	);
};

const TextWrap = styled.div`
	display: flex;
	flex-direction: ${({ twoRows }) => (twoRows ? 'column' : 'row')};
	width: 100%;
	height: 100%;
	padding: ${({ twoRows }) => (twoRows ? '10px 20px' : '0')};
	flex-wrap: nowrap;

	& > h5 {
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		line-height: 140%;
		margin: 0;
	}

	& > p {
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		line-height: 130%;
		letter-spacing: 0.04em;
		margin: 0;
		color: rgba(${colors.mainBlackOP}, 0.4);
	}
`;

const Span = styled.div`
	align-self: center;
	margin: 0 20px;
	font-style: normal;
	font-weight: 800;
	font-size: 14px;
	line-height: 24px;
	text-transform: ${({ white }) => (white ? 'none' : 'uppercase')};
`;

const Wrapper = styled.div`
	align-self: ${({ alignSelf }) => alignSelf || 'auto'};
	width: ${({ width }) => width || '100%'};
	height: ${({ twoRows, stretch }) => (stretch ? 'auto' : twoRows ? '60px' : '50px')};
	min-height: ${({ twoRows, stretch }) => (!stretch ? '0' : twoRows ? '60px' : '50px')};
	margin-bottom: 10px;
	position: relative;

	&:before {
		position: absolute;
		content: '';
		width: 14px;
		height: 14px;
		background-image: ${({ rightIcon }) => (icons[rightIcon] ? `url(${icons[rightIcon]})` : 'url()')};
		background-repeat: no-repeat;
		background-size: 100%;
		top: 50%;
		right: 10px;
		transform: translateY(-50%);
		z-index: 1;
	}
`;

const buttonStyle = css`
	color: ${({ disabled }) => {
		if (disabled) {
			return `rgba(${colors.mainBlackOP}, 0.4)`;
		}
		return colors.mainBlack;
	}};
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	background-color: ${({ white }) => (white ? '#ffffff' : colors.darkOrange)};
	position: relative;
	text-decoration: none;

	&:after {
		top: 50%;
		right: 0;
		transform: translate(50%, -50%);
		position: absolute;
		${({ arrow }) => arrow && "content: '';"}
		width: 60px;
		height: 22px;
		background-image: url(${arrowNext});
		background-size: 100%;
		background-repeat: no-repeat;
	}
`;

const ButtonLink = styled(Link)`
	${buttonStyle}
`;

const Button = styled.div`
	${buttonStyle}
`;

const Icon = styled.div`
	width: 40px;
	height: 100%;
	background-color: ${({ white, error, disabled }) => {
		if (error) {
			return '#FE776E';
		}
		if (disabled) {
			return `rgba(${colors.mainBlackOP}, 0.4)`;
		}
		if (white) {
			return colors.mainBlack;
		}
		return `rgba(${colors.mainBlackOP}, 0.1)`;
	}};
	position: relative;

	&:before {
		position: absolute;
		content: '';
		width: ${({ largeIcon }) => (largeIcon ? '22px' : '16px')};
		height: ${({ largeIcon }) => (largeIcon ? '22px' : '16px')};
		background-image: ${({ icon }) => (icons[icon] ? `url(${icons[icon]})` : `url()`)};
		background-repeat: no-repeat;
		background-size: 100%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;

const Optional = styled.div`
	color: ${colors.mainBlack};
	font-style: normal;
	font-weight: 800;
	font-size: 14px;
	line-height: 24px;
	text-transform: uppercase;
	text-decoration: none;
	align-self: flex-end;
	transform: translate(0%, -100%);
`;
