import React, { useState, useEffect } from 'react';
import { Layout, ContentCard } from '../../components';
import { VerticalText, ContentGrid } from '../sharedStyles';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as icons from '../../assets/images/icons';

const RequestWithTimer = ({ transaction, inProgress, history, ...props }) => {
	//330000 === 5 min 30 sec
	const [timer, setTimer] = useState(330000);
	const [allowCancel, setAllowCancel] = useState(false);
	const [formattedTime, setFormattedTime] = useState({ minutes: '5', seconds: '30' });

	useEffect(() => {
		const timerId = setInterval(() => setTimer(sec => sec - 1000), 1000);
		return function clear() {
			clearInterval(timerId);
		};
	});

	useEffect(() => {
		//149000 === 2 min 29 sec
		if (timer <= 149000) {
			setAllowCancel(true);
		}
		if (timer === 0) {
			history.push({
				pathname: '/result',
				state: { error: true },
			});
		}
		const time = new Date(timer);
		setFormattedTime(prevState => ({
			minutes: time.getMinutes(),
			seconds: ('0' + time.getSeconds()).slice(-2),
		}));
	}, [timer]);

	return (
		<Layout clear cross={(allowCancel || inProgress) && true} goBack={allowCancel && (() => history.push('/'))}>
			<Content>
				<VerticalContent>
					<VerticalText height={'50vh'}>{inProgress ? 'in Process' : 'Request Sent'}</VerticalText>
				</VerticalContent>
				<PaymentContent>
					<Icon />
					<Text>Waiting for Confirmation..</Text>
					<Timer>
						{formattedTime.minutes} min {formattedTime.seconds} sec
					</Timer>
					<ContentCard row={4} column={1} transaction={transaction} receipt justifySelf={'end'} />
				</PaymentContent>
				{/* //todo type should be dynamic */}
				<BGIcon type={'business'} />
				{allowCancel && <TextButton>Cancel Request</TextButton>}
			</Content>
		</Layout>
	);
};

function mapStateToProps(state) {
	return {
		transaction: state.dataForOutgoingPayment,
	};
}

export default connect(
	mapStateToProps,
	null
)(RequestWithTimer);

const Timer = styled.div`
	grid-row: 3;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	margin-left: 10%;
`;

const TextButton = styled.button`
	grid-column: 1 / -1;
	grid-row: 2;
	background: none;
	border: none;
	font-weight: 800;
	font-size: 14px;
	text-transform: uppercase;
	line-height: 24px;
	width: 150px;
	height: 25px;
	align-self: end;
	margin-bottom: 30px;
	justify-self: center;
`;

const Text = styled.div`
	margin-left: 10%;
	grid-row: 2;
	font-size: 15px;
	line-height: 140%;
`;

const BGIcon = styled.div`
	position: absolute;
	right: 0;
	bottom: 0;
	opacity: 0.1;
	width: 120px;
	height: 120px;
	background-image: ${({ type }) => (icons[type] ? `url(${icons[type]})` : `url()`)};
	background-repeat: no-repeat;
	background-size: 100%;

	@media screen and (min-width: 321px) {
		width: 160px;
		height: 160px;
	}
`;

const Icon = styled.div`
	grid-row: 1;
	margin-left: 10%;
	position: relative;
	width: 64px;
	height: 64px;

	&:after {
		width: 100%;
		height: 100%;
		position: absolute;
		content: '';
		background-image: url(${icons.clock});
		background-size: 100%;
		background-repeat: no-repeat;
	}
`;

const PaymentContent = styled.div`
	grid-column: 2;
	grid-row: 1;
	display: grid;
	row-gap: 10px;
`;

const Content = styled(ContentGrid)`
	grid-row: 2;
	grid-template-columns: 70px 1fr;
	grid-template-rows: 2fr 1fr;
	column-gap: 15px;

	@media screen and (min-width: 321px) {
		grid-template-columns: 90px 1fr;
	}
`;

const VerticalContent = styled.div`
	display: flex;
	margin-left: 20px;
	grid-row: 1;
	grid-column: 1;
`;
