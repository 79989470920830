import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
	Amount,
	ButtonWithInnerIcon,
	ChangeBankButton,
	NumPanel,
} from '../../../components';
import { colors, Text } from '../../../components/sharedStyles';
import { iconLockWhite } from '../../../assets/images/icons';

const ContentWrap = styled.div`
	display: grid;
	width: 100%;
	grid-row: 2;
	margin: 25px 0 20px;
`;

const NumPanelContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
`;

const Header = styled.div`
	font-style: normal;
	font-size: 15px;
	line-height: 140%;
	text-align: center;
	letter-spacing: 0.05em;
	color: rgba(${colors.mainBlackOP}, 0.6);
	width: 100%;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
`;

const AccountContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-self: flex-start;
	justify-self: flex-end;
	width: 75%;
`;

export const WithAccountComponent = (props) => {
	const { amount, history, prefAcc } = props;
	const handleClick = () => history.push('/request-qr');
	return (
		<ContentWrap>
			<NumPanelContainer>
				<Header>Enter the amount you want to send</Header>
				<Amount amount={amount} />
				<NumPanel />
			</NumPanelContainer>
			<AccountContainer>
				<Text uppercase bold>to</Text>
				<ChangeBankButton
					alignSelf="flex-end"
					title={prefAcc.bankName}
					desc={prefAcc.accountNumber}
					to="/start-add-bank-account"
				/>
			</AccountContainer>
			<ButtonContainer>
				<ButtonWithInnerIcon icon={iconLockWhite} onClick={handleClick}>Create Request</ButtonWithInnerIcon>
			</ButtonContainer>
		</ContentWrap>
	);
};

export const WithAccount = withRouter(WithAccountComponent);

WithAccount.propTypes = {
	amount: PropTypes.string.isRequired,
	prefAcc: PropTypes.shape({
		accountName: PropTypes.string,
		accountNumber: PropTypes.string,
		sortCode: PropTypes.string,
	}).isRequired,
};
