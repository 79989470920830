import React, { useState, useEffect } from 'react';
import { Background } from '../sharedStyles';
import DotSlider from './DotSlider';
import SliderContent from './SliderContent';
import { BrowserRouter as Router, Link, withRouter } from 'react-router-dom';
import { startRoutes } from '../sharedData';
import { ClearLayout, Top, TextButton, Bottom, BlackButton } from './style';


const FirstLoadScreen = ({ history }) => {
	const [ countNext, setCount ] = useState(1);
	const [ countClick, setClicks ] = useState(0);
	const [ redirect, setRedirect ] = useState(false);

	const skip = () => {
		localStorage.isFirst = true;
		history.push('/');
	}

	const showNext = () => {
		if (redirect) {
			skip();
		}

		if (startRoutes.length - 1 > countNext) {
			setCount(countNext + 1);
		}

		setClicks(countClick + 1);
	};

	useEffect(
		() => {
			if (startRoutes.length - 1 === countClick) {
				setRedirect(true);
			}
		},
		[ countClick ],
	);

	return (
		<Router>
			<Background>
				<ClearLayout>
					<Top>
						<DotSlider routes={startRoutes} />
						<Link to='/'>
							<TextButton onClick={skip}>Skip</TextButton>
						</Link>
					</Top>
					<SliderContent routes={startRoutes} />
					<Bottom>
						<Link to={redirect ? '/' : startRoutes[countNext].route}>
							<BlackButton onClick={showNext}>{redirect ? 'Finish' : 'Next'}</BlackButton>
						</Link>
					</Bottom>
				</ClearLayout>
			</Background>
		</Router>
	);
};

export default withRouter(FirstLoadScreen);
