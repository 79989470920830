import {
	ADD_RECEIVER_BANK_ACC,
	SET_PREFER_RECEIVER_ACC,
	BANKS_DATA_REQUEST,
	CONTACTS_DATA_REQUEST,
	BANKS_DATA_RECEIVED,
	CONTACTS_DATA_RECEIVED,
	ADD_PREFERABLE_METHOD,
	ADD_PAYEE_ACCOUNT,
	CLEAR_DATA_OF_PAYMENT_RECIPIENT,
	SET_PAYMENT_AMOUNT,
	SELECT_TRANSACTION,
	REQUEST_PAYMENT_LINK,
	GENERATE_BOPP_PAYEE_PAYMENT_LINK,
	CLEAR_PAYMENT_LINK,
	SET_PAYMENT_FROM_URL,
	SCAN_SUCCESS,
	SET_PAYEE_ACCOUNT,
	SUBMIT_PAYMENT,
	SAVE_CONTACT,
} from './constants';

function action(type, payload = {}) {
	return { type, ...payload };
}

export const requestBanksData = () => action(BANKS_DATA_REQUEST);

export const requestContactsData = () => action(CONTACTS_DATA_REQUEST);

export const fetchedBanksData = (banksData) => action(BANKS_DATA_RECEIVED, { banksData });

export const fetchedContactsData = (contactsData) => action(CONTACTS_DATA_RECEIVED, { contactsData });

export const addPreferableMethod = (prefMethod) => action(ADD_PREFERABLE_METHOD, { prefMethod });

export const addPayeeAccount = (account) => action(ADD_PAYEE_ACCOUNT, { account });

export const clearDataOfPaymentRecipient = () => action(CLEAR_DATA_OF_PAYMENT_RECIPIENT);

export const setAmountForPayer = (amount) => action(SET_PAYMENT_AMOUNT, { amount });

export const selectTransaction = (transaction) => action(SELECT_TRANSACTION, { transaction });

export const requestPaymentLinkGeneration = () => action(REQUEST_PAYMENT_LINK);

export const generatePayeeLink = () => action(GENERATE_BOPP_PAYEE_PAYMENT_LINK);

export const clearPaymentLink = () => action(CLEAR_PAYMENT_LINK);

export const setUrlPaymentData = (queryData) => action(SET_PAYMENT_FROM_URL, { queryData });

export const scanSuccess = (qrData) => action(SCAN_SUCCESS, { qrData });

export const setPayeeAcc = (account) => action(SET_PAYEE_ACCOUNT, { account });

export const submitPayment = (code, state, idToken) => action(SUBMIT_PAYMENT, { code, state, idToken });

export const saveContact = (contact) => action(SAVE_CONTACT, { contact });

export const addReceiverBankAcc = (acc) => action(ADD_RECEIVER_BANK_ACC, { acc });

export const setPrefReceiverAcc = (acc) => action(SET_PREFER_RECEIVER_ACC, { acc });
