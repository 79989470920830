import styled from 'styled-components';

import { colors } from '../sharedStyles';
import * as icons from '../../assets/images/icons';

export const Wrapper = styled.div`
	width: 100%;
	min-height: 105px;
	background-color: #fff;
	display: flex;
	flex-direction: row;
	margin-bottom: 7px;
`;

export const Transaction = styled.div`
	padding: 10px;
	display: grid;
	grid-template-rows: 1fr 1.5fr 1 fr;
	grid-template-columns: repeat(3, 1fr);
`;

Transaction.Header = styled.div`
	grid-row: 1;
	grid-column: 1 / 3;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 21px;
`;

Transaction.Amount = styled.div`
	grid-row: 1;
	grid-column: 3;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 23px;
	text-align: right;
	letter-spacing: 0.05em;
	color: ${({ isNegative }) => (isNegative ? colors.mainBlack : colors.pink)};
`;

Transaction.Details = styled.div`
	grid-row: 2;
	grid-column: 1 / 3;
	color: rgba(${colors.mainBlackOP}, 0.4);
	font-style: normal;
	font-weight: normal;
	font-size: 11px;
	line-height: 14px;
	letter-spacing: 0.04em;
`;

Transaction.Account = styled.div`
	grid-row: 3;
	grid-column: 1;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 18px;
	letter-spacing: 0.05em;
	align-self: end;
	justify-self: start;
	position: relative;
	padding-left: 20px;

	&:before {
		position: absolute;
		content: '';
		width: 16px;
		height: 16px;
		background-image: ${({ icon }) => icons[icon] ? `url(${icons[icon]})` : `url()`};
		background-repeat: no-repeat;
		background-size: 100%;
		top: 50%;
		left: 0px;
		transform: translateY(-50%);
	}
`;

Transaction.Categories = styled.div`
	grid-row: 3;
	grid-column: 2 / -1;
	align-self: end;
	justify-self: end;
	width: 24px;
	height: 24px;
	background-color: black;
	position: relative;
	&:before {
		position: absolute;
		content: '';
		width: 100%;
		height: 100%;
		background-image: ${({ icon }) => icons[icon] ? `url(${icons[icon]})` : `url()`};
		background-repeat: no-repeat;
		background-size: 100%;
		top: 50%;
		left: 0px;
		transform: translateY(-50%);
	}
`;

export const Time = styled.div`
	position: relative;
	width: 30px;
	min-height: 100%;
	padding: 10px 7px;
	text-transform: uppercase;
	text-align: left;
	writing-mode: vertical-lr;
	transform: rotate(180deg);
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 16px;
	letter-spacing: 0.05em;
	color: ${colors.mainOrange};
	background-color: ${colors.mainBlack};
	&:after {
		position: absolute;
		content: '';
		background-size: 100%;
		background-repeat: no-repeat;
		background-image: url(${icons.clock});
		width: 16px;
		height: 16px;
		bottom: 10px;
		right: 7px;
	}
`;
