import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { colors } from '../sharedStyles';
import { arrowForward } from '../../assets/images/icons';

export const ContentWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%;
    height: 100%;
`;

export const Button = styled(Link)`
	color: ${colors.mainBlack};
	width: 100%;
	height: 70px;
	background-color: ${({ white }) => (white ? '#fff' : colors.darkOrange)};
	display: flex;
	position: relative;
	padding: ${({ padding }) => (padding || ' 5px 0 5px 20px')};
	margin: ${({ margin }) => (margin || ' 0 0 8px 0')};
	flex-direction: column;
    justify-content: center;
    
    &:after {
        position: absolute;
        content: '';
        right: 10px;
        width: 14px;
        height: 14px;
        background: url(${arrowForward});
        background-repeat: no-repeat;
        background-size: 100%;
    }
`;

Button.Icon = styled.div`
	width: 40px;
	height: 40px;
	position: absolute;
	left: 0;

	&:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background: url(${({ img }) => img});
        background-repeat: no-repeat;
        background-size: 100%;
    }
`;

Button.Header = styled.div`
	font-style: normal;
	font-weight: 800;
	font-size: 16px;
	line-height: 22px;
`;

Button.Description = styled.div`
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 140%;
	letter-spacing: 0.05em;
	opacity: .6;
`;