import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../sharedStyles';

const Button = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	border: none;
	width: ${({ width }) => width};
	height: 56px;
	color: #ffffff;
	background-color: ${colors.mainBlack};
	font-style: normal;
	font-weight: 800;
	font-size: 16px;
	line-height: 22px;
`;

const Icon = styled.div`
	width: 16px;
	height: 16px;
	background-image: url(${({ img }) => img});
	background-size: 100%;
	background-repeat: no-repeat;
	margin-right: 14px;
`;

export const ButtonWithInnerIcon = (props) => {
	const {
		children,
		icon,
		width,
		onClick,
	} = props;
	return (
		<Button type="button" width={width} onClick={onClick}>
			<Icon img={icon} />
			{children}
		</Button>
	);
};

ButtonWithInnerIcon.defaultProps = {
	width: '80%',
	onClick: () => {},
};

ButtonWithInnerIcon.propTypes = {
	width: PropTypes.string,
	children: PropTypes.string.isRequired,
	icon: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.node,
	]).isRequired,
	onClick: PropTypes.func,
};
