import styled from 'styled-components';
import { plus, iconLockWhite } from '../assets/images/icons';

export const ContentGrid = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	display: grid;
`;

export const colors = {
	mainBlack: '#21242b',
	mainOrange: '#ffd602',
	darkOrange: '#EDBB1F',
	pink: '#FE655B',
	mainBlackOP: '33,36,43',
	mainOrangeOP: '255,214,2',
	darkOrangeOP: '237,187,31',
	grayBlack: '	#3C434D',
};

export const Background = styled.div`
	width: 100%;
	height: 100%;
	background-color: ${colors.mainOrange};
	overflow: hidden;
`;

export const ClearLayout = styled.div`
	display: grid;
	width: 100%;
	height: 100%;
	grid-template-columns: 1fr;
	grid-template-rows: ${({ tRows }) => (tRows ? tRows : '1fr 8fr')};
`;

export const VerticalText = styled.div`
	grid-row: 1 / -1;
	grid-column: 1;
	width: 50px;
	height: ${({ height }) => (height ? height : '70vh')};
	position: relative;
	display: block;
	text-transform: uppercase;
	line-height: 120%;
	letter-spacing: 0.05em;
	font-weight: bold;
	text-align: right;
	writing-mode: vertical-lr;
	transform: rotate(180deg);
	font-size: ${({ fontSize }) => (fontSize ? `${fontSize - 4}px` : '30px')};

	@media screen and (min-width: 321px) {
		width: 70px;
		font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '30px')};
	}
`;

export const SkeletonPulse = styled.div`
	display: inline-block;
	height: 100%;
	width: 100%;
	background: linear-gradient(-90deg, #ffd602 0%, #edbb1f 50%, #ffd602 100%);
	background-size: 400% 400%;
	animation: pulse 1.2s ease-in-out infinite;
	@keyframes pulse {
		0% {
			background-position: 0% 0%;
		}
		100% {
			background-position: -135% 0%;
		}
	}
`;

export const Text = styled.div`
	font-family: Muli;
	margin-left: ${({ leftMargin }) => (leftMargin ? '20px' : '0')};
	margin-bottom: 0;
	${({ margin }) => margin && `margin: ${margin}`};
	font-style: normal;
	color: ${({ color = colors.mainBlack }) => color};
	font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
	text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
	font-size: ${({ big, small, fSize = '14px' }) => {
		if (big) {
			return '36px';
		}
		if (small) {
			return '13px';
		}
		return fSize;
	}};
	line-height: 140%;

	@media screen and (min-width: 321px) {
		margin-bottom: 10px;
		${({ margin }) => margin && `margin: ${margin}`};
	}
`;

export const ButtonWrap = styled.div`
	grid-row: ${({ row }) => row || 2};
	grid-column: 1 / -1;
	display: flex;
	flex-direction: column;
	align-self: end;
	margin-bottom: 20px;
`;

export const BackButton = styled.button`
	border: none;
	height: 56px;
	width: ${({ width = '80%' }) => width};
	color: ${colors.mainBlack};
	background-color: ${colors.darkOrange};
	font-style: normal;
	font-weight: 800;
	font-size: 16px;
	line-height: 22px;
	text-transform: uppercase;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const NextButton = styled(BackButton)`
	margin-top: ${({ marginTop }) => marginTop || '14px'};
	width: 90%;
	color: #ffffff;
	background-color: ${colors.mainBlack};
`;

export const NextButtonWithLock = styled(NextButton)`
	position: relative;
	&::before {
		content: '';
		position: absolute;
		display: block;
		color: white;
		width: 16px;
		height: 16px;
		top: 50%;
		left: ${({ iconPosition = '25%' }) => iconPosition};
		transform: translateY(-50%);
		background-image: url(${iconLockWhite});
	}
`;

export const Input = styled.input`
	background-color: rgba(${colors.mainBlackOP}, 0.1);
	outline: none;
	width: 100%;
	height: 50px;
	border: none;
	border-radius: 0px;
	border-bottom: 1px solid ${({ error }) => (error ? 'red' : colors.mainBlack)};
	padding: 15px 10px 15px 10px;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 140%;

	&::placeholder {
		color: ${colors.mainBlack};
		opacity: 0.7;
	}
`;

export const Plus = styled.div`
	background-color: ${colors.darkOrange};
	width: 40px;
	height: 40px;
	position: relative;

	&:after {
		position: absolute;
		content: '';
		width: 16px;
		height: 16px;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		background-image: url(${plus});
		background-repeat: no-repeat;
		background-size: 100%;
	}
`;

export const WrapCheckbox = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	margin-top: 10px;
	& > ${Text} {
		width: ${({ width }) => width || '60%'};
		${({ fSize }) => fSize && `font-size: ${fSize}`};
		font-weight: normal;
		margin-left: 10px;
	}
`;

export const CardWrapper = styled.div`
	grid-column: ${({ column }) => column || '0'};
	grid-row: ${({ row }) => row || '0'};
	align-self: ${({ alignSelf }) => alignSelf || 'auto'};
	justify-self: ${({ justifySelf }) => justifySelf || 'auto'};
	position: relative;
	width: ${({ width }) => (width ? width : '90%')};
	${({ stretch }) => !stretch && 'height: 150px'};
	margin-bottom: 20px;
	z-index: 1;
`;

export const CardWithShadow = styled.div`
	position: relative;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	background: #ffffff;
	padding: 20px;
	padding-top: 15px;

	&:before {
		content: '';
		position: absolute;
		width: calc(100% + ${({ offset }) => (offset ? '0px' : '5px')});
		height: 100%;
		bottom: ${({ offset }) => (offset ? `${offset}px` : '-5px')};
		left: ${({ offset }) => (offset ? `${offset}px` : '-5px')};
		background: #000000;
		z-index: -1;
	}
`;
