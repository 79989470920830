import React from 'react';
import { Button, Wrapper } from './style';

export const ButtonWithArrow = ({ gRow, reverse, header, descr, bottom, shortText, to, invertColors, ...props }) => {
	return (
		<Wrapper bottom={bottom} gRow={gRow} onClick={props.onClick}>
			<Button
				pending={props.pending}
				invertcolors={invertColors ? 1 : 0}
				reverse={reverse ? 1 : 0}
				shorttext={shortText ? 1 : 0}
				{...(to ? { to: to } : { as: 'div' })}
				{...(props.href && { href: props.href, as: 'a' })}
				{...props}
			>
				<Button.Header>{header}</Button.Header>
				<Button.Description pending={props.pending} invertColors={invertColors}>
					{descr}
				</Button.Description>
			</Button>
		</Wrapper>
	);
};
