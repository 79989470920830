import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRouteWrapper = ({ path, component: Component, restricted, routeParams }) => {
	// if (!localStorage.isFirst) {
	// return <Redirect to='/start/small-business' />;
	// }
	if (!localStorage.f05a83f0) {
		return <RedirPath to='/login' path={path} />;
	}

	if (routeParams) {
		return <Route path={path} component={props => <Component routeParams={routeParams} {...props} />} />;
	}

	return <Route path={path} component={Component} />;
};

export default PrivateRouteWrapper;

const RedirPath = props => {
	const path = props.path;
	return (
		<Route
			path={path}
			render={routeProps => {
				return (
					<Redirect
						from={path}
						to={
							path.length > 1
								? {
										pathname: props.to,
										hash: path,
										search: routeProps.location.search,
								  }
								: {
										pathname: props.to,
										search: routeProps.location.search,
								  }
						}
					/>
				);
			}}
		/>
	);
};
