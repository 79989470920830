const initialState = {
    transaction: {
        time: '12:31',
        amount: '-12.99',
        details: 'International House, 12 Constance Street, London. E16 2DQ',
        account: {
            accountName: 'Central London Cafe',
            accountType: 'bank',
            sortCode: '1243 1452 3512',
        },
        by: 'Barclays',
        categories: 'food',
    }
};

const transactionDetails = (state = initialState, action) => {
	switch (action.type) {
		case 'SELECT_TRANSACTION': {
			return { ...state, transaction: action.transaction };
		}
		default:
			return state;
	}
};

export default transactionDetails;
