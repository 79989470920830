import React from 'react';
import styled from 'styled-components';
import { colors } from '../sharedStyles';

const Dots = ({ pages, current }) => {
	return (
		<Wrapper length={pages.length}>
			{pages.map((v, i) => (
				<Dot key={v} active={current === i} />
			))}
		</Wrapper>
	);
};

export default Dots;

const Wrapper = styled.div`
	z-index: 10;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	width: ${props => props.length * 16}px;
	position: absolute;
	top: 188px;
	left: 16px;
`;

const Dot = styled.div`
	width: 8px;
	height: 8px;
	background-color: ${({ active }) => (active ? colors.mainBlack : `rgba(${colors.mainBlackOP}, 0.1)`)};
	transition: background-color 0.3s ease-in-out;
`;
