const contactsData = (state = [], action) => {
	switch (action.type) {
		case 'SAVE_CONTACT':
			return [...state, action.contact];
		case 'CONTACTS_DATA_RECEIVED':
			return [...state, ...action.contactsData];
		case 'CONTACTS_DATA_REQUEST':
		default:
			return state;
	}
};

export default contactsData;
