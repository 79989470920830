import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { trapezoid } from '../../assets/images';

const Wrapper = styled.div`
	position: absolute;
    transform: ${({ top }) => {
		if (top) {
			return 'translateY(-30vw)';
		}
		return 'none';
	}};
`;

const Figure = styled.div`
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url(${trapezoid});
    position: relative;
`;

export const Trapezoid = ({ top }) => (
	<Wrapper top={top}>
		<Figure />
	</Wrapper>
);

Trapezoid.defaultProps = {
	top: false,
};

Trapezoid.propTypes = {
	top: PropTypes.bool,
};
