import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { clearPaymentLink } from '../../actions';
import { Layout, ContentCard, Popup } from '../../components';
import { colors, VerticalText, ContentGrid, Text, Input } from '../sharedStyles';
import * as icons from '../../assets/images/icons';

const PaymentResult = ({ transaction, error, history, ...props }) => {
	// If array then first item is title, second item is icon id from 'images/icons'
	const hashtags = [
		'grocery',
		['eat', 'food'],
		'entertainment',
		'shopping',
		'travel',
		['bills', 'bill'],
		'medicine',
		['person', 'userInvert'],
		['personal care', 'personalCare'],
		'chairty',
		'other',
	];

	const handleClose = () => {
		props.clearPaymentLink();
		history.push('/');
	};

	const err = error || (history.location.state && history.location.state.error);

	const [open, setOpen] = useState(false);
	const [selectedHashtags, setHashtag] = useState([]);

	const togglePopup = () => {
		setOpen((current) => !current);
	};

	const hashtagsText = () => (
		selectedHashtags.map((tag) => (Array.isArray(tag) ? `#${tag[0]}` : `#${tag}`)).join(' ')
	);

	const isActive = (tag) => {
		const hashtag = Array.isArray(tag) ? tag[0] : tag;
		return selectedHashtags.includes(hashtag);
	};

	const addHashtag = (tag) => {
		const hashtag = Array.isArray(tag) ? tag[0] : tag;
		setHashtag((hashtags) => {
			if (hashtags.includes(hashtag)) {
				const newHashtags = [...hashtags];
				newHashtags.splice(hashtags.indexOf(hashtag), 1);
				return [...newHashtags];
			}
			return [...hashtags, hashtag];
		});
	};

	return (
		<>
			<Layout config={{ cross: true, rightButtonHandler: handleClose }} color={err && colors.pink}>
				<Content>
					<VerticalContent>
						<VerticalText height="50vh">{err ? 'Payment Failed' : 'PAYMENT SENT'}</VerticalText>
					</VerticalContent>
					<PaymentContent error={err}>
						<ResultIcon error={err} />
						{err && (
							<ErrorText>Ooops.. Something went wrong and the payment could not be processed</ErrorText>
						)}
						<ContentCard row={err ? 3 : 2} column={1} transaction={transaction} receipt justifySelf={'end'}>
							{/* <Bottom> */}
							{/* <Hashtag icon={icons.food}>Food</Hashtag> */}
							{/* <TextButtonThin onClick={togglePopup}>Change tag?</TextButtonThin> */}
							{/* </Bottom> */}
						</ContentCard>
					</PaymentContent>
					<BGIcon type="business" />
					{err && <TextButton>Try again</TextButton>}
				</Content>
			</Layout>
			<Popup
				isOpen={open}
				onCancel={togglePopup}
				onOk={togglePopup}
				ok="Save & Close"
				cancel="Skip & Close"
				title="add hashtag"
			>
				<Text>What is this payment for?</Text>
				<HashtagWrapper>
					{hashtags.map((tag) => {
						const [title, icon] = Array.isArray(tag) ? tag : [tag, tag];
						return (
							<Hashtag
								key={icon}
								active={isActive(tag)}
								onClick={() => addHashtag(tag)}
								icon={icons[icon]}
							>
								{title}
							</Hashtag>
						);
					})}
				</HashtagWrapper>
				<Text bold margin="30px 0 0 0">
					Hashtags
				</Text>
				<Input value={hashtagsText()} />
			</Popup>
		</>
	);
};

function mapStateToProps(state) {
	return {
		transaction: state.dataForOutgoingPayment,
		method: state.preferableMethods,
	};
}

export const PaymentResultScreen = connect(
	mapStateToProps,
	{ clearPaymentLink },
)(PaymentResult);

const HashtagWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

const Hashtag = styled.div`
	height: 30px;
	background-color: ${({ active }) => (active ? colors.darkOrange : colors.mainBlack)};
	display: flex;
	align-items: center;
	position: relative;
	color: white;
	padding: 0 11px 0 30px;
	font-size: 11px;
	margin: 2px

	&:before {
		content: '';
		position: absolute;
		left: 4px;
		width: 20px;
		height: 20px;
		background-image: url(${({ icon }) => icon || ''});
		background-repeat: no-repeat;
		background-size: 100%;
		background-position: center;
	}
`;

const Bottom = styled.div`
	display: flex;
	position: absolute;
	bottom: 13px;
`;

const TextButton = styled.button`
	grid-column: 1 / -1;
	grid-row: 2;
	background: none;
	border: none;
	font-weight: 800;
	font-size: 14px;
	text-transform: uppercase;
	line-height: 24px;
	width: 120px;
	height: 25px;
	align-self: end;
	margin-bottom: 30px;
	justify-self: center;
`;

const TextButtonThin = styled(TextButton)`
	margin-bottom: 0;
	font-weight: 100;
	align-self: center;
`;

const ErrorText = styled.div`
	margin-left: 10%;
	grid-row: 2;
	grid-column: 1;
	font-size: 15px;
	line-height: 140%;
`;

const BGIcon = styled.div`
	position: absolute;
	right: 0;
	bottom: 0;
	opacity: 0.1;
	width: 120px;
	height: 120px;
	background-image: ${({ type }) => (icons[type] ? `url(${icons[type]})` : `url()`)};

	background-repeat: no-repeat;
	background-size: 100%;

	@media screen and (min-width: 321px) {
		width: 160px;
		height: 160px;
	}
`;

const ResultIcon = styled.div`
	margin-left: 10%;
	position: relative;
	width: 64px;
	height: 64px;

	&:after {
		width: 100%;
		height: 100%;
		position: absolute;
		content: '';
		background-image: url(${({ error }) => (error ? icons.cross : icons.check)});
		background-size: 100%;
		background-repeat: no-repeat;
	}
`;

const PaymentContent = styled.div`
	grid-column: 2;
	display: grid;
	row-gap: 10px;
	grid-template-rows: ${({ error }) => (error ? '1fr 1fr 3fr' : '1fr 3fr')};
`;

const Content = styled(ContentGrid)`
	grid-row: 2;
	grid-template-columns: 70px 1fr;
	grid-template-rows: 2fr 1fr;
	column-gap: 15px;

	@media screen and (min-width: 321px) {
		grid-template-columns: 90px 1fr;
	}
`;

const VerticalContent = styled.div`
	display: flex;
	margin-left: 20px;
	grid-row: 1;
	grid-column: 1;
`;
