import styled from 'styled-components';
import { Link } from 'react-router-dom';
import arrow from '../../assets/images/arrow.svg';
import arrowW from '../../assets/images/arrow-white.svg';
import { colors } from '../sharedStyles';

export const StyledLink = styled(Link)`
	grid-row: ${({ row = 1 }) => row};
    background-color: ${({ black }) => (black ? colors.mainBlack : colors.mainOrange)};
    grid-column: 1;
	width: 30px;
	height: 240px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
`;

export const Arrow = styled.span`
	background-color: ${({ black }) => (black ? 'rgba(255, 255, 255, 0.1)' : `rgba(${colors.mainBlackOP}, 0.1)`)};
	display: block;
	width: 30px;
	height: 50px;
	position: relative;

	&:before {
		position: absolute;
		content: '';
		background-image: url(${({ black }) => (black ? arrowW : arrow)});
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 14px;
		height: 14px;
	}
`;

export const Text = styled.span`
	color: ${({ black }) => (black ? '#ffffff' : colors.mainBlack)};
	transform: rotate(270deg);
	transform-origin: 0 0;
	display: block;
	letter-spacing: 0.05em;
	font-size: 14px;
	line-height: 24px;
	text-transform: uppercase;
	text-decoration: none;
	font-weight: 800;
	width: 70%;
	height: 30px;
`;
