import { ADD_RECEIVER_BANK_ACC } from '../actions/constants';

const initState = localStorage.receiveAccounts ? JSON.parse(localStorage.receiveAccounts) : [];

export const receiverListOfBanks = (state = initState, action) => {
	switch (action.type) {
		case ADD_RECEIVER_BANK_ACC: {
			return [...state, action.acc];
		}
		default:
			return state;
	}
};
