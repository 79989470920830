import React from 'react';
import { Background, ClearLayout } from '../../sharedStyles';
import { PlainHeader } from '../../../components';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { setPayeeAcc } from '../../../actions';

const Dev_contacts = [
	{ accountName: 'Ian Gass', accountNumber: '54357591', sortCode: '60-83-71', bank: 'Starling' },
	{ accountName: 'Migle Varnagire', accountNumber: '22449868', sortCode: '30-84-45', bank: 'Lloyds' },
	{ accountName: 'Migle Varnagire', accountNumber: '04513983', sortCode: '04-00-04', bank: 'Monzo' },
];

const PayeeList = props => {
	const onSelect = acc => {
		props.setPayeeAcc(acc);
		props.history.push('/send-payment');
	};

	return (
		<Background>
			<ClearLayout>
				<PlainHeader
					text={'CHANGE PAYEE'}
					rClick={() => props.history.push('/send-payment')}
					controls={{ cross: true }}
				/>
				<List>
					{Dev_contacts.map(c => (
						<Contact key={c.accountName + c.sortCode} data={c} onSelect={() => onSelect(c)} />
					))}
				</List>
			</ClearLayout>
		</Background>
	);
};

export default connect(
	null,
	{ setPayeeAcc }
)(PayeeList);

const List = styled.div`
	overflow-x: auto;
	padding: 15px 20px;
`;

const Contact = ({ data, onSelect }) => {
	return (
		<Card onClick={onSelect}>
			<Card.H3>{data.accountName}</Card.H3>
			<Card.P>Bank: {data.bank}</Card.P>
			<Card.P>Account Number: {data.accountNumber}</Card.P>
			<Card.P>Sort Code: {data.sortCode}</Card.P>
		</Card>
	);
};

const Card = styled.div`
	background-color: #fff;
	padding: 20px;
	margin-bottom: 10px;
`;

Card.H3 = styled.h3`
	margin: 0;
	font-family: Muli;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 140%;
`;

Card.P = styled.p`
	margin: 0;
	font-family: Muli;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 140%;
	letter-spacing: 0.05em;
	opacity: 0.4;
`;
