import styled from 'styled-components';

export const ContentWrap = styled.div`
	display: grid;
	grid-template-rows: 1fr;
	grid-auto-columns: 50px 1fr;
	grid-column-gap: 10px;
	margin-top: 50px;

	@media screen and (min-width: 321px) {
		grid-auto-columns: 70px 1fr;
		grid-column-gap: 30px;
	}
`;

export const Content = styled.div`
	grid-column: 2;
	display: flex;
	flex-direction: column;
`;

export const Text = styled.div`
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 140%;
	margin-bottom: 50px;
`;
