import React from 'react';
import PropTypes from 'prop-types';
import { NavWithLogo } from '../NavWithLogo';
import { BackgroundWithTrapezoid } from '../BackgroundWithTrapezoid';
import { Wrapper } from './style';

export const Layout = ({
	children,
	black,
	color,
	next,
	fixed,
	squish,
	config: controlsConfig,
}) => {
	return (
		<Wrapper fixed={fixed} squish={squish}>
			<NavWithLogo
				controlsConfig={controlsConfig}
				next={next}
			/>
			{children}
			<BackgroundWithTrapezoid black={black} color={color} fixed={fixed} />
		</Wrapper>
	);
};

Layout.defaultProps = {
	config: {
		cross: false,
		rightButtonHandler: () => { },
		leftButtonHandler: () => { },
		clear: false,
		notifs: false,
		back: false,
	},
};

Layout.propTypes = {
	config: PropTypes.shape({
		cross: PropTypes.bool,
		clear: PropTypes.bool,
		notifs: PropTypes.bool,
		back: PropTypes.bool,
		rightButtonHandler: PropTypes.func,
		leftButtonHandler: PropTypes.func,
	}),
};
