import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ListWithSearchScreen } from '../../screens';
import { ButtonWLeftIcon } from '../../components';
import { setPayeeAcc } from '../../actions';

const initConfig = {
	header: 'Choose Payee',
	placeholder: 'Search a user..',
	list: [],
	controls: {},
	listItemIcon: 'userInvert',
	onListItemClick: () => {},
	listType: 'user',
	listItemRightIcon: 'arrowForward',
};

const MyContactsFirstPageConfig = (props) => {
	const { history, setPayeeAcc, account } = props;
	const [state, setState] = useState(initConfig);

	const choosePayee = (payee) => {
		setPayeeAcc(payee);
		if (account) {
			history.push('/send-payment');
		} else {
			history.push('/request-payment');
		}
	};

	const config = (data) => ({
		header: 'Choose Payee',
		placeholder: 'Search a user..',
		list: data,
		controls: { back: true },
		controlsHandlers: {
			lClick: () => history.push('/'),
		},
		listItemIcon: 'iconUserInvert',
		onListItemClick: choosePayee,
		listType: 'user',
		listItemRightIcon: 'arrowForward',
	});

	useEffect(() => {
		const data = localStorage.contactsData ? JSON.parse(localStorage.contactsData) : [];
		setState(() => config(data));
	}, []);

	return (
		<ListWithSearchScreen config={state}>
			<ButtonWLeftIcon icon="plus" to="/payee-details">
				Add Details manually
			</ButtonWLeftIcon>
		</ListWithSearchScreen>
	);
};

const mapStateToProps = (state) => ({
	account: state.dataForOutgoingPayment.account,
});

const mapDispatchToProps = {
	setPayeeAcc,
};

export const MyContactsFirstPage = connect(mapStateToProps, mapDispatchToProps)(MyContactsFirstPageConfig);
