import styled from 'styled-components';
import { ContentGrid } from '../../components/sharedStyles';

export const WrapContentGrid = styled(ContentGrid)`
	display: flex;
	flex-direction: column;
	grid-row: 2;
	grid-column: 1;
	position: relative;

	@media screen and (max-width: 321px) {
		grid-row: 3;
    }
`;
