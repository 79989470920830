import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { colors } from '../../components/sharedStyles';
import { logo } from '../../assets/images/icons';
import { trapezoid } from '../../assets/images';
import { clearPaymentLink } from '../../actions';

export const ContinueToTheBankPage = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const redirectURL = useSelector((state) => state.openBanking.redirectURL);

	useEffect(() => {
		if (!redirectURL) {
			history.push('/');
		}
	}, [history]);

	const redirectBack = () => {
		dispatch(clearPaymentLink());
		history.push('/send-payment');
	};

	return (
		<Wrapper>
			<Content>
				<CancelBtn onClick={redirectBack}>cancel</CancelBtn>
				<Btn onClick={redirectBack} href={redirectURL} target="_blank">
					Continue
				</Btn>
			</Content>
			<Figure>
				<VerticalText>Your personal payment assistant</VerticalText>
				<Figure.Logo />
			</Figure>
		</Wrapper>
	);
};

const Btn = styled.a`
	display: flex;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	border: none;
	width: 100%;
	height: 56px;
	color: #ffffff;
	background-color: ${colors.mainBlack};
	font-style: normal;
	font-weight: 800;
	font-size: 16px;
	line-height: 22px;
`;

const CancelBtn = styled(Btn)`
	background-color: transparent;
	color: ${colors.mainBlack};
	width: 40%;
	height: 36px;
	font-size: 14px;
	margin-bottom: 15px;
	border: 1px solid ${colors.mainBlack};
`;

const VerticalText = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	width: 40px;
	height: 100%;
	display: block;
	transform: translateY(-18%);
	text-transform: uppercase;
	font-style: normal;
	font-weight: 800;
	font-size: 14px;
	line-height: 24px;
	text-align: right;
	text-transform: uppercase;
	text-align: left;
	writing-mode: vertical-lr;
`;

const Wrapper = styled.div`
	position: absolute;
	background: ${colors.mainOrange};
	width: 100vw;
	height: 100vh;
	top: 0;
	z-index: 5;
	display: flex;
	justify-content: center;
	grid-column: 1;
`;

const Content = styled.div`
	z-index: 6;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	padding: 20px 20px 60px;
	width: 100%;
`;

const Figure = styled.div`
	width: 100vw;
	height: 300px;
	background-repeat: no-repeat;
	background-size: 100%;
	background-image: url(${trapezoid});
	position: absolute;
	z-index: 10;
	top: 46%;
	transform: translateY(-50%);
`;

Figure.Logo = styled.div`
	position: absolute;
	background-image: url(${logo});
	background-repeat: no-repeat;
	background-size: 100%;
	top: 0;
	transform: translate(70%, -5%);
	width: 40%;
	height: 200px;
`;
