import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import Login from './DEMO/Login';
import {
	// RequestPaymentScreen,
	AddPayment,
	BeforeSentPreference,
	Menu,
	StepLayout,
	PaymentMethods,
	PaymentResultScreen,
	ScanQRCodeToPay,
	RequestWithTimer,
	PaymentPreferences,
	TransactionsScreen,
	TransactionDetails,
	SettingsScreen,
	BankScreen,
	BankSettings,
	GrantConsents,
	BankAccSettings,
	SavedBank,
	BankAccounts,
	EditRelatedContact,
	PayeeList,
} from '.';
import {
	AccountAddedPage,
	AccountVerifiedPage,
	AddBankAccountPage,
	ConfirmPaymentPage,
	ContinueToTheBankPage,
	GrantAccessRequestPage,
	MyContactsFirstPage,
	PayeeDetailsPage,
	PaymentSetupPage,
	ReceiverDetailsPage,
	RequestPaymentPage,
	RequestSelectBankPage,
	RequestQRLinkPage,
	SelectBankPage,
	SendOrRequestPage,
} from '../pages';

import PrivateRouteWrapper from './PrivateRouteWrapper';

const routes = [
	{
		component: PayeeList,
		restricted: false,
		path: '/payee-list',
		routeParams: '',
	},
	{
		component: EditRelatedContact,
		path: '/edit-related-contact',
	},
	{
		component: BankAccounts,
		path: '/bank-linked-accounts',
	},
	{
		component: SavedBank,
		path: '/saved-bank',
	},
	{
		component: BankAccSettings,
		path: '/bank-acc-settings',
	},
	{
		component: GrantConsents,
		path: '/grant-consents',
	},
	{
		component: BankSettings,
		path: '/bank-settings',
	},
	{
		component: BankScreen,
		path: '/bank',
	},
	{
		component: SettingsScreen,
		path: '/settings',
	},
	{
		component: TransactionsScreen,
		path: '/transactions',
	},
	{
		component: TransactionDetails,
		path: '/transaction-details',
	},
	{
		component: PaymentPreferences,
		path: '/payment-preferences',
	},
	{
		component: RequestWithTimer,
		path: '/request',
	},
	{
		component: ScanQRCodeToPay,
		restricted: false,
		path: '/scan-qr-code-to-pay',
		routeParams: '',
	},
	// {
	// 	component: AccountDetailForm,
	// 	restricted: false,
	// 	path: '/account-details',
	// 	routeParams: '',
	// },
	{
		component: PayeeDetailsPage,
		restricted: false,
		path: '/payee-details',
		routeParams: '',
	},
	{
		component: PaymentResultScreen,
		restricted: false,
		path: '/result',
		routeParams: '',
	},
	{
		component: PaymentMethods,
		restricted: true,
		path: '/payment-methods-settings',
		routeParams: '',
	},
	{
		component: StepLayout,
		restricted: false,
		path: '/signup/step',
		routeParams: '',
	},
	// {
	// 	component: RequestPaymentScreen,
	// 	restricted: false,
	// 	path: '/request-payment',
	// 	routeParams: '',
	// },
	{
		component: Menu,
		restricted: false,
		path: '/menu',
		routeParams: '',
	},
	{
		component: BeforeSentPreference,
		restricted: false,
		path: '/send-payment/before-sent',
		routeParams: '',
	},
	{
		component: ConfirmPaymentPage,
		restricted: false,
		path: '/send-payment',
		routeParams: '',
	},
	{
		component: AddPayment,
		restricted: false,
		path: '/add-payment-method',
		routeParams: '',
	},
	{
		component: SelectBankPage,
		restricted: false,
		path: '/select-bank',
		routeParams: '',
	},
	{
		component: ContinueToTheBankPage,
		restricted: false,
		path: '/continue',
		routeParams: '',
	},
	// {
	// 	component: ListWithSearch,
	// 	restricted: false,
	// 	path: '/select-contact',
	// 	routeParams: '',
	// },
	{
		component: PaymentSetupPage,
		restricted: false,
		path: '/request-payment',
		routeParams: '',
	},
	{
		component: RequestPaymentPage,
		restricted: false,
		path: '/start-payment-request',
		routeParams: '',
	},
	{
		component: MyContactsFirstPage,
		restricted: false,
		path: '/start-send-payment',
		routeParams: '',
	},
	{
		component: AddBankAccountPage,
		restricted: false,
		path: '/start-add-bank-account',
		routeParams: '',
	},
	{
		component: RequestSelectBankPage,
		restricted: false,
		path: '/add-bank-select-bank',
		routeParams: '',
	},
	{
		component: GrantAccessRequestPage,
		restricted: false,
		path: '/grant-access-request',
		routeParams: '',
	},
	{
		component: AccountVerifiedPage,
		restricted: false,
		path: '/account-verified',
		routeParams: '',
	},
	{
		component: AccountAddedPage,
		restricted: false,
		path: '/account-added',
		routeParams: '',
	},
	{
		component: () => <div>empty route</div>,
		restricted: false,
		path: '/request-setup',
		routeParams: '',
	},
	{
		component: ReceiverDetailsPage,
		restricted: false,
		path: '/add-acc-manually',
		routeParams: '',
	},
	{
		component: RequestQRLinkPage,
		restricted: false,
		path: '/request-qr',
		routeParams: '',
	},
	{
		component: ConfirmPaymentPage,
		restricted: false,
		path: '/api',
		routeParams: '',
	},
	{
		// component: ScannerStartScreen,
		// component: RequestPaymentScreen,
		// component: ConfirmPayment,
		component: SendOrRequestPage,
		restricted: false,
		path: '/',
		routeParams: '',
	},
];

const Main = () => (
	<Switch>
		<Route exact path="/login" component={Login} />
		{routes.map((route) => (
			<PrivateRouteWrapper key={route.path} {...route} />
		))}
		<Redirect from="*" to="/" />
	</Switch>
);

export default withRouter(Main);
