import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Layout } from '..';
import { ContentGrid, colors } from '../sharedStyles';
import {
	paymentsInvert,
	payments,
	dollarInvert,
	dollar,
	contactsInvert,
	contacts,
	transactionsInvert,
	transactions,
} from '../../assets/images/icons/menu';
import messageInvert from '../../assets/images/message-invert.svg';
import { iconSettings, iconSettingsInvert, iconMessage } from '../../assets/images/icons';
import arrow from '../../assets/images/arrow-next.svg';

const Menu = ({ history }) => {
	const buttons = [
		{ icon: { normal: payments, inverted: paymentsInvert }, text: 'payments', to: '/' },
		{ icon: { normal: dollar, inverted: dollarInvert }, text: 'Payment Methods', to: '/menu' },
		// { icon: { normal: dollar, inverted: dollarInvert }, text: 'Payment Methods', to: '/payment-methods-settings' },
		{ icon: { normal: contacts, inverted: contactsInvert }, text: 'My Contacts', to: '/menu' },
		{ icon: { normal: transactions, inverted: transactionsInvert }, text: 'Transactions', to: '/menu' },
		{ icon: { normal: iconMessage, inverted: messageInvert }, text: 'Notifications', to: '/menu' },
		{ icon: { normal: iconSettings, inverted: iconSettingsInvert }, text: 'Settings', to: '/menu' },
	];

	return (
		<Layout cross goBack={history.goBack}>
			<WrapContentGrid>
				{buttons.map(({ icon, text, to }) => (
					<MenuButton to={to} key={text} icon={icon}>
						<span>{text}</span>
					</MenuButton>
				))}
			</WrapContentGrid>
		</Layout>
	);
};

export default Menu;

const WrapContentGrid = styled(ContentGrid)`
	display: flex;
	flex-direction: column;
	grid-row: 2;
	padding: 0 20px;
`;

const MenuButton = styled(Link)`
	border: none;
	background-color: ${colors.darkOrange};
	margin-bottom: 10px;
	font-style: normal;
	font-weight: 800;
	font-size: 14px;
	line-height: 24px;
	color: ${colors.mainBlack};
	text-transform: uppercase;
	width: 90%;
	height: 50px;
	text-align: start;
	padding-left: 50px;
	position: relative;
	display: flex;
	align-items: center;

	&:before {
		content: '';
		position: absolute;
		background-size: 100%;
		background-repeat: no-repeat;
		width: 24px;
		height: 24px;
		left: 15px;
		background-image: url(${({ icon }) => icon.normal});
	}

	&:after {
		content: '';
		position: absolute;
		background-size: 100%;
		background-repeat: no-repeat;
		width: 62px;
		height: 22px;
		right: -10%;
		background-image: url(${arrow});
	}

	&:hover {
		width: 100%;
		color: #ffffff;
		background-color: ${colors.mainBlack};
	}

	&:hover:before {
		background-image: url(${({ icon }) => icon.inverted});
	}

	&:hover:after {
		width: 0;
		height: 0;
	}
`;
