import React from 'react';
import { connect } from 'react-redux';
import { AccountDetailFormScreen } from '../../screens';

const PayeeDetailsPageConfig = ({ history }) => {
	const config = {
		sideText: 'PAYEE DETAILS',
		header: {
			cross: true,
			rightButtonHandler: () => {
				history.push('/start-send-payment');
			},
		},
		buttons: {
			topButtonText: 'Save Payee',
			bottomButtonText: 'Next',
		},
	};
	return (
		<AccountDetailFormScreen config={config} history={history} />
	);
};

export const PayeeDetailsPage = connect()(PayeeDetailsPageConfig);
