import React from 'react';
import Checkbox from 'rc-checkbox';

import { Background, ClearLayout } from '../sharedStyles';
import { PlainHeader, ButtonWLeftIcon } from '../../components'
import { VerticalText, Text, ButtonWrap, NextButton, WrapCheckbox } from '../sharedStyles';
import { ContentWrap, Content } from './style';

const GrantConsents = ({ history }) => {
	return (
		<Background>
			<ClearLayout tRows={'1fr 10fr'}>
				<PlainHeader lClick={history.goBack} controls={{ back: true, cross: true }} text={'Grant Consents'} />
				<ContentWrap>
					<VerticalText>App Consent</VerticalText>
					<Content>
						<Text margin={'0 20px 17px 0'}>To help process and manage payments to <b>Bank Name</b> we require your consent to:</Text>
                        <ButtonWLeftIcon
                            alignSelf={'flex-end'}
                            white
                            stretch
							icon={'checkInvert'}
							largeIcon
							desc={'Your account name, number and sort-code, Your account balance'}
							twoRows={1}
						>
							Your Account Details
						</ButtonWLeftIcon>
                        <ButtonWLeftIcon
                            alignSelf={'flex-end'}
                            white
                            stretch
							icon={'checkInvert'}
							desc={'Your incoming transactions, Your outgoing transactions'}
							twoRows={1}
						>
							Your Account Transactions
						</ButtonWLeftIcon>
                        <ButtonWLeftIcon
                            alignSelf={'flex-end'}
                            white
                            stretch
							icon={'checkInvert'}
							largeIcon
							desc={'Your address, telephone numbers and email address as held by your bank'}
							twoRows={1}
						>
							Your Contact Details
						</ButtonWLeftIcon>
						<WrapCheckbox width={'100%'} fSize={'11px'}>
							<Checkbox />
							<Text>Bopp can access the following account information from my accounts</Text>
						</WrapCheckbox>
					</Content>
				</ContentWrap>
				<ButtonWrap row={3}>
					<NextButton onClick={history.goBack}>Grant Consents</NextButton>
				</ButtonWrap>
			</ClearLayout>
		</Background>
	);
};

export default GrantConsents;

