import React from 'react';
import styled from 'styled-components';
import { colors, VerticalText } from '../sharedStyles';

const TextContainer = ({content}) => {
    return (
        <React.Fragment>
            <VerticalText>
                {content.header}
            </VerticalText>
            <WrapText>
                <Text>
                    {content.text}
                </Text>
            </WrapText>
            <Img img={content.img}/>
        </React.Fragment>
    )
}

export default TextContainer;

const Text = styled.div`
	grid-row: 1;
	grid-column: 2;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 140%;
    padding-right: 20px;
`;

const WrapText = styled.div`
    width: 250px;
    justify-self: end;
    @media screen and (min-width: 321px) {
        width: 275px;
    }
`;

const Img = styled.div`
    grid-row: 2;
	grid-column: 2;
    width: 250px;
    height: 250px;
    background: ${colors.darkOrange};
    justify-self: end;
    @media screen and (min-width: 321px) {
        width: 275px;
        height: 275px
    }
`;
