import React, { useEffect } from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { iconLockWhite } from '../../assets/images/icons';
import { WrapContentGrid } from './style';
import { Text } from '../../components/sharedStyles';
import { Layout, ContentCard, ButtonWLeftIcon, ButtonWithInnerIcon, Splash } from '../../components';
import {
	setUrlPaymentData,
	requestPaymentLinkGeneration,
	clearPaymentLink,
	clearDataOfPaymentRecipient,
	submitPayment,
} from '../../actions';
import { formatTotal } from '../../utils';

const ConfirmPayment = (props) => {
	const {
		transaction,
		history,
		method,
		result,
		location,
		pending,
		submitPayment,
		setUrlPaymentData,
		clearPaymentLink,
		clearDataOfPaymentRecipient,
		requestPaymentLinkGeneration,
		redirectURL,
	} = props;

	const isMethodChosen = !!Object.keys(method).length;
	const initValue = {
		amount: '0.00',
		account: {
			accountName: '',
			accountNumber: '',
			sortCode: '',
			bank: '',
		},
	};

	useEffect(() => {
		if (result) {
			history.push('/result');
		}
		if (redirectURL) {
			history.push('/continue');
		}
	}, [result, redirectURL, history]);

	useEffect(() => {
		const q = queryString.parse(location.search);
		const isQ = Object.keys(q).length;

		if (q.sort && !q.sort.includes('-')) {
			q.sort = q.sort.match(/.{1,2}/g).join('-');
		}

		const { code, state, id_token: idToken } = queryString.parse(location.hash);
		if (code) {
			// commented to check the backend
			submitPayment(code, state, idToken);
		}

		if (isQ) {
			const qData = {
				amount: q.total,
				account: { accountName: q.name, accountNumber: q.acc, sortCode: q.sort },
			};
			setUrlPaymentData(qData);
		} else if (!transaction.account && !code) {
			setUrlPaymentData(initValue);
		}
	}, [location]);

	const handleClose = () => {
		clearPaymentLink();
		clearDataOfPaymentRecipient();
		history.push('/start-send-payment');
	};

	return (
		<Layout config={{ cross: true, rightButtonHandler: handleClose }}>
			<Splash show={pending} />
			<WrapContentGrid>
				<Text uppercase bold leftMargin>
					You are about to pay
				</Text>
				<Text big bold leftMargin>
					£{formatTotal(transaction.amount)}
				</Text>
				<Text uppercase bold leftMargin>
					TO
				</Text>
				<ContentCard alignSelf="flex-end" transaction={transaction} change changeLink="/start-send-payment" />
				<Text uppercase bold leftMargin>
					FROM
				</Text>
				{isMethodChosen ? (
					<ButtonWLeftIcon
						width="90%"
						twoRows
						icon={`${method.listType}Invert`}
						alignSelf="flex-end"
						desc={method.listType !== 'user' ? method.listType : method.details}
						largeIcon
						white
						change
						to="/select-bank"
					>
						{method.accountName}
					</ButtonWLeftIcon>
				) : (
					<ButtonWLeftIcon width="90%" icon="plus" alignSelf="flex-end" to="/select-bank">
						Add Payment Method
					</ButtonWLeftIcon>
				)}
				{isMethodChosen && method.listType === 'bank' && (
					<BottomContainer>
						<ButtonWithInnerIcon
							icon={iconLockWhite}
							onClick={() => {
								requestPaymentLinkGeneration();
							}}
						>
							Pay via bank
						</ButtonWithInnerIcon>
						<Text small margin="10px">
							Bopp is regulated by the FCA
						</Text>
					</BottomContainer>
				)}
			</WrapContentGrid>
		</Layout>
	);
};

const BottomContainer = styled.div`
	width: 100%;
	height: 30%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex-direction: column;
`;

function mapStateToProps(state) {
	return {
		transaction: state.dataForOutgoingPayment,
		method: state.preferableMethods,
		pending: state.openBanking.pending,
		result: state.openBanking.result,
		amount: state.accountDetails.amount,
		redirectURL: state.openBanking.redirectURL,
	};
}

export const ConfirmPaymentPage = connect(mapStateToProps, {
	setUrlPaymentData,
	requestPaymentLinkGeneration,
	clearPaymentLink,
	submitPayment,
	clearDataOfPaymentRecipient,
})(ConfirmPayment);
