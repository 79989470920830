import { Link } from 'react-router-dom';
import styled from 'styled-components';
import arrowNext from '../../../assets/images/arrow-next.svg';
import arrowNextOrange from '../../../assets/images/arrow-next-orange.svg';
import { colors } from '../../sharedStyles';

export const Wrapper = styled.div`
	position: ${({ bottom }) => (bottom ? 'absolute' : 'static')};
	width: 100%;
	height: 90px;
	align-self: end;
	grid-column: 1 / -1;
	grid-row: ${({ gRow }) => (gRow ? gRow : 'none')};
	bottom: ${({ bottom }) => (bottom ? '0' : '')};
`;

export const Button = styled(Link)`
	color: ${({ invertcolors, pending }) =>
		invertcolors ? colors.darkOrange : pending ? '#A9A9A9' : colors.mainBlack};
	grid-row: 2;
	grid-column: 1 / -1;
	align-self: end;
	width: 100%;
	height: 100%;
	background-color: ${({ invertcolors, pending }) =>
		invertcolors ? '#3C434D' : pending ? '#DCDCDC' : colors.darkOrange};
	display: flex;
	position: relative;
	padding: ${({ shorttext }) => (shorttext ? '10px 5px 10px 10px' : '5px 0 5px 7px')};
	flex-direction: ${({ reverse }) => (reverse ? 'column-reverse' : 'column')};
	justify-content: center;

	@media screen and (min-width: 321px) {
		width: 86%;
		padding: ${({ shortText }) => (shortText ? '24px 20px 20px 20px' : '10px 10px 10px 20px')};

		&:after {
			position: absolute;
			content: '';
			right: -40px;
			top: 35px;
			width: 62px;
			height: 23px;
			background: ${({ invertcolors }) => (invertcolors ? `url(${arrowNextOrange})` : `url(${arrowNext})`)};
			background-repeat: no-repeat;
			background-size: 100%;
		}
	}
`;

Button.Header = styled.div`
	font-style: normal;
	font-weight: 800;
	font-size: 16px;
	line-height: 22px;
	text-transform: uppercase;
`;

Button.Description = styled.div`
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 140%;
	letter-spacing: 0.05em;
	color: ${({ invertColors, pending }) =>
		invertColors ? ' rgba(255, 255, 255, 0.6)' : pending ? '#BEBEBE' : colors.mainBlack};
`;
