import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ListWithSearchScreen } from '../../screens';
import { addPreferableMethod } from '../../actions';

const initConfig = {
	header: 'Choose a Bank',
	placeholder: 'Search a bank..',
	list: [],
	controls: { cross: true },
	listItemIcon: 'bankInvert',
	onListItemClick: () => { },
	listType: 'bank',
};

const RequestSelectBank = ({
	history,
	addPreferableMethod,
	banks,
}) => {
	const [state, setState] = useState(initConfig);

	const chooseBank = (bank) => {
		addPreferableMethod(bank);
		history.push('/add-acc-manually');
	};

	const config = (data) => ({
		header: 'Choose a Bank',
		placeholder: 'Search a bank..',
		list: data,
		controls: { back: true, cross: true },
		controlsHandlers: {
			lClick: () => {
				history.push('/start-add-bank-account');
			},
		},
		listItemIcon: 'bankInvert',
		onListItemClick: chooseBank,
		listType: 'bank',
	});

	useEffect(() => {
		setState(() => config(banks));
	}, []);

	return (
		<ListWithSearchScreen config={state} />
	);
};

const mapStateToProps = (state) => ({
	banks: state.banksData,
});

const mapDispatchToProps = {
	addPreferableMethod,
};

export const RequestSelectBankPage = connect(mapStateToProps, mapDispatchToProps)(RequestSelectBank);
