import React from 'react';
import { Wrapper, NumButton, Symb, Numeric } from './style';

const PinPanel = ({ handler, ...props }) => {
	const buttons = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 'c', 0, 'b' ];

	const buttonClick = (e, val) => {
		switch (val) {
			case 'c':
				return handler(val, 'clear');
			case 'b':
				return handler(val, 'remove');
			default:
				return handler([ val ]);
		}
	};

	return (
		<Wrapper>
			{buttons.map((value) => {
				return (
					<NumButton
						inner={value}
						key={value}
						onClick={(e) => {
							buttonClick(e, value);
						}}
					>
						{value !== 'c' && value !== 'b' ? <Numeric>{value}</Numeric> : <Symb inner={value} />}
					</NumButton>
				);
			})}
		</Wrapper>
	);
};

export default PinPanel;
