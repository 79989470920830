import React from 'react';
import QRCode from 'qrcode-react';
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { colors } from '../sharedStyles';
import { Layout, ButtonWithArrow } from '../../components';
import { logo, iconUserInvert } from '../../assets/images/icons';
import logoGray from '../../assets/images/logo-gray.svg';
import camera from '../../assets/images/camera-gray.svg';
import cursor from '../../assets/images/cursor-gray.svg';
import { Instruction, ContentWrapper, Account, SideInstructions, QRWrap, Info } from './style';
import { clearPaymentLink } from '../../actions';

const instructions = [
	{
		icon: logoGray,
		text: 'Pay with BOPP App',
	},
	{
		icon: camera,
		text: 'Scan with Camera',
	},
	{
		icon: cursor,
		text: 'Visit ',
		highlightText: 'paybopp.com',
	},
];

const ScanQRCodeToPay = ({ history, payment, url, boppLink, ...props }) => {
	console.log(payment);
	const Instructions = instructions.map(({ icon, text, highlightText }) => {
		return (
			<Instruction key={text}>
				<Instruction.Icon icon={icon} />
				<Instruction.Text>
					{text}
					<b>{highlightText}</b>
				</Instruction.Text>
			</Instruction>
		);
	});

	const handleClose = () => {
		history.push('/request-payment');
		props.clearPaymentLink();
	};

	return (
		<Layout cross black goBack={handleClose}>
			<ContentWrapper>
				<Account>
					<Instruction.Icon icon={iconUserInvert} />
					<Account.Text>{payment.account ? payment.account.accountName : 'No Account'}</Account.Text>
				</Account>
				<SideInstructions>{Instructions}</SideInstructions>
				<QRWrap>
					<QRCode
						value={boppLink}
						bgColor={colors.mainOrange}
						fgColor={colors.mainBlack}
						logo={logo}
						size={200}
						logoWidth={55}
					/>
				</QRWrap>
				<Info>
					<div>Scan to Pay</div>
					<div>£{payment.amount}</div>
				</Info>
				<CopyToClipboard text={boppLink}>
					<ButtonWithArrow
						gRow={4}
						invertColors
						shortText
						reverse
						//href={`whatsapp://send?text=${url}`}
						header={'COPY LINK'}
						descr={'Client cannot scan the QR Code?'}
					/>
				</CopyToClipboard>
			</ContentWrapper>
		</Layout>
	);
};

function mapStateToProps(state) {
	return {
		payment: state.accountDetails,
		url: state.openBanking.redirectURL,
		boppLink: state.openBanking.link,
	};
}

export default connect(
	mapStateToProps,
	{ clearPaymentLink }
)(ScanQRCodeToPay);
