export const validEmailRegex = RegExp(
	/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
);

export const startRoutes = [
	{
		route: '/start/small-business',
		header: 'HELP SMALL BUSINESS',
		text:
			'Businesses typically have to pay 1-3% to accept digital payments. With BOPP transaction fees are zero for consumers and business.',
		img: '',
	},
	{
		route: '/start/pay-receive',
		header: 'PAY AND RECEiVE',
		text:
			'Use BOPP to make payments for everyday purchases, as well as sending requests for payment to friends and family.',
		img: '',
	},
	{
		route: '/start/for-others',
		header: 'PAY FOR OTHERS',
		text:
			'Using cash is getting harder and harder.  With BOPP you can pay for other’s purchases without having to hand over your bank cards. ',
		img: '',
	},
	{
		route: '/start/fast-sage-secure',
		header: 'FAST, SAFE & SECURE',
		text:
			'BOPP is just as quick as other payment mehods..  It is also highly secure as your authentication keys stay on your mobile. ',
		img: '',
	},
	{
		route: '/start/trust-us',
		header: 'YOU CAN TRUST US',
		text:
			"Our system is designed from the ground up to protect your right to privacy.  We can't access your data without your explicit consent.",
		img: '',
	},
];

export const countryCodes = [ '+44', '+353' ];
