import React from 'react';
import { Background, ClearLayout } from '../../sharedStyles';
import { PlainHeader } from '../../../components';

const PaymentMethods = ({ history, ...props }) => {
	return (
		<Background>
			<ClearLayout>
				<PlainHeader
					text={'Payment Methods'}
					controls={{ burger: true, plus: true }}
					white
					rClick={() => history.push('/menu')}
				/>
				<div />
			</ClearLayout>
		</Background>
	);
};

export default PaymentMethods;
