import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from './sharedStyles';
import { close } from '../assets/images/icons';

const Message = styled.div`
	position: relative;
	width: calc(100% - 20px);
	height: calc(100% - 60px);
	background-color: #ffffff;
	padding: 20px 20px 6px 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

Message.Close = styled.div`
	width: 17px;
	height: 17px;
	position: relative;
	&:after {
		position: absolute;
		content: '';
		background-image: url(${close});
		background-repeat: no-repeat;
		background-size: 100%;
		width: 100%;
		height: 100%;
	}
`;

Message.Header = styled.div`
	display: flex;
	z-index: -1;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	position: absolute;
	bottom: 60px;
	right: 20px;
	width: 100%;
	height: 100%;
	padding: 20px;
	font-style: normal;
	font-weight: 800;
	font-size: 16px;
	line-height: 22px;
	text-transform: uppercase;
	background-color: ${({ error }) => (error ? colors.pink : colors.mainOrange)};
`;
Message.Text = styled.div`
	padding-bottom: 30px;
`;

Message.Button = styled.button`
	background-color: ${({ bg = colors.mainBlack }) => bg};
	border: none;
	color: ${({ textColor = '#ffffff' }) => textColor};
	text-align: end;
	padding: 17px 20px;
	font-style: normal;
	font-weight: 800;
	font-size: 16px;
	margin-bottom: 4px;
	line-height: 22px;
	text-transform: uppercase;
	width: 100%;
	transform: translateX(20px);
`;

const Container = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	position: absolute;
	width: 100%;
	min-height: 34%;
	padding: 0 20px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

const Shade = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	background-color: ${colors.mainBlack};
	opacity: 0.7;
`;

const Wrapper = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 20;
`;

const Icon = styled.div`

`;

export const Modal = ({
	message,
	show,
	error,
	bb,
	tb,
	header,
	onClose,
	tbClick,
	bbClick,
	hIcon,
	bColor,
}) => {
	const [element, setElement] = useState(null);
	const [doc, setDoc] = useState(null);

	useEffect(() => setElement(document.createElement('div')), []);
	useEffect(() => setDoc(document), []);

	useEffect(() => {
		if (element && doc) {
			doc.body.appendChild(element);
			return () => {
				doc.body.removeChild(element);
			};
		}
	}, [element, doc]);

	if (!element) {
		return null;
	}

	return ReactDOM.createPortal(
		<>
			{show && (
				<Wrapper key="modal">
					<Shade onClick={onClose} />
					<Container>
						<Message>
							<Message.Text>
								{hIcon && <Icon icon={hIcon} />}
								{message}
							</Message.Text>
							<Message.Button bg={bColor && bColor.bg} textColor={bColor && bColor.textColor} onClick={tbClick}>
								{tb}
							</Message.Button>
							{bb && <Message.Button bg={bColor && bColor.bg} textColor={bColor && bColor.textColor} onClick={bbClick}>
								{bb}
							</Message.Button>}
							<Message.Header error={error}>
								{header}
								<Message.Close onClick={onClose} />
							</Message.Header>
						</Message>
					</Container>
				</Wrapper>
			)}
		</>,
		element,
	);
};

Modal.defaultProps = {
	tb: 'ok',
	header: 'information',
	onClose: () => {},
	tbClick: () => {},
	bbClick: () => {},
};

Modal.propTypes = {
	tb: PropTypes.string,
	header: PropTypes.string,
	onClose: PropTypes.func,
	tbClick: PropTypes.func,
	bbClick: PropTypes.func,
	message: PropTypes.string,
	show: PropTypes.bool.isRequired,
	error: PropTypes.bool,
	bb: PropTypes.string,
	hIcon: PropTypes.string,
	bColor: PropTypes.shape({
		bg: PropTypes.string,
		textColor: PropTypes.string,
	}),
};
