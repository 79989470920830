import React, { useState } from 'react';
import styled from 'styled-components';
import { Text, colors } from '../../components/sharedStyles';
import { iconShieldBlack } from '../../assets/images/icons';
import { Barclays, LLoyds } from '../../assets/images/icons/banks';
import {
	BankButton,
	ButtonWLeftIcon,
	Modal,
	Layout,
} from '../../components';

const Wrapper = styled.div`
	grid-row: 2;
`;

const VerifiedContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

const IconShield = styled.div`
	width: 20px;
	height: 32px;
	background: url(${iconShieldBlack}) no-repeat top;
	background-size: 100%;
	margin-right: 5px;


	@media screen and (min-width: 321px)  {
		height: 45px;
	}
`;

const VerifiedBanks = styled.div`

`;

const DottedLine = styled.hr`
	border: none;
	border-top: 2px dotted ${colors.mainBlack};
	color: #fff;
	background-color: transparent;
	height: 1px;
	width: 100%;
	margin: 40px 0 0;
	align-self: flex-end;
`;

const TopContent = styled.div`
	padding: 0 14px;
	grid-area: a;

	@media screen and (min-width: 321px)  {
		padding: 0 14px 0 26px;
	}
`;

const BottomContent = styled.div`
	padding: 0 14px;
	grid-area: b;

	@media screen and (min-width: 321px)  {
		padding: 0 14px 0 26px;
	}
`;

const VerifiedBankAccounts = [
	{ h: 'Barclays', d: 'Bank', icon: Barclays },
	{ h: 'Santander', d: 'Bank', icon: '' },
	{ h: 'LLoyds', d: 'Bank', icon: LLoyds },
];

export const AddBankAccountPage = (props) => {
	const { history } = props;
	const layoutConfig = {
		cross: true,
		rightButtonHandler: () => history.push('/'),
	};
	const [showModal, setShowModal] = useState(false);
	const handleManualAdd = () => setShowModal(!showModal);

	return (
		<Layout config={layoutConfig}>
			<Wrapper>
				<TopContent>
					<Text uppercase bold>Choose your bank</Text>
					<VerifiedContainer>
						<IconShield />
						<Text>For user&#39;s we recommend the use of verified bank account details.</Text>
					</VerifiedContainer>
					<VerifiedBanks>
						{VerifiedBankAccounts.map(({ h, d, icon }) => (
							<BankButton key={h} header={h} descr={d} icon={icon} />
						))}
						{/* <ButtonWLeftIcon icon="plus" to="/add-bank-select-bank">Choose a Bank</ButtonWLeftIcon> */}
						<ButtonWLeftIcon icon="plus">Choose a Bank</ButtonWLeftIcon>
					</VerifiedBanks>
					<DottedLine />
				</TopContent>
				<BottomContent>
					<Text bold>Or</Text>
					<ButtonWLeftIcon icon="plus" onClick={handleManualAdd}>Add account manually</ButtonWLeftIcon>
				</BottomContent>
			</Wrapper>
			<Modal
				show={showModal}
				header="Warning"
				message="When you add bank account details manually we are unable to display ‘verified by bank’ to the recipient."
				tb="VERIFY DETAILS WITH BANK"
				bb="ADD MANUALLY"
				onClose={handleManualAdd}
				tbClick={handleManualAdd}
				bbClick={() => history.push('/add-bank-select-bank')}
				bColor={{
					bg: colors.darkOrange,
					textColor: colors.mainBlack,
				}}
			/>
		</Layout>
	);
};
