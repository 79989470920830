import styled from 'styled-components';
import { ContentGrid } from '../sharedStyles';

export const WrapContentGrid = styled(ContentGrid)`
	display: flex;
	flex-direction: column;
	grid-row: 2;
	grid-column: 1;
	position: relative;
	padding: 0 20px;

	@media screen and (min-width: 321px) {
    }
`;
