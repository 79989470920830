import React, { useState } from 'react';

import { Background, ClearLayout, Text, ButtonWrap, Input, NextButton } from '../sharedStyles';
import { PlainHeader, ButtonWLeftIcon, Popup, SubHeader } from '../../components';
import { ContentWrap, Button, InputWithLabel, Content } from './style';
import { deleteIcon, bankAcc } from '../../assets/images/icons';

const BankAccSettings = ({ history }) => {
    const [open, setOpen] = useState(false);

    const togglePopup = () => {
        setOpen(current => !current);
    }

	return (
		<Background>
			<ClearLayout tRows={'1fr 1fr 8fr'}>
				<PlainHeader
					text={'Bank Acc Settings'}
                    controls={{ back: true, cross: true }}
                    lClick={history.goBack}
					rClick={history.goBack}
				/>
                <SubHeader
                    // TODO: dynamic bank
                    leftIcon={bankAcc}
                    title={'Barclays Account 1'}
                    text={'Ian Gass | 1234 5678 | 20 31 24'}
                />
                <ContentWrap>
                    <InputWithLabel>
						<Text bold>Bank Account Nickname</Text>
						<Input
							type={'text'}
						/>
					</InputWithLabel>
                        <Content><Text bold uppercase margin={'0'}>Granted Concents</Text></Content>
                        <ButtonWLeftIcon
                            width={'90%'}
                            alignSelf={'flex-end'}
							white
							icon={'checkInvert'}
							largeIcon
							desc={'Expires 24 Aug 2019'}
							twoRows={1}
						>
							Your Account Details
						</ButtonWLeftIcon>
                        <ButtonWLeftIcon
                            width={'90%'}
                            alignSelf={'flex-end'}
                            white
                            error
							icon={'crossInvert'}
							desc={'Not granted'}
							twoRows={1}
						>
							Your Account Transactions
						</ButtonWLeftIcon>
                        <ButtonWLeftIcon
                            width={'90%'}
                            alignSelf={'flex-end'}
							white
							icon={'checkInvert'}
							largeIcon
							desc={'Expires 24 Aug 2019'}
							twoRows={1}
						>
							Your Contact Details
						</ButtonWLeftIcon>
                </ContentWrap>
                <ButtonWrap row={4}>
                    <Button onClick={togglePopup} icon={deleteIcon}>Delete bank account</Button>
                    <NextButton onClick={history.goBack}>Save & Close</NextButton>
                </ButtonWrap>
			</ClearLayout>
            <Popup title={'Delete a bank?'} ok={'Delete'} cancel isOpen={open} onOk={togglePopup} onCancel={togglePopup}>
                Are you sure that you want to delete the <b>Bank Name</b> from your payment methods?
            </Popup>
		</Background>
	);
};

export default BankAccSettings;
