import React from 'react';
import Checkbox from 'rc-checkbox';

import { Background, ClearLayout, ButtonWrap, NextButton, Text } from '../sharedStyles';
import { countryCodes } from '../sharedData';
import { PlainHeader, SubHeader } from '../../components';
import { iconUserInvert, deleteIcon } from '../../assets/images/icons';
import { ContentWrap, HeaderWrapCheckbox, Button, WrapName, WrapMobile, WrapSelect, Input, Select, Label } from './style';


const EditRelatedContact = ({ history }) => {
	return (
		<Background>
			<ClearLayout tRows={'1fr 1fr 8fr'}>
				<PlainHeader
					text={'Edit a Contact'}
					controls={{ back: true, cross: true }}
					lClick={history.goBack}
				/>
				<SubHeader
                    // TODO: dynamic contact
                    leftIcon={iconUserInvert}
                    title={'Mom'}
                    text={'+44 123 456 789'}
                >
                    <HeaderWrapCheckbox width={'100%'} fSize={'13px'}>
                        <Checkbox />
                        <Text>Set as Default payment method </Text>
                    </HeaderWrapCheckbox>
                </SubHeader>
                <ContentWrap>
                    <WrapName>
                        <Label>Contact Name</Label>
                        <Input />
                    </WrapName>
                    <WrapMobile>
                        <Label>Contact Phone</Label>
                        <WrapSelect>
                            <Select>
                                {countryCodes.map((code) => (
                                    <option value={code} key={code}>
                                        {code}
                                    </option>
                                ))}
                            </Select>
                        </WrapSelect>
                        <Input />
                    </WrapMobile>
                </ContentWrap>
                <ButtonWrap row={4}>
                    <Button onClick={history.goBack} icon={deleteIcon}>Delete PAYMENT Contact</Button>
                    <NextButton onClick={history.goBack}>Close</NextButton>
                </ButtonWrap>
			</ClearLayout>
		</Background>
	);
};

export default EditRelatedContact;
