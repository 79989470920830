import styled from 'styled-components';
import { animated } from 'react-spring';
import arrowNext from '../../assets/images/arrow-next.svg';
import { colors } from '../sharedStyles';

export const CardsList = styled.div`
	display: grid;
	grid-template-rows: 1fr;
	grid-template-columns: 1fr;
	position: relative;
	width: 277px;
	height: 220px;
`;

export const LinkedWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	position: relative;
`;


export const ContentWrap = styled.div`
	padding: 20px;
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 4fr;
`;

export const Card = styled(animated.div)`
	max-width: 277px;
	max-height: 220px;
	background-color: ${colors.darkOrange};
	padding: 30px 20px 20px;
	position: relative;
	grid-row: 1;
	grid-column: 1;
`;

Card.Header = styled.div`
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
`;

Card.Content = styled.div`
	font-style: normal;
	font-weight: 800;
	font-size: 15px;
	line-height: 21px;
`;

Card.Next = styled.div`
	font-style: normal;
	font-weight: 800;
	font-size: 14px;
	line-height: 24px;
	position: absolute;
	bottom: 25px;
	right: 55px;
	text-transform: uppercase;

	&:after {
		position: absolute;
		content: '';
		width: 60px;
		height: 22px;
		left: 10px;
		background: url(${arrowNext});
		background-repeat: no-repeat;
		background-size: 100%;
	}
`;
