import styled from 'styled-components';
import { colors } from '../sharedStyles';

const gradient = `linear-gradient(209.35deg, #3c434d 0%, ${colors.mainBlack} 100%)`;

export const Wrapper = styled.div`
	position: absolute;
	background: ${({ black, color }) => {
		if (black) {
			return gradient;
		} else if (color) {
			return color;
		} else {
			return colors.mainOrange;
		}
	}};
	width: 100vw;
	height: 100vh;
	z-index: -10;
	display: flex;
	grid-column: 1;
`;
