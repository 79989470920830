import React from 'react';
import { Layout, ButtonWLeftIcon } from '..';
import { WrapContentGrid } from './style';
import { connect } from 'react-redux';
import { Text } from '../sharedStyles';

const BeforeSentPreference = ({ transaction, history }) => {
	return (
		<Layout cross goBack={() => history.push('/send-payment')}>
			<WrapContentGrid>
				<Text>Your Payment Preferences</Text>
				<Text big bold>
					£{transaction.amount}
				</Text>
				<ButtonWLeftIcon width='90%' icon='plus' to='/add-payment-method'>
					Add Payment Method
				</ButtonWLeftIcon>
			</WrapContentGrid>
		</Layout>
	);
};

function mapStateToProps(state) {
	return {
		transaction: state.dataForOutgoingPayment,
		method: state.preferableMethods,
	};
}

export default connect(
	mapStateToProps,
	null
)(BeforeSentPreference);
