import React, { useState, useEffect } from 'react';
import { Background, ClearLayout } from '../../components/sharedStyles';
import { PlainHeader, ButtonWLeftIcon } from '../../components';
import { Search, Input, List } from './style';

export const ListWithSearchScreen = ({ children, config }) => {
	const {
		header,
		placeholder,
		list,
		controls,
		listItemIcon,
		onListItemClick,
		listType,
		controlsHandlers,
		listItemRightIcon,
	} = config;

	const [input, setInput] = useState('');
	const [localList, setLocalList] = useState([]);

	const filterList = (e, clear) => {
		if (clear) {
			setInput('');
			setLocalList([...list]);
		} else {
			setInput(e.target.value);
			let updatedList = [...list];
			if (updatedList.length) {
				updatedList = updatedList.filter(({ accountName }) =>
					accountName.toLowerCase().includes(e.target.value.toLowerCase())
				);
			}
			setLocalList([...updatedList]);
		}
	};

	useEffect(() => {
		setLocalList([...list]);
	}, [list]);

	return (
		<Background>
			<ClearLayout tRows="2fr 8fr">
				<PlainHeader
					text={header}
					controls={controls}
					lClick={controlsHandlers && controlsHandlers.lClick}
					rClick={
						controlsHandlers && controlsHandlers.rClick
							? controlsHandlers.rClick
							: (e) => filterList(e, true)
					}
				>
					<Search>
						<Input placeholder={placeholder} value={input} onChange={filterList} />
					</Search>
				</PlainHeader>
				<List>
					{children}
					{Boolean(localList.length) &&
						localList.map(({ accountName, api, sortCode, accountNumber }) => {
							if (listType === 'bank' && !api) {
								return (
									<ButtonWLeftIcon
										key={accountName + accountNumber + sortCode}
										white
										disabled
										icon={listItemIcon}
										largeIcon
										rightIcon={listItemRightIcon}
									>
										{accountName}
									</ButtonWLeftIcon>
								);
							}
							return (
								<ButtonWLeftIcon
									key={accountName + accountNumber + sortCode}
									onClick={() =>
										onListItemClick({
											accountName,
											sortCode,
											accountNumber,
											listType,
											api,
										})
									}
									white
									icon={listItemIcon}
									largeIcon
									rightIcon={listItemRightIcon}
									desc={sortCode && { sortCode, accountNumber }}
									twoRows={sortCode ? 1 : 0}
								>
									{accountName}
								</ButtonWLeftIcon>
							);
						})}
				</List>
			</ClearLayout>
		</Background>
	);
};
