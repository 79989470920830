import styled from 'styled-components';

import { WrapCheckbox, BackButton, colors } from '../sharedStyles';
import arrow from '../../assets/images/drop-down-arrow.svg';

export const ContentWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    height: 100%;
`;

export const HeaderWrapCheckbox = styled(WrapCheckbox)`
    grid-column: 1 / -1;
    margin-left: 20px;
    margin-top: 28px;
    margin-bottom: -20px;
`;

export const Button = styled(BackButton)`
    position: relative;

    &:after {
        position: absolute;
        content: '';
        right: 13px;
        width: 14px;
        height: 18px;
        background: url(${({ icon }) => icon});
        background-repeat: no-repeat;
        background-size: 100%;
    }
`;

export const WrapName = styled.div``;

export const WrapSelect = styled.div`
	width: 96px;
	height: 50px;
	position: relative;
	&::before {
		content: '';
		position: absolute;
		top: 50%;
		right: 10px;
		width: 16px;
		height: 16px;
		background-image: url(${arrow});
		background-repeat: no-repeat;
		background-size: 100%;
		pointer-events: none;
		transform: translateY(-50%);
	}
`;

export const Select = styled.select`
	background-color: #e9c406;
	color: ${colors.mainBlack};
	width: 100%;
	height: 100%;
	border: none;
	border-bottom: 1px solid ${colors.mainBlack};
	-webkit-appearance: button;
	appearance: button;
	outline: none;
	padding: 15px 10px 15px 10px;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 140%;
	box-shadow: none !important;

	&:focus {
		outline: none;
	}
	& option {
		padding: 30px;
	}
`;

export const Input = styled.input`
	background-color: rgba(${colors.mainBlackOP}, 0.1);
	outline: none;
	width: 100%;
	height: 50px;
	border: none;
	border-bottom: 1px solid ${({ error }) => (error ? 'red' : colors.mainBlack)};
	padding: 15px 10px 15px 10px;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 140%;

	&::placeholder {
		color: ${colors.mainBlack};
		opacity: 0.7;
	}
`;

export const Label = styled.div`
	margin-bottom: 3px;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 16px;
	letter-spacing: 0.05em;
`;

export const WrapMobile = styled.div`
	display: grid;
	grid-template-columns: 0.5fr 2fr;
	grid-template-rows: 0.3fr 2fr;
    column-gap: 10px;
    margin-top: 20px;

	& > ${Label} {
		grid-column: 1 / -1;
		grid-row: 1;
	}

	& > ${WrapSelect} {
		grid-column: 1;
		grid-row: 2;
	}

	& > ${Input} {
		grid-column: 2;
		grid-row: 2;
	}
`;
