import React from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import Checkbox from 'rc-checkbox';
import { colors } from '../sharedStyles';
import { countryCodes } from '../sharedData';
import arrow from '../../assets/images/drop-down-arrow.svg';
import 'rc-checkbox/assets/index.css';

const Step1 = ({ handleChange, formData, ...props }) => {
	return (
		<FormContent>
			<WrapName>
				<Text>Your Name</Text>
				<Input
					onChange={handleChange('name')}
					value={formData.name}
					error={formData.errors.step0.name_err ? 1 : 0}
				/>
			</WrapName>
			<WrapMobile>
				<Text>Your Mobile</Text>
				<WrapSelect>
					<Select onChange={handleChange('countryCode')} defaultValue={formData.countryCode}>
						{countryCodes.map(code => (
							<option value={code} key={code}>
								{code}
							</option>
						))}
					</Select>
				</WrapSelect>
				<Input
					as={NumberFormat}
					value={formData.phone}
					format='#### ### ###'
					onChange={handleChange('phone')}
					error={formData.errors.step0.phone_err ? 1 : 0}
				/>
			</WrapMobile>
			<WrapEmail>
				<Text>Your Email</Text>
				<Input
					type='email'
					value={formData.email}
					onChange={handleChange('email')}
					error={formData.errors.step0.email_err ? 1 : 0}
				/>
			</WrapEmail>
			<WrapCheckbox>
				<TermsCheckbox
					defaultChecked={formData.agreed}
					onChange={handleChange('agreed', 'checkbox')}
					error={formData.errors.step0.phone_err ? 1 : 0}
				/>
				<Text>
					I agree and accept the <span>Terms&Conditions</span>
				</Text>
			</WrapCheckbox>
		</FormContent>
	);
};

export default Step1;

const WrapName = styled.div``;

const WrapEmail = styled.div``;

const Text = styled.div`
	margin-bottom: 3px;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 16px;
	letter-spacing: 0.05em;

	& > span {
		text-decoration: underline;
	}
`;

const WrapSelect = styled.div`
	width: 96px;
	height: 50px;
	position: relative;
	&::before {
		content: '';
		position: absolute;
		top: 50%;
		right: 10px;
		width: 16px;
		height: 16px;
		background-image: url(${arrow});
		background-repeat: no-repeat;
		background-size: 100%;
		pointer-events: none;
		transform: translateY(-50%);
	}
`;

const TermsCheckbox = styled(Checkbox)`
	& .rc-checkbox:hover .rc-checkbox-inner,
	& .rc-checkbox-inner {
		border: ${({ error }) => (error ? '2px solid red' : `1px solid ${colors.mainBlack}`)};
	}
`;

const Select = styled.select`
	background-color: #e9c406;
	color: ${colors.mainBlack};
	width: 100%;
	height: 100%;
	border: none;
	border-radius: 0px;
	border-bottom: 1px solid ${colors.mainBlack};
	-webkit-appearance: button;
	appearance: button;
	outline: none;
	padding: 15px 10px 15px 10px;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 140%;
	box-shadow: none !important;

	&:focus {
		outline: none;
	}
	& option {
		padding: 30px;
	}
`;

const Input = styled.input`
	background-color: rgba(${colors.mainBlackOP}, 0.1);
	outline: none;
	width: 100%;
	height: 50px;
	border: none;
	border-radius: 0px;
	border-bottom: 1px solid ${({ error }) => (error ? 'red' : colors.mainBlack)};
	padding: 15px 10px 15px 10px;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 140%;

	&::placeholder {
		color: ${colors.mainBlack};
		opacity: 0.7;
	}
`;

const FormContent = styled.div`
	display: grid;
	padding-left: 10px;
	row-gap: 20px;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(4, 60px);

	@media screen and (min-width: 321px) {
		grid-template-rows: repeat(4, 70px);
	}
`;

const WrapMobile = styled.div`
	display: grid;
	grid-template-columns: 0.5fr 2fr;
	grid-template-rows: 0.3fr 2fr;
	column-gap: 10px;

	& > ${Text} {
		grid-column: 1 / -1;
		grid-row: 1;
	}

	& > ${WrapSelect} {
		grid-column: 1;
		grid-row: 2;
	}

	& > ${Input} {
		grid-column: 2;
		grid-row: 2;
	}
`;

const WrapCheckbox = styled.div`
	display: flex;
	flex-direction: row;
	& > ${Text} {
		width: 60%;
		font-weight: normal;
		margin-left: 10px;
	}
`;
