import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { colors } from '../../sharedStyles';
import { bankInvert } from '../../../assets/images/icons';

const TextWrap = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
	padding: 10px 20px;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
`;

const Text = styled.div`
	& > h5 {
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		line-height: 140%;
		margin: 0;
	}

	& > p {
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		line-height: 16px;
		letter-spacing: 0.05em;
		margin: 0;
		color: rgba(${colors.mainBlackOP}, 0.4);
	}
`;

const Wrapper = styled.div`
	align-self: ${({ alignSelf }) => alignSelf};
	width: ${({ width }) => width};
	height: 60px;
	min-height: 60px;
	margin-bottom: 10px;
	position: relative;
`;

const Button = styled(Link)`
	color: ${colors.mainBlack};
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	background-color: #ffffff;
	position: relative;
	text-decoration: none;
`;

const Icon = styled.div`
	width: 40px;
	height: 100%;
	background-color: ${colors.mainBlack};
	position: relative;

	&:before {
		position: absolute;
		content: '';
		width: 24px;
		height: 24px;
		background-image: url(${bankInvert});
		background-repeat: no-repeat;
		background-size: cover;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;

const BankLogo = styled.div`
	width: 40px;
	height: 100%;
	background-color: #ffffff;
	position: relative;

	&:before {
		position: absolute;
		content: '';
		width: 34px;
		height: 34px;
		background-image: url(${({ icon }) => icon});
		background-repeat: no-repeat;
		background-size: cover;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;


const Optional = styled.div`
	color: ${colors.mainBlack};
	font-style: normal;
	font-weight: 800;
	font-size: 14px;
	line-height: 24px;
	text-transform: uppercase;
	text-decoration: none;
`;

export const ChangeBankButton = ({
	alignSelf,
	title,
	to,
	icon,
	desc,
	width,
	onClick,
}) => {
	return (
		<Wrapper
			alignSelf={alignSelf}
			width={width}
			onClick={onClick}
		>
			<Button to={to}>
				{icon ? <BankLogo icon={icon} /> : <Icon />}
				<TextWrap>
					<Text>
						<h5>{title}</h5>
						<p>{desc.charAt(0).toUpperCase() + desc.slice(1)}</p>
					</Text>
					<Optional>Change</Optional>
				</TextWrap>
			</Button>
		</Wrapper>
	);
};

ChangeBankButton.defaultProps = {
	alignSelf: 'auto',
	to: '',
	icon: '',
	width: '100%',
	onClick: () => {},
};

ChangeBankButton.propTypes = {
	desc: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	alignSelf: PropTypes.string,
	to: PropTypes.string,
	icon: PropTypes.string,
	width: PropTypes.string,
};
