import React from 'react';
import { Background, ClearLayout, VerticalText } from '../sharedStyles';
import { PlainHeader, ButtonWLeftIcon } from '../../components';
import { ContentWrap, Content, Text } from './style';

const AddPayment = ({ history }) => {
	return (
		<Background>
			<ClearLayout>
				<PlainHeader text={'Add Payment Method'} rClick={() => history.push('/')} controls={{ cross: true }} />
				<ContentWrap>
					<VerticalText>Select Method</VerticalText>
					<Content>
						<Text>What payment method do you want to add?</Text>
						<ButtonWLeftIcon
							alignSelf='flex-end'
							icon='bank'
							twoRows
							desc='Pay with your favorite bank apps'
							to='/select-bank'
							largeIcon
						>
							Bank app
						</ButtonWLeftIcon>
						<ButtonWLeftIcon
							alignSelf='flex-end'
							icon='user'
							twoRows
							desc='Ask others to pay on your behalf'
							to='/select-contact'
							largeIcon
						>
							Your family & friends
						</ButtonWLeftIcon>
					</Content>
				</ContentWrap>
			</ClearLayout>
		</Background>
	);
};

export default AddPayment;
