import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../sharedStyles';
import { iconArrowUpYellow } from '../../../assets/images/icons';

const Square = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 56px;
	height: 56px;
	margin-left: 4px;
	margin-bottom: 4px;
	background: ${colors.grayBlack};

	&:before {
		position: absolute;
		content: '';
		width: 100%;
		height: 100%;
		top: 4px;
		right: 4px;
		background: ${colors.mainBlack};
		z-index: -1;
	}
`;

const Icon = styled.div`
	width: 20px;
	height: 20px;
	background: url(${iconArrowUpYellow}) 100% no-repeat;
	${({ up }) => up && 'transform: rotate(180deg);'}
`;

const Wrapper = styled.div`
	display: flex;
	flex: 0 0;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
  	z-index: 1;
`;

const Label = styled.div`
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 16px;
	text-align: center;
	letter-spacing: 0.05em;
	margin-top: 5px;
`;

export const SquareButton = (props) => {
	const { label, up, onClick } = props;
	return (
		<Wrapper onClick={onClick}>
			<Square>
				<Icon up={up} />
			</Square>
			{ label && <Label>{label}</Label>}
		</Wrapper>
	);
};

SquareButton.defaultProps = {
	label: '',
	up: false,
	onClick: () => {},
};

SquareButton.propTypes = {
	label: PropTypes.string,
	up: PropTypes.bool,
	onClick: PropTypes.func,
};
