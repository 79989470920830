import React from 'react';
import styled from 'styled-components';
import { Switch, Route, withRouter } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { ContentGrid } from '../sharedStyles';
import TextContainer from './TextContainer';

const SliderContent = ({ routes, location }) => {
	return (
		<Wrapper>
			<WrapTransitionGroup>
				<CSSTransition key={location.key} timeout={{ enter: 300, exit: 300 }} classNames={'fade'}>
					<section className='route-section'>
						<Switch location={location}>
							{routes.map(({ route, ...content }) => (
								<Route key={route} exact path={route} component={() => <WrapContentGrid><TextContainer content={content}/></WrapContentGrid>} />
							))}
						</Switch>
					</section>
				</CSSTransition>
			</WrapTransitionGroup>
		</Wrapper>
	);
};

export default withRouter(SliderContent);

const Wrapper = styled.div`
    grid-row: 2;
	position: relative;

	.fade-enter {
		opacity: 0.01;
		right: -100vw;
	}

	.fade-enter.fade-enter-active {
		opacity: 1;
		right: 0;
		transition: 300ms ease-in;
	}

	.fade-exit {
		opacity: 1;
		left: 0;
	}

	.fade-exit.fade-exit-active {
		opacity: 0.01;
		left: -100vw;
		transition: 300ms ease-in;
	}

	div.transition-group {
		position: relative;
	}
	section.route-section {
		position: absolute;
		width: 100%;
		height: 100%;
		/* top: 0; */
		/* left: 0; */
	}
`;

const WrapContentGrid = styled(ContentGrid)`
	grid-template-rows: 0.5fr 3fr;
	grid-template-columns: 70px 1fr;
	column-gap: 0;
	row-gap: 5px;

	@media screen and (min-width: 321px) {
		column-gap: 15px;
		row-gap: 15px;
    }
`;

const WrapTransitionGroup = styled(TransitionGroup)`
	width: 100%;
	height: 100%;
`;