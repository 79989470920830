import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { CardWrapper, CardWithShadow, colors } from '../sharedStyles';
import { Card, DescriptionContainer } from './style';

const ContentCard = ({ transaction, children, row, column, alignSelf, justifySelf, receipt, change, changeLink }) => {
	const { amount, account } = transaction;
	return (
		<CardWrapper row={row} column={column} alignSelf={alignSelf} justifySelf={justifySelf}>
			<CardWithShadow>
				<TopContainer>
					{receipt ? (
						<Card.Header size="20px">£{amount}</Card.Header>
					) : (
						<Card.Icon type="business" />
					)}
					{change && <Change to={changeLink}>edit</Change>}
				</TopContainer>
				<Card.Header>
					{account.accountName || 'Not available'}
				</Card.Header>
				<DescriptionContainer>
					<Card.DescriptionKey>Acc #: </Card.DescriptionKey>
					<Card.Description>
						{account.accountNumber || 'N/A'}
					</Card.Description>
					<Card.DescriptionKey>Sort Code: </Card.DescriptionKey>
					<Card.Description>
						{account.sortCode || 'N/A'}
					</Card.Description>
					{children}
				</DescriptionContainer>
			</CardWithShadow>
		</CardWrapper>
	);
};

export default ContentCard;

const Change = styled(Link)`
	color: ${colors.mainBlack};
	font-style: normal;
	font-weight: 800;
	font-size: 14px;
	line-height: 24px;
	text-transform: uppercase;
	text-decoration: none;
`;

const TopContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;
