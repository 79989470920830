import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import arrowNext from '../../../assets/images/arrow-next.svg';
import { colors } from '../../sharedStyles';
import { bankInvert } from '../../../assets/images/icons';

const Wrapper = styled.div`
	width: 100%;
	height: 50px;
	grid-column: 1 / -1;
	display: flex;
	margin-bottom: 10px;

	@media screen and (min-width: 321px) {
		height: 60px;
	}
`;

const Button = styled.div`
	color: ${colors.mainBlack};
	width: calc(86% - 40px);
	height: 100%;
	background-color: #ffffff;
	display: flex;
	position: relative;
	padding: 10px 5px 10px 10px;
	flex-direction: column;
	justify-content: center;
	padding: 24px 20px 20px 20px;

	&:after {
		position: absolute;
		content: '';
		right: -40px;
		top: 50%;
		transform: translateY(-50%);
		width: 62px;
		height: 23px;
		background: url(${arrowNext});
		background-repeat: no-repeat;
		background-size: 100%;
	}
`;

Button.Header = styled.div`
	font-style: normal;
	font-weight: 800;
	font-size: 16px;
	line-height: 22px;
`;

Button.Description = styled.div`
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 140%;
	letter-spacing: 0.05em;
	color: rgba(${colors.mainBlackOP}, 0.4);
`;

const IconContainer = styled.div`
	display: flex;
	width: 40px;
	height: 50px;
	justify-content: center;
	align-items: center;
	background: ${({ icon }) => {
		if (icon) {
			return '#ffffff';
		}
		return colors.mainBlack;
	}};

	@media screen and (min-width: 321px)  {
		height: 60px;
	}
`;

const Icon = styled.div`
	${({ icon }) => {
		if (icon) {
			return `
				width: 34px;
				height: 34px;
				background-image: url(${icon});
			`;
		}
		return `
			width: 24px;
			height: 24px;
			background-image: url(${bankInvert});
		`;
	}};
	background-size: 100%;
	background-repeat: no-repeat;
`;

export const BankButton = ({
	header,
	icon,
	descr,
	onClick,
}) => {
	return (
		<Wrapper onClick={onClick}>
			<IconContainer icon={icon}>
				<Icon icon={icon} />
			</IconContainer>
			<Button>
				<Button.Header>{header}</Button.Header>
				<Button.Description>
					{descr}
				</Button.Description>
			</Button>
		</Wrapper>
	);
};

BankButton.defaultProps = {
	onClick: () => {},
	icon: '',
};

BankButton.propTypes = {
	header: PropTypes.string.isRequired,
	descr: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	icon: PropTypes.string,
};
