import React from 'react';
import { Wrapper } from './style';

const Amount = ({ amount, ...props }) => {
	return (
		<Wrapper>
			<div>£</div>
			<div>{amount}</div>
		</Wrapper>
	);
};

export default Amount;
