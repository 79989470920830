import React from 'react';
import { connect } from 'react-redux';
import { ReceiverDetailsScreen } from '../../screens';

const ReceiverDetails = ({ history }) => {
	const config = {
		sideText: 'Bank Account',
		header: {
			cross: true,
			rightButtonHandler: () => {
				history.push('/add-bank-select-bank');
			},
		},
		buttons: {
			topButtonText: 'Cancel',
			bottomButtonText: 'Save',
		},
	};
	return (
		<ReceiverDetailsScreen config={config} history={history} />
	);
};

export const ReceiverDetailsPage = connect()(ReceiverDetails);
