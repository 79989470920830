import React from 'react';
import styled from 'styled-components';
import { colors } from './sharedStyles';

export const Burger = ({ next }) => {
	return (
		<Bun onClick={next}>
			<Slice />
			<Slice />
			<Slice />
			<Slice />
		</Bun>
	);
};

const Bun = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	align-self: center;
	justify-self: flex-end;
`;

const Slice = styled.div`
	display: block;
	height: 2px;
	background-color: ${colors.mainBlack};
	margin-bottom: 3px;

	&:nth-child(1) {
		width: 22px;
	}

	&:nth-child(2) {
		width: 17px;
	}

	&:nth-child(3) {
		width: 12px;
	}

	&:nth-child(4) {
		width: 22px;
		margin-bottom: 0;
	}
`;
