import React from 'react';
import QrReader from 'react-qr-reader';
import { scanSuccess } from '../actions';
import { connect } from 'react-redux';

const Scanner = ({ history, scanSuccess, ...props }) => {
	const handleScan = history => data => {
		if (data) {
			try {
				const qrData = JSON.parse(data);
				if ('amount' in qrData && 'account' in qrData) {
					scanSuccess(qrData);
					history.push('/send-payment');
				} else {
					throw new Error('Not valid qr code');
				}
			} catch (err) {
				console.log('Cannot parse scanned qr-code.', err);
			}
		}
	};

	const handleError = err => {
		console.error(err);
	};

	return <QrReader delay={300} onError={handleError} onScan={handleScan(history)} showViewFinder={false} />;
};

export default connect(
	null,
	{ scanSuccess }
)(Scanner);
