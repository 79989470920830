import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { PlainHeader } from '../../components';
import {
	Text,
	Background,
	ClearLayout,
	VerticalText,
	NextButton,
	CardWrapper,
	CardWithShadow,
} from '../../components/sharedStyles';
import { check } from '../../assets/images/icons';

const TextBottom = styled(Text)`
	width: 80%;
	text-align: center;
`;

const TextTop = styled(Text)`
	width: 90%;
	text-align: start;
`;

const MainContent = styled.div`
	position: relative;
	grid-row: 2;
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	padding-top: 50px;
`;

const BottomContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
	width: 100%;
	margin-bottom: 20px;
`;

const TopContainer = styled.div`
	display: flex;
	height: 80%;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	margin-left: 30px;
`;

const List = styled.ul`
 	list-style: none;
    padding-left: 0;
	width: 80%;

	& > li {
		position: relative;
    	padding-left: 25px;
	}

	& > li:before {
		content: '';
		width: 15px;
		height: 15px;
		position: absolute;
		background-image: url(${check});
		background-size: cover;
		background-position: center;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
	}
`;

const GrantAccessRequest = (props) => {
	const { history } = props;
	const clickBack = () => history.push('/add-bank-select-bank');
	const handleClick = () => history.push('/account-verified');
	const reset = () => history.push('/');

	return (
		<Background>
			<ClearLayout tRows="1fr 10fr">
				<PlainHeader rClick={reset} lClick={clickBack} controls={{ back: true, cross: true }} text="Retrieve your details" />
				<MainContent>
					<TopContainer>
						<VerticalText fontSize={36} height="auto">Retrieve details</VerticalText>
						<Content>
							<TextTop>In order to link a bank account you have to grant us some consents.</TextTop>
							<CardWrapper alignSelf="flex-end" width="100%">
								<CardWithShadow />
							</CardWrapper>
							<List>
								<li><Text bold>Advanced bank-grade encryption</Text></li>
								<li><Text bold>Controlled by you</Text></li>
								<li><Text bold>FCA regulated</Text></li>
							</List>
						</Content>
					</TopContainer>
					<BottomContainer>
						<TextBottom>This connection is protected by bank-level encryption</TextBottom>
						<NextButton onClick={handleClick}>confirm</NextButton>
					</BottomContainer>
				</MainContent>
			</ClearLayout>
		</Background>
	);
};

export const GrantAccessRequestPage = connect()(withRouter(GrantAccessRequest));
