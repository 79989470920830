import styled from 'styled-components';
import { close } from '../../assets/images/icons';
import backspace from '../../assets/images/backspace.svg';
import whiteClose from '../../assets/images/close-arrow-white.svg';
import whiteBackspace from '../../assets/images/backspace-white.svg';
import { colors } from '../sharedStyles';



export const Wrapper = styled.div`
    grid-row: 1;
    grid-column: 2;
    display: grid;
    grid-template-rows: repeat(4, 50px);
    grid-template-columns: repeat(3, 50px);;
    grid-auto-flow: row;
    grid-gap: 15px;
`;

export const Symb = styled.span`
    background-repeat: no-repeat;
    background-size: 100%;
    width: 16px;
    height: 16px;
    background-image: ${({inner}) => {
        if (inner === 'c') {
            return `url(${close})`
        }
        if (inner === 'b') {
            return `url(${backspace})`
        }
    }};
`;

export const Numeric = styled.span`
`;

export const NumButton = styled.button`
    background: #ffffff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-style: normal;
    font-size: 20px;
    line-height: 25px;
    border: none;
    outline: none;

    &:active {
        background: ${colors.mainBlack};
        color: #ffffff;
    }


    &:active > ${Symb} {
        background-image: ${({inner}) => {
            if (inner === 'c') {
                return `url(${whiteClose})`
            }
            if (inner === 'b') {
                return `url(${whiteBackspace})`
            }
        }}
    }
`;
