import React, { useState } from 'react';

import { Background, ClearLayout, Text, ButtonWrap, Input, NextButton } from '../sharedStyles';
import { PlainHeader, ButtonWLeftIcon, Popup } from '../../components';
import { ContentWrap, Button, InputWithLabel, Content } from './style';
import { deleteIcon } from '../../assets/images/icons';

const BankSettings = ({ history }) => {
    const [open, setOpen] = useState(false);
    const [grant, setOpenGrant] = useState(false);

    const togglePopup = () => {
        setOpen(current => !current);
    }

    const openGrantConsents = () => {
        setOpenGrant(true)
    }

	return (
		<Background>
			<ClearLayout>
				<PlainHeader
					text={'Bank Settings'}
                    controls={{ back: true, cross: true }}
                    lClick={history.goBack}
					rClick={history.goBack}
				/>
                <ContentWrap>
                    <InputWithLabel>
						<Text bold>Bank Nickname</Text>
						<Input
							type={'text'}
						/>
					</InputWithLabel>
                    {grant ? (
                            <>
                            <Content><Text bold uppercase margin={'0'}>Granted Concents</Text></Content>
                            <ButtonWLeftIcon
                                width={'90%'}
                                alignSelf={'flex-end'}
								white
								icon={'checkInvert'}
								largeIcon
								desc={'Expires 24 Aug 2019'}
								twoRows={1}
							>
								Your Account Details
							</ButtonWLeftIcon>
                            <ButtonWLeftIcon
                                width={'90%'}
                                alignSelf={'flex-end'}
                                white
                                error
								icon={'crossInvert'}
								desc={'Not granted'}
								twoRows={1}
							>
								Your Account Transactions
							</ButtonWLeftIcon>
                            <ButtonWLeftIcon
                                width={'90%'}
                                alignSelf={'flex-end'}
								white
								icon={'checkInvert'}
								largeIcon
								desc={'Expires 24 Aug 2019'}
								twoRows={1}
							>
								Your Contact Details
							</ButtonWLeftIcon>
                            </>
                        ) : (
                            <Content><ButtonWLeftIcon onClick={openGrantConsents} icon='check'>
                                Grant Consents
                            </ButtonWLeftIcon></Content>
                        )}
                </ContentWrap>
                <ButtonWrap row={4}>
                    <Button onClick={togglePopup} icon={deleteIcon}>Delete bank</Button>
                    <NextButton onClick={history.goBack}>Save & Close</NextButton>
                </ButtonWrap>
			</ClearLayout>
            <Popup title={'Delete a bank?'} ok={'Delete'} cancel isOpen={open} onOk={togglePopup} onCancel={togglePopup}>
                Are you sure that you want to delete the <b>Bank Name</b> from your payment methods?
            </Popup>
		</Background>
	);
};

export default BankSettings;
