import styled from 'styled-components';

export const Wrapper = styled.div`
	font-style: normal;
	font-weight: bold;
	font-size: 31px;
	line-height: 120%;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: 0.05em;
	text-transform: uppercase;

	@media screen and (min-width: 321px) {
		margin-bottom: 2vh;
	}
`;
