import styled from 'styled-components';
import { close, logo } from '../../assets/images/icons';

export const Wrapper = styled.div`
	position: absolute;
	grid-row: 1;
	display: grid;
	grid-template-columns: 0.3fr 1fr 0.3fr;
	height: 28vw;
	width: 100vw;
	z-index: 1;
	padding: 0 20px;
	top: -10px;
`;

export const Logo = styled.div`
	background-image: url(${logo});
	background-repeat: no-repeat;
	background-size: 100%;
	width: 82px;
	height: 56px;
	align-self: center;
	justify-self: center;
`;

export const Placeholder = styled.div`
	width: 24px;
	height: 24px;
`;

export const Cross = styled.div`
	width: 24px;
	height: 24px;
	position: relative;
	align-self: center;
	justify-self: end;

	&:before {
		position: absolute;
		content: '';
		width: 100%;
		height: 100%;
		background-image: url(${close});
		background-repeat: no-repeat;
		background-size: 100%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;
