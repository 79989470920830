import styled from 'styled-components';
import { colors, ContentGrid } from '../sharedStyles';
import arrow from '../../assets/images/drop-down-arrow.svg';

export const Content = styled(ContentGrid)`
	grid-row: 2;
	grid-template-columns: 70px 1fr;
	grid-template-rows: 2fr 1fr;

	@media screen and (min-width: 321px) {
		grid-template-columns: 90px 1fr;
	}
`;

export const Input = styled.input`
	background-color: rgba(${colors.mainBlackOP}, 0.1);
	outline: none;
	width: 100%;
	height: 50px;
	border: none;
	border-radius: 0px;
	border-bottom: 1px solid ${({ error }) => (error ? 'red' : colors.mainBlack)};
	padding: 15px 10px 15px 10px;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 140%;

	&::placeholder {
		color: ${colors.mainBlack};
		opacity: 0.7;
	}
`;

export const VerticalContent = styled.div`
	display: flex;
	margin-left: 20px;
	grid-row: 1;
	grid-column: 1;
`;

export const MainContent = styled.div`
	grid-row: 1;
	grid-column: 2;
	justify-self: end;
	width: 90%;
	display: grid;
	grid-template-rows: 1fr 1fr;
	height: 17vh;
	row-gap: 10px;
`;

export const WrapSelect = styled.div`
	width: 96px;
	height: 50px;
	position: relative;
	&::before {
		content: '';
		position: absolute;
		top: 50%;
		right: 10px;
		width: 16px;
		height: 16px;
		background-image: url(${arrow});
		background-repeat: no-repeat;
		background-size: 100%;
		pointer-events: none;
		transform: translateY(-50%);
	}
`;

export const Select = styled.select`
	background-color: #e9c406;
	color: ${colors.mainBlack};
	width: 100%;
	height: 100%;
	border: none;
	border-radius: 0px;
	border-bottom: 1px solid ${colors.mainBlack};
	-webkit-appearance: button;
	appearance: button;
	outline: none;
	padding: 15px 10px 15px 10px;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 140%;
	box-shadow: none !important;

	&:focus {
		outline: none;
	}
	& option {
		padding: 30px;
	}
`;

export const Text = styled.div`
	margin-bottom: 3px;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 16px;
	letter-spacing: 0.05em;
`;

export const WrapMobile = styled.div`
	display: grid;
	grid-template-columns: 0.5fr 2fr;
	grid-template-rows: 0.3fr 2fr;
	column-gap: 10px;

	& > ${Text} {
		grid-column: 1 / -1;
		grid-row: 1;
	}

	& > ${WrapSelect} {
		grid-column: 1;
		grid-row: 2;
	}

	& > ${Input} {
		grid-column: 2;
		grid-row: 2;
	}
`;

export const WrapCheckbox = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	& > ${Text} {
		width: 60%;
		font-weight: normal;
		margin-left: 10px;
	}
`;
