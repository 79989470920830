import styled from 'styled-components';
import { ContentGrid, colors } from '../sharedStyles';

export const ButtonSection = styled.div`
	grid-column: 2 / -1;
	grid-row: 2;
	align-self: flex-start;
	margin-left: 30px;
`;

export const ContentWrapper = styled(ContentGrid)`
	grid-row: 2;
	grid-column: 1;
	grid-template-columns: 30px 50px 1fr;
	grid-template-rows: 1fr 1fr;
`;

export const Wrap = styled.div`
	display: flex;
	margin-left: 20px;
	grid-row: 1;
	grid-column: 2;
`;

export const Text = styled.p`
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 140%;
	margin-block-start: 0;
	margin-block-end: 0;
`;

export const ReqPaymentContent = styled.div`
	grid-column: 3;
	grid-row: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
`;

ReqPaymentContent.Header = styled.div`
	font-style: normal;
	/* font-weight: bold; */
	font-size: 15px;
	line-height: 140%;
	text-align: center;
	letter-spacing: 0.05em;
	color: rgba(${colors.mainBlackOP}, 0.6);
	width: 100%;
`;
