import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Amount, NumPanel, PlainHeader } from '../../components';
import {
	Background,
	colors,
	ClearLayout,
	NextButton,
} from '../../components/sharedStyles';

export const PaymentSetup = ({ history, amount }) => {
	return (
		<Background>
			<ClearLayout tRows="1fr 10fr">
				<PlainHeader lClick={() => history.push('/start-send-payment')} controls={{ back: true }} text="Send Payment" />
				<ContentWrap>
					<NumPanelContainer>
						<Header>Enter the amount you want to send</Header>
						<Amount amount={amount} />
						<NumPanel />
					</NumPanelContainer>
					<NextButton onClick={() => history.push('/send-payment')}>Next</NextButton>
				</ContentWrap>
			</ClearLayout>
		</Background>
	);
};

const mapStateToProps = (state) => ({
	amount: state.accountDetails.amount,
});

export const PaymentSetupPage = connect(mapStateToProps, null)(PaymentSetup);

const NumPanelContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	grid-row: 1;
`;

const ContentWrap = styled.div`
	display: grid;
	width: 100%;
	grid-row: 2;
	grid-template-columns: 1fr;
	grid-template-rows: 4fr 1fr;
	margin-top: 25px;
`;

const Header = styled.div`
	font-style: normal;
	/* font-weight: bold; */
	font-size: 15px;
	line-height: 140%;
	text-align: center;
	letter-spacing: 0.05em;
	color: rgba(${colors.mainBlackOP}, 0.6);
	width: 100%;
`;
