import React from 'react';
import { PinPanel, PinInput } from '../../components';
import { PinWrapper } from './style';

const Step3 = ({ handleChange, formData, ...props }) => {
	return (
		<PinWrapper>
			<PinInput pin={formData.pin_s} error={formData.errors.step2.pin_s_err} />
			<PinPanel handler={handleChange('pin_s', 'pin')} />
		</PinWrapper>
	);
};

export default Step3;
