import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import rootSaga from './sagas';
import Main from './components/Main';

export const store = configureStore(window.__INITIAL_STATE__);
store.runSaga(rootSaga);

function App () {
	return (
		<Provider store={store}>
			<Router>
				<Main />
			</Router>
		</Provider>
	);
}

export default App;
