import React from 'react';
import styled from 'styled-components';
import { PlainHeader, SquareButton } from '../../components';
import {
	Background,
	ClearLayout,
} from '../../components/sharedStyles';

const ContentWrap = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	grid-row: 2;
	margin: 40px 0 20px;
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 170px;
`;

export const SendOrRequestPage = (props) => {
	const { history } = props;
	return (
		<Background>
			<ClearLayout tRows="1fr 10fr">
				<PlainHeader lClick={() => {}} controls={{ burger: true }} white text="Send or Request" />
				<ContentWrap>
					<ButtonContainer>
						<SquareButton onClick={() => history.push('/start-payment-request')} label="Request Payment" />
						<SquareButton onClick={() => history.push('/start-send-payment')} label="Send Payment" up />
					</ButtonContainer>
				</ContentWrap>
			</ClearLayout>
		</Background>
	);
};
