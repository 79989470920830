import React from 'react';
import styled from 'styled-components';
import {
	Wrapper,
	Logo,
	Placeholder,
	Cross,
} from './style';
import { Burger } from '../Burger';
import { arrowBack, iconMessage } from '../../assets/images/icons';

const IconButton = styled.div`
	background-image: url(${({ icon }) => icon});
	background-repeat: no-repeat;
	background-size: 100%;
	width: 24px;
	height: 24px;
	align-self: center;
	justify-self: flex-start;
`;

export const NavWithLogo = ({ next, controlsConfig }) => {
	const {
		rightButtonHandler,
		leftButtonHandler,
		notifs,
		cross,
		clear,
		back,
	} = controlsConfig;

	let RightButton;
	if (cross) {
		RightButton = <Cross onClick={rightButtonHandler} />;
	} else if (clear) {
		RightButton = <div />;
	} else {
		RightButton = <Burger next={next} />;
	}

	let LeftButton;
	if (back) {
		LeftButton = <IconButton icon={arrowBack} onClick={leftButtonHandler} />;
	} else if (notifs) {
		LeftButton = <IconButton icon={iconMessage} onClick={leftButtonHandler} />;
	} else {
		LeftButton = <Placeholder />;
	}

	return (
		<Wrapper>
			{LeftButton}
			<Logo />
			{RightButton}
		</Wrapper>
	);
};
