import React, { useRef, useState } from 'react';
import { useSprings } from 'react-spring';
import { useGesture } from 'react-use-gesture';
import { Background, ClearLayout, Text, ButtonWrap, NextButton, Plus } from '../sharedStyles';
import { PlainHeader, SubHeader } from '../../components';
import { iconSettings, bankInvert } from '../../assets/images/icons';
import { ContentWrap, Card, CardsList, LinkedWrapper } from './style';
import Dots from './Dots';


const pages = [
	['ADD ACCOUNT DETAILS', 'Want to get the most out of bopp? Link your account details'],
	['Quick', 'We can make the payment journey even quicker for you by pre-selecting your favourite account'],
	['Easy', 'With your account details saved, you can make it easy for friends & family to send you money'],
	[
		'Informative',
		'If you want you can  use bopp to view all your transactions from all your bank accounts (even across different banks)',
	],
	[
		'Safe',
		'We will never sell your data to anyone. We will never share your data with anyone (unless required to by Law Enforcement).',
	],
];

const SavedBank = ({ history }) => {
	const media = window.matchMedia('(min-width: 321px)').matches;
	const clamp = (num, clamp, higher) => (higher ? Math.min(Math.max(num, clamp), higher) : Math.min(num, clamp));
	const index = useRef(0);
	const [current, setCurrent] = useState(0);

	const [props, set] = useSprings(pages.length, i => ({
		x: i * window.innerWidth * (media ? 0.85 : 1),
		display: 'block',
	}));
	const bind = useGesture(({ down, delta: [xDelta], direction: [xDir], distance, cancel }) => {
		if (down && distance > window.innerWidth / 3) {
			cancel((index.current = clamp(index.current + (xDir > 0 ? -1 : 1), 0, pages.length - 1)));
		}
		set((i) => {
			if (i < index.current - 1 || i > index.current + 1) {
				return { display: 'none' };
			}
			const x = (i - index.current) * window.innerWidth * (media ? 0.85 : 1) + (down ? xDelta : 0);
			return { x, display: 'block' };
		});
		setCurrent(() => index.current);
	});

	return (
		<Background>
			<ClearLayout tRows={'1fr 8fr 1fr'}>
				<PlainHeader text={'Saved Bank'} controls={{ cross: true }} rClick={history.goBack}>
					<SubHeader
						// TODO: dynamic bank
						leftIcon={bankInvert}
						rightIcon={iconSettings}
						title={'Barclays'}
						text={'Bank'}
					/>
				</PlainHeader>
				<ContentWrap>
					<LinkedWrapper>
						<Text bold uppercase margin={'10px 0 30px 0'}>
							Linked accounts
						</Text>
						<Plus />
					</LinkedWrapper>
					<CardsList>
						{props.map(({ x, display }, i) => (
							<Card
								{...bind()}
								key={i}
								style={{ display, transform: x.interpolate(x => `translateX(${x}px)`) }}
							>
								<Card.Header>{pages[i][0]}</Card.Header>
								<Card.Content main>{pages[i][1]}</Card.Content>
								<Card.Next />
							</Card>
						))}
						<Dots current={current} pages={pages} />
					</CardsList>
				</ContentWrap>
				<ButtonWrap row={3}>
					<NextButton onClick={history.goBack} marginTop={'0px'}>
						Close
					</NextButton>
				</ButtonWrap>
			</ClearLayout>
		</Background>
	);
};

export default SavedBank;
