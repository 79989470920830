import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import { Layout } from '../../components';
import { addPayeeAccount, saveContact, setPayeeAcc } from '../../actions';
import {
	VerticalText,
	ContentGrid,
	ButtonWrap,
	Input,
	BackButton,
	NextButton,
} from '../../components/sharedStyles';

const initialState = {
	sortCode: '',
	accountNumber: '',
	accountName: '',
	errors: {
		sortCode_err: '',
		accountNumber_err: '',
		accountName_err: '',
	},
};

const AccountDetailForm = ({
	history,
	addPayeeAccount,
	setPayeeAcc,
	saveContact,
	config,
	account,
}) => {
	const [state, setState] = useState(initialState);
	const { sideText, header, buttons } = config;

	const validate = () => {
		const { errors } = state;
		errors.sortCode_err = state.sortCode.length < 6;
		errors.accountNumber_err = state.accountNumber.split(' ').join('').length < 8;
		errors.accountName_err = state.accountName.length === 0;

		setState((prevState) => ({ ...prevState, errors }));

		return Object.values(errors).indexOf(true) === -1;
	};

	const handleChange = (input) => (e) => {
		setState({ ...state, [input]: e.target.value });
	};

	const save = (returnToList) => {
		if (validate()) {
			const passState = { ...state, errors: { ...state.errors } };
			delete passState.errors;
			saveContact(passState);
			if (returnToList) {
				history.push('/');
			} else {
				addPayeeAccount(passState);
				if (account) {
					setPayeeAcc(passState);
					history.push('/send-payment');
				} else {
					history.push('/request-payment');
				}
			}
		}
	};

	return (
		<Layout config={header}>
			<Content>
				<VerticalContent>
					<VerticalText height="50vh">{sideText}</VerticalText>
				</VerticalContent>
				<MainContent>
					<div>
						<Text>Account Name</Text>
						<Input
							value={state.accountName}
							onChange={handleChange('accountName')}
							error={state.errors.accountName_err ? 1 : 0}
						/>
					</div>
					<div>
						<Text>Sort Code</Text>
						<Input
							type="tel"
							as={NumberFormat}
							onChange={handleChange('sortCode')}
							value={state.sortCode}
							format="## ## ##"
							error={state.errors.sortCode_err ? 1 : 0}
						/>
					</div>
					<div>
						<Text>Account Number</Text>
						<Input
							type="tel"
							as={NumberFormat}
							onChange={handleChange('accountNumber')}
							value={state.accountNumber}
							format="## ## ## ##"
							error={state.errors.accountNumber_err ? 1 : 0}
						/>
					</div>
				</MainContent>
				<ButtonWrap>
					<BackButton onClick={() => save(true)}>{buttons.topButtonText}</BackButton>
					<NextButton onClick={() => save()}>{buttons.bottomButtonText}</NextButton>
				</ButtonWrap>
			</Content>
		</Layout>
	);
};

const mapStateToProps = (state) => ({
	account: state.dataForOutgoingPayment.account,
});

const mapDispatchToProps = {
	setPayeeAcc,
	addPayeeAccount,
	saveContact,
};

export const AccountDetailFormScreen = connect(mapStateToProps, mapDispatchToProps)(AccountDetailForm);

const Content = styled(ContentGrid)`
	grid-row: 2;
	display: grid;
	column-gap: 20px;
	grid-template-columns: 70px 1fr;
	grid-template-rows: 1fr 1fr;
	@media screen and (min-width: 321px) {
		grid-template-columns: 90px 1fr;
	}

	&:focus-within {
		row-gap: 30px;
	}
`;

const VerticalContent = styled.div`
	display: flex;
	margin-left: 20px;
	grid-row: 1;
	grid-column: 1;
`;

const MainContent = styled.div`
	grid-row: 1;
	grid-column: 2;
	display: grid;
	row-gap: 20px;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(3, 60px);

	@media screen and (min-width: 321px) {
		grid-template-rows: repeat(3, 70px);
	}
`;

const Text = styled.div`
	margin-bottom: 3px;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 16px;
	letter-spacing: 0.05em;
`;
