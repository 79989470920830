import { combineReducers } from 'redux';
import accountDetails from './accountDetails';
import banksData from './banksData';
import contactsData from './contactsData';
import dataForOutgoingPayment from './dataForOutgoingPayment';
import errors from './errors';
import openBanking from './openBanking';
import preferableMethods from './preferableMethods';
import { preferableReceiverAccount } from './preferableReceiverAccount';
import { receiverListOfBanks } from './receiverListOfBanks';
import transactionDetails from './transactionDetails';

const rootReducer = combineReducers({
	accountDetails,
	banksData,
	contactsData,
	dataForOutgoingPayment,
	errors,
	openBanking,
	preferableMethods,
	preferableReceiverAccount,
	receiverListOfBanks,
	transactionDetails,
});

export default rootReducer;
