import styled from 'styled-components';
import { colors } from '../sharedStyles';


export const ClearLayout = styled.div`
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 1fr;
	grid-auto-rows: 0.7fr 3fr 0.4fr;
`;

export const BlackButton = styled.button`
	width: 100%;
	height: 100%;
	border: none;
	background-color: ${colors.mainBlack};
	color: #ffffff;
	font-weight: 800;
	font-size: 16px;
	line-height: 22px;
	text-transform: uppercase;
`;

export const Top = styled.div`
	grid-row: 1;
	display: grid;
	grid-template-rows: 1fr;
	grid-template-columns: 1fr 38px;
	padding: 0 20px 40px;
	justify-items: center;
`;

export const Bottom = styled.div`
	width: 100%;
	height: 100%;
	padding: 0 20px 20px;
	grid-row: 3;
`;

export const TextButton = styled.button`
	width: 100%;
	height: 100%;
	border: none;
	background-color: transparent;
	font-weight: 800;
	font-size: 16px;
	line-height: 22px;
	text-transform: uppercase;
`;