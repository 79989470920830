import React from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';

import { Background, ClearLayout, VerticalText, Text, ButtonWrap, NextButton, CardWrapper } from '../sharedStyles';
import { PlainHeader } from '../../components';
import { calendar } from '../../assets/images/icons';
import { ContentWrap, Content, Date, TransactionCard} from './style';

const TransactionDetails = ({ transactionDetails: transaction, history }) => {
	return (
		<Background>
			<ClearLayout tRows={'1fr 10fr'}>
				<PlainHeader lClick={history.goBack} controls={{ back: true, edit: true }} text={'Transaction Details'} />
				<ContentWrap>
					<VerticalText>Transaction</VerticalText>
					<Content>
						<Date icon={calendar}>{format(1566571803468, 'dd MMM ‘yy')} at {transaction.time}</Date>
						<Text>Amount</Text>
						<Text big bold>
							£{Math.abs(transaction.amount)}
						</Text>
						<Text>to</Text>
						<CardWrapper width={'calc(100% - 10px)'} stretch alignSelf={'flex-end'}>
							<TransactionCard>
								<TransactionCard.Icon type={'business'} />
								<TransactionCard.Code><span>TX ID</span> <span>{transaction.account.sortCode}</span></TransactionCard.Code>
								<TransactionCard.Header>{transaction.account.accountName}</TransactionCard.Header>
								<TransactionCard.Details>{transaction.details}</TransactionCard.Details>
								<TransactionCard.Account icon={transaction.account.accountType}>{transaction.by}</TransactionCard.Account>
								<TransactionCard.Categories icon={transaction.categories}></TransactionCard.Categories>
							</TransactionCard>
						</CardWrapper>
					</Content>
				</ContentWrap>
				<ButtonWrap row={3}>
					<NextButton onClick={history.goBack}>Back</NextButton>
				</ButtonWrap>
			</ClearLayout>
		</Background>
	);
};

function mapStateToProps(state) {
	return {
		transactionDetails: state.transactionDetails.transaction,
	};
}

export default connect(
	mapStateToProps,
	null,
)(TransactionDetails);
