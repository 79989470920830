import styled from 'styled-components';

export const Wrapper = styled.div`
	display: grid;
	width: 100%;
	height: 100vh;
	position: relative;
	grid-template-rows: 0.3fr 1.1fr;
	grid-template-columns: 1fr;
	overflow-y: ${({ fixed }) => (fixed ? 'hidden' : 'auto')};

	@media screen and (min-width: 321px) {
		grid-template-rows: ${({ fixed, squish }) => {
			if (fixed) {
				return 'minmax(150px, 30vw) minmax(350px, 50vw)';
			}
			if (squish) {
				return '0.35fr 1fr';
			}
			if (!squish && !fixed) {
				return '0.3fr 1.1fr';
			}
		}};
	}

	@media screen and (min-width: 376px) {
		grid-template-rows: ${({ fixed, squish }) => {
			if (fixed) {
				return 'minmax(170px, 30vw) minmax(350px, 50vw)';
			}
			if (!squish && !fixed) {
				return '0.3fr 1fr';
			}
		}};
	}

	&:focus-within > div:last-child {
		overflow-y: hidden;
		height: 130vh;
	}
`;
