import React from 'react';
import { AccountConfirmationScreen } from '../../screens';


export const AccountAddedPage = () => {
	const config = {
		vText: 'Account Added',
		bText: 'Finish',
	};

	return (
		<AccountConfirmationScreen config={config} />
	);
};
