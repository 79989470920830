import React, { useState, useEffect } from 'react';

import TransactionCard from './TransactionCard';
import styled from 'styled-components';
import formatRelative from 'date-fns/formatRelative';
import { enGB } from 'date-fns/esm/locale';

import { calendar, refresh } from '../../assets/images/icons';

const formatRelativeLocale = {
	yesterday: "'yesterday'",
	today: "'today'",
	other: 'dd MMM yyyy',
};

const locale = { ...enGB, formatRelative: token => formatRelativeLocale[token] || formatRelativeLocale.other};

const TransactionsGroup = ({ transactionsList, date, onSelectTransaction, refresh }) => {
	const [win, setWin] = useState(null);
	const [now, setNow] = useState(0);
	useEffect(() => setWin(() => window), []);
	useEffect(() => {
		if (win) {
			setNow(() => win.Date.now());
		}
	}, [win]);

	return (
		<Wrapper>
			<Header>
				<Date icon={calendar}>{formatRelative(date, now, { locale })}</Date>
				{refresh && <Refresh>Refresh</Refresh>}
			</Header>
			{transactionsList.map(t => (
				<TransactionCard key={t.time} transaction={t} onSelectTransaction={() => onSelectTransaction(Object.assign(t, {date: date}))}/>
			))}
		</Wrapper>
	);
};

export default TransactionsGroup;

const Header = styled.div`
	display: grid;
`

const Date = styled.div`
	font-style: normal;
	font-weight: 800;
	font-size: 16px;
	line-height: 22px;
	align-items: center;
	text-transform: uppercase;
	position: relative;
	margin-bottom: 15px;
	margin-top: 20px;
	left: 28px;

	&:before {
		position: absolute;
		content: '';
		left: -28px;
		width: 20px;
		height: 20px;
		background-image: ${({ icon }) => `url(${icon})`};
		background-repeat: no-repeat;
		background-size: 100%;
	}
`;

const Refresh = styled.div`
	font-style: normal;
	font-weight: 800;
	font-size: 16px;
	line-height: 22px;
	align-items: center;
	text-transform: uppercase;
	position: relative;
	margin-bottom: 15px;
	margin-top: 20px;

	grid-column: 2;
	justify-self: end;

	&:before {
		position: absolute;
		content: '';
		left: -23px;
		top: 2px;
		width: 20px;
		height: 20px;
		background-image: url(${refresh});
		background-repeat: no-repeat;
		background-size: 100%;
	}
`

const Wrapper = styled.div``;
