import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { colors } from '../sharedStyles';

const DotSlider = ({ routes }) => {
	return (
        <Wrapper>
            {routes.map(({ route }) => (
                <StyledLink key={route} to={route} activeClassName='active'/>
            ))}
        </Wrapper>
    );
};

export default DotSlider;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50px;
    align-items: center;
    margin-left: 38px;
`;

const StyledLink = styled(NavLink)`
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: ${colors.mainBlack};
    opacity: 0.1;
`;