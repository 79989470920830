import React, { useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import QRCode from 'qrcode.react';
import { Layout } from '../../components';
import { BackButton, Text, colors } from '../../components/sharedStyles';
import { logo } from '../../assets/images/icons';

const Wrapper = styled.div`
	grid-row: 2;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 0 20px 30px;

	@media screen and (max-width: 320px) {
		padding: 0 10px;
	}
`;

const Top = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	color: white;
`;

const DottedLine = styled.hr`
	border: none;
	border-top: 1px dotted #ffffff;
	color: #fff;
	background-color: transparent;
	height: 1px;
	width: 100%;
	margin: 20px 0;
	align-self: flex-end;
	opacity: 0.2;
	
	@media screen and (max-width: 320px) {
		margin: 10px 0;
	}
`;

const InvText = styled(Text)`
	color: #ffffff;
`;

const Middle = styled(Top)`
	justify-content: center;
	align-items: center;
`;
const Bottom = styled(Middle)``;

const QrWrap = styled.div`
	& > canvas {
		border: 5px solid ${colors.mainOrange};
	}
`;

const Copy = styled.textarea`
	position: absolute;
	left: -100px;
	top: -100px;
	width: 1px;
	height: 1px;
`;

export const RequestQRLink = (props) => {
	const copyRef = useRef();
	const {
		accountName,
		accountNumber,
		sortCode,
		history,
		amount,
	} = props;
	const layoutConfig = {
		cross: true,
		rightButtonHandler: () => history.push('/'),
		leftButtonHandler: () => history.push('/start-payment-request'),
		back: true,
	};
	const qrLogo = 30;
	const imageSettings = {
		src: logo,
		excavate: false,
		height: qrLogo,
		width: qrLogo * 1.48,
	};

	const stripA = amount.split(',').join('');
	const stripSC = sortCode.split(' ').join('');
	const stripAN = accountNumber.split(' ').join('');
	const link = `${process.env.REACT_APP_BOPP}/send-payment?total=${stripA}&name=${accountName}&acc=${stripAN}&sort=${stripSC}`;

	const copyLink = () => {
		copyRef.current.value = link;
		copyRef.current.select();
		document.execCommand('copy');
		copyRef.current.style = 'display: none;';
	};

	return (
		<Layout black config={layoutConfig}>
			<Wrapper>
				<Top>
					<InvText>You are requesting</InvText>
					<InvText big margin="0">£{amount}</InvText>
					<DottedLine />
				</Top>
				<Middle>
					<InvText fSize="18px">Show the QR code to scan</InvText>
					<QrWrap>
						<QRCode
							bgColor={colors.mainOrange}
							fgColor={colors.mainBlack}
							size={150}
							imageSettings={imageSettings}
							value={link}
						/>
					</QrWrap>
				</Middle>
				<Bottom>
					<DottedLine />
					<InvText fSize="18px">Share a payment link</InvText>
					<InvText small>Send your payment request via your favourite messaging apps</InvText>
					<BackButton onClick={copyLink} width="100%">Send Payment Link</BackButton>
					<Copy ref={copyRef} />
				</Bottom>
			</Wrapper>
		</Layout>
	);
};

const mapStateToProps = (state) => ({
	amount: state.accountDetails.amount,
	accountName: state.preferableReceiverAccount.accountName,
	accountNumber: state.preferableReceiverAccount.accountNumber,
	sortCode: state.preferableReceiverAccount.sortCode,
});

export const RequestQRLinkPage = connect(mapStateToProps, null)(RequestQRLink);

RequestQRLink.defaultProps = {
	amount: '0.00',
	accountName: 'NA',
	accountNumber: '00 00 00 00',
	sortCode: '00 00 00',
};
