import React from 'react';
import Checkbox from 'rc-checkbox';

import { Background, ClearLayout, Text, ButtonWrap, NextButton, Plus, CardWrapper } from '../sharedStyles';
import { PlainHeader, SubHeader } from '../../components';
import { iconSettings, bankInvert } from '../../assets/images/icons';
import { ContentWrap, Account } from './style';

const BankAccount = ({ history }) => {
	return (
		<Background>
			<ClearLayout tRows={'1fr 1fr 8fr'}>
				<PlainHeader
					text={'Bank'}
					controls={{ back: true, cross: true }}
					lClick={history.goBack}
				/>
				<SubHeader
                    leftIcon={bankInvert}
                    rightIcon={iconSettings}
                    rClick={() => history.push('/bank-settings')}
                    title={'Barclays'}
                    text={'Bank'}
                />
                <ContentWrap>
                    <Text bold uppercase margin={'10px 0 30px 0'}>Linked accounts (1)</Text>
                    <Plus />
                    <CardWrapper stretch>
                        <Account offset={-6}>
                            <Account.Header>
                                <Account.IconLeft></Account.IconLeft>
                                <Account.Text bold>Personal Bank Account</Account.Text>
                                <Account.Text small row={2}>Ian Gass</Account.Text>
                                <Account.IconRight onClick={() => history.push('/bank-acc-settings')}></Account.IconRight>
                            </Account.Header>
                            <Account.Line></Account.Line>
                            <Account.Text small column={2} row={3}>Account number</Account.Text>
                            <Account.Text small bold column={3} row={3} right>1234 5678</Account.Text>
                            <Account.Text small column={2} row={4}>Sort Code</Account.Text>
                            <Account.Text small bold column={3} row={4} right>20 31 24</Account.Text>
                            <Account.Line></Account.Line>
                            <Account.WrapCheckbox width={'100%'} fSize={'13px'}>
                                <Checkbox />
                                <Text>Set as Default payment method </Text>
                            </Account.WrapCheckbox>
                        </Account>
                    </CardWrapper>
                </ContentWrap>
                <ButtonWrap row={4}>
                    <NextButton onClick={history.goBack}>Close</NextButton>
                </ButtonWrap>
			</ClearLayout>
		</Background>
	);
};

export default BankAccount;
