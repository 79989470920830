import React from 'react';
import { Wrapper, Text, LeftButton, RightButton, Title } from './style';

const SubHeader = ({ white, text, title, leftIcon, rightIcon, rClick, children }) => {
	return (
		<Wrapper extend={children} white={white}>
			<LeftButton icon={leftIcon} />
            <Title>{title}</Title>
			<Text>{text}</Text>
			<RightButton onClick={rClick} icon={rightIcon} />
			{children}
		</Wrapper>
	);
};

SubHeader.defaultProps = {
	rClick: () => {},
};

export default SubHeader;
