import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from './sharedStyles';
import eye from '../assets/images/eye-icon.svg';

const PinInput = ({ pin, lenght = 4, error }) => {
	const [isHidden, setVisibility] = useState(true);

	return (
		<Wrapper>
			<Eye
				isHidden={isHidden}
				onClick={() => {
					setVisibility(state => !state);
				}}
			/>
			{Array(lenght)
				.fill('')
				.map((val, index) => (
					<Input
						error={error}
						key={index}
						readOnly
						required
						type={isHidden ? 'password' : 'number'}
						value={!isNaN(pin[index]) ? pin[index] : ''}
					/>
				))}
		</Wrapper>
	);
};

export default PinInput;

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const Input = styled.input`
	outline: none;
	width: 20px;
	height: 30px;
	-webkit-appearance: none;
	appearance: none;
	border-radius: 0;
	border: none;
	padding: 0;
	text-align: center;
	margin-right: 5px;
	color: #ffffff;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 140%;
	background-color: ${({ value, error }) => {
		if (value !== 0 && !value && error) {
			return 'red';
		} else if (value === 0 || !!value) {
			return `${colors.mainBlack}`;
		} else {
			return `rgba(${colors.mainBlackOP}, 0.1)`;
		}
	}};

	&:focus {
		border-color: inherit;
		outline: none;
	}
`;

const Eye = styled.div`
	width: 20px;
	height: 20px;
	position: relative;
	opacity: ${({ isHidden }) => (isHidden ? '0.5' : '1')};
	margin-right: 25px;

	&:before {
		position: absolute;
		width: 100%;
		height: 100%;
		content: '';
		background-image: url(${eye});
		background-size: 100%;
		background-repeat: no-repeat;
	}
`;
