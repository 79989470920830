export const generateUUID = () => {
	return ([1e7] + -1e3 + -1).replace(/[018]/g, (c) =>
		(c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
	);
};

export const formatTotal = (total) => {
	if (total) {
		if (total.includes('.')) {
			return total.slice(0, -2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + total.slice(-2);
		}
		return total.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + '.00';
	}
};

export const parseJwt = (token) => {
	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	const jsonPayload = decodeURIComponent(
		atob(base64)
			.split('')
			.map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
			.join('')
	);

	return JSON.parse(jsonPayload);
};
