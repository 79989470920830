import { SET_PREFER_RECEIVER_ACC } from '../actions/constants';

const initState = localStorage.prefAcc ? JSON.parse(localStorage.prefAcc) : {};

export const preferableReceiverAccount = (state = initState, action) => {
	switch (action.type) {
		case SET_PREFER_RECEIVER_ACC: {
			return { ...action.acc };
		}
		default:
			return state;
	}
};
