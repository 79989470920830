import styled from 'styled-components';

import { CardWithShadow } from '../sharedStyles';
import { Card } from '../ContentCard/style';
import { Transaction } from '../TransactionsScreen/style';

export const ContentWrap = styled.div`
	display: grid;
	grid-template-rows: 1fr;
	grid-auto-columns: 50px 1fr;
	grid-column-gap: 10px;
	margin-top: 50px;

	@media screen and (min-width: 321px) {
		grid-auto-columns: 70px 1fr;
		grid-column-gap: 30px;
	}
`;

export const Content = styled.div`
	grid-column: 2;
	display: flex;
	flex-direction: column;
`;

export const TransactionCard = styled(CardWithShadow)`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-row-gap: 5px;
`

TransactionCard.Icon = styled(Card.Icon)`
	grid-row: 1;
	margin-bottom: 0;
`

TransactionCard.Header = styled(Transaction.Header)`
	grid-row: 2;
`

TransactionCard.Code = styled(Transaction.Header)`
	grid-column-start: 2;
	grid-column-end: 4;
	align-self: center;
	font-size: 13px;
	font-style: normal;
	font-weight: normal;

	span {
		&:nth-child(1) {
			font-weight: bold;
			color: #21242B;
			opacity: .4;
		}
	}
`

TransactionCard.Details = styled(Transaction.Details)`
	grid-row: 3;
	grid-column: 1 / 4;
`

TransactionCard.Account = styled(Transaction.Account)`
	grid-row: 4;
`

TransactionCard.Categories = styled(Transaction.Categories)`
	grid-row: 4;
`

export const Date = styled.div`
	font-style: normal;
	font-weight: 800;
	font-size: 16px;
	line-height: 22px;
	align-items: center;
	text-transform: uppercase;
	position: relative;
	margin: 20px 0 15px 0;
	
	left: 28px;

	&:before {
		position: absolute;
		content: '';
		left: -28px;
		width: 20px;
		height: 20px;
		background-image: ${({ icon }) => `url(${icon})`};
		background-repeat: no-repeat;
		background-size: 100%;
	}
`;
