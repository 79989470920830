import React from 'react';

import { Background, ClearLayout } from '../sharedStyles';
import { PlainHeader } from '../../components';
import { ContentWrap, Button } from './style';

import { user } from '../../assets/images/icons';

const SettingsScreen = ({ history, ...props }) => {
	return (
		<Background>
			<ClearLayout>
				<PlainHeader
					text={'Settings'}
					controls={{ burger: true }}
					white
					rClick={() => history.push('/menu')}
				/>
                <ContentWrap>
                    <Button to='/' white padding={'5px 0 5px 60px'} margin={'0 0 30px 0'}>
                        {/* TODO: user profile image */}
                        <Button.Icon img={user}/>
                        <Button.Header>Samanta Adler</Button.Header>
                        <Button.Description>View your profile</Button.Description>
                    </Button>
					<Button>
                        <Button.Header>Security</Button.Header>
                        <Button.Description>Change PIN, use fingerprint, etc.</Button.Description>
                    </Button>
					<Button>
                        <Button.Header>Invite Friends</Button.Header>
                        <Button.Description>Share the link to login for friends</Button.Description>
                    </Button>
					<Button>
                        <Button.Header>Contact Bopp</Button.Header>
                        <Button.Description>Use this for  questions or suggections</Button.Description>
                    </Button>
					<Button>
                        <Button.Header>Legal &#38; Privacy</Button.Header>
                        <Button.Description>View agreements</Button.Description>
                    </Button>
					<Button>
                        <Button.Header>Help Center</Button.Header>
                        <Button.Description>Do you need any help?</Button.Description>
                    </Button>
                </ContentWrap>
			</ClearLayout>
		</Background>
	);
};

export default SettingsScreen;
