import React, { useState } from 'react';
import { Layout } from '../../components';
import styled from 'styled-components';
import { VerticalText, ContentGrid, ButtonWrap, BackButton, NextButton } from '../sharedStyles';
import { validEmailRegex } from '../sharedData';
import 'rc-checkbox/assets/index.css';
import { Link, withRouter } from 'react-router-dom';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

const steps = [Step1, Step2, Step3];

const sideText = ['Signup', 'Set PIN', 'Repeat PIN'];

const StepLayout = ({ history }) => {
	const [step, setStep] = useState(0);
	const [formData, setFormData] = useState({
		name: '',
		countryCode: '+44',
		phone: '',
		email: '',
		agreed: false,
		pin_f: [],
		pin_s: [],
		errors: {
			step0: {
				name_err: false,
				phone_err: false,
				email_err: false,
				agreed_err: false,
			},
			step1: {
				pin_f_err: false,
			},
			step2: {
				pin_s_err: false,
			},
		},
	});

	const validateFields = () => {
		const { errors } = formData;
		if (step === 0) {
			errors.step0.name_err = formData.name.length === 0;

			errors.step0.phone_err = formData.phone.split(' ').join('').length <= 9;

			errors.step0.email_err = !validEmailRegex.test(formData.email);

			errors.step0.agreed_err = !formData.agreed;
		}

		if (step === 1) {
			errors.step1.pin_f_err = formData.pin_f.length !== 4;
		}

		if (step === 2) {
			errors.step2.pin_s_err = formData.pin_s.length !== 4;
		}
		setFormData(state => ({ ...state, errors }));

		return errors;
	};

	const nextStep = () => {
		const err = validateFields();
		if (Object.values(err[`step${step}`]).indexOf(true) === -1) {
			setStep(state => state + 1);
		}
	};
	const prevStep = () => setStep(state => state - 1);

	const handleChange = (input, type) => (e, command) => {
		if (type === 'checkbox') {
			setFormData({ ...formData, [input]: e.target.checked });
		}

		if (type === 'pin') {
			switch (command) {
				case 'clear':
					setFormData({ ...formData, [input]: [] });
					return;
				case 'remove':
					setFormData({ ...formData, [input]: formData[input].slice(0, formData[input].length - 1) });
					return;

				default:
					if (formData[input].length <= 3) {
						setFormData({ ...formData, [input]: [...formData[input].concat(e)] });
					}
					return;
			}
		}

		if (!type) {
			setFormData({ ...formData, [input]: e.target.value });
		}
	};

	const handleSubmit = e => {
		e.preventDefault();
		if (formData.pin_f.join('') !== formData.pin_s.join('')) {
			prevStep();
			setFormData(state => ({ ...state, pin_f: [], pin_s: [] }));
		}
		localStorage.acc = formData.name;
		history.push('/menu');
	};

	const StepComponent = steps[step];

	return (
		<Layout fixed cross goBack={() => history.push('/')}>
			<Content>
				<VerticalContent>
					<VerticalText height={'50vh'} fontSize={14}>
						Step {step + 1} /{steps.length}
					</VerticalText>
					<VerticalText height={'50vh'}>{sideText[step]}</VerticalText>
				</VerticalContent>
				<MainContent>
					<StepComponent handleChange={handleChange} formData={formData} />
				</MainContent>
				<ButtonWrap>
					{step === 0 ? (
						<BackButton as={Link} to='/signup'>
							<span>Back to Methods</span>
						</BackButton>
					) : (
						<BackButton onClick={prevStep}>Back to step {step}</BackButton>
					)}

					{step === steps.length - 1 ? (
						<NextButton htmlType='submit' onClick={handleSubmit}>
							finish
						</NextButton>
					) : (
						<NextButton onClick={nextStep}>next</NextButton>
					)}
				</ButtonWrap>
			</Content>
		</Layout>
	);
};

export default withRouter(StepLayout);

const Content = styled(ContentGrid)`
	grid-row: 2;
	grid-template-columns: 70px 1fr;
	grid-template-rows: 2fr 1fr;

	@media screen and (min-width: 321px) {
		grid-template-columns: 90px 1fr;
	}
`;

const VerticalContent = styled.div`
	display: flex;
	margin-left: 20px;
	grid-row: 1;
	grid-column: 1;
`;

const MainContent = styled.div`
	grid-row: 1;
	grid-column: 2;
	padding-left: 10px;
`;
