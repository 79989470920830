import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { ButtonWithInnerIcon } from '../../../components';
import { Text } from '../../../components/sharedStyles';
import { iconWarning, iconLockWhite } from '../../../assets/images/icons';

const Icon = styled.div`
	width: 40px;
	height: 40px;
	background: url(${iconWarning}) 100% no-repeat;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
`;

const TextBottom = styled(Text)`
	width: 100%;
    text-align: center;
`;


const TextTop = styled(Text)`
	width: 55%;
    text-align: center;
`;

const ContentWrap = styled.div`
	display: grid;
	width: 100%;
	grid-row: 2;
	margin: 25px 0 20px;
`;

const WithoutAcc = (props) => {
	const { history } = props;
	const handleClick = () => history.push('/start-add-bank-account');

	return (
		<ContentWrap>
			<Container>
				<Icon />
				<TextTop>Add at least one bank account to receive payment</TextTop>
			</Container>
			<ButtonContainer>
				<TextBottom>Add account details to request payment</TextBottom>
				<ButtonWithInnerIcon icon={iconLockWhite} onClick={handleClick}>Add a bank account</ButtonWithInnerIcon>
			</ButtonContainer>
		</ContentWrap>
	);
};

export const WithoutAccount = withRouter(WithoutAcc);
