import React from 'react';

import { Wrapper, Transaction, Time } from './style'

const TransactionCard = ({ transaction, onSelectTransaction }) => {
	return (
		<Wrapper onClick={() => onSelectTransaction(transaction)}>
			<Time>{transaction.time}</Time>
			<Transaction>
				<Transaction.Header>{transaction.account.accountName}</Transaction.Header>
				<Transaction.Amount>{transaction.amount < 0 ? `-£${-transaction.amount}` : `£${transaction.amount}`}</Transaction.Amount>
				<Transaction.Details>{transaction.details}</Transaction.Details>
				<Transaction.Account icon={transaction.account.accountType}>{transaction.by}</Transaction.Account>
				<Transaction.Categories icon={transaction.categories}></Transaction.Categories>
			</Transaction>
		</Wrapper>
	);
};

export default TransactionCard;
