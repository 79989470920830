import styled from 'styled-components';
import { colors, ContentGrid } from '../sharedStyles';

export const Account = styled.div`
	grid-row: 1;
	grid-column: 1 / -1;
	padding: 0 20px 20px 20px;
`;

Account.Text = styled.div`
	margin-top: 10px;
	color: #ffffff;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.03em;
`;

export const Info = styled.div`
	grid-row: 3;
	grid-column: 1 / -1;
	display: flex;
	flex-direction: column;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 31px;
	text-align: right;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	color: #ffffff;
	padding-right: 20px;
`;

export const SideInstructions = styled.div`
	grid-row: 2;
	grid-column: 1;
	display: flex;
	flex-direction: column;
`;

export const Instruction = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	padding: 15px 10px;
	border-top: 2px solid #3c434d;
	box-sizing: border-box;

	&:last-child {
		border-bottom: 2px solid #3c434d;
	}
`;

Instruction.Icon = styled.div`
	position: relative;
	width: 24px;
	height: 24px;

	&:after {
		position: absolute;
		content: '';
		width: 100%;
		height: 100%;
		background-image: ${({ icon }) => `url(${icon})`};
		background-size: 100%;
		background-repeat: no-repeat;
	}
`;

Instruction.Text = styled.div`
	font-style: normal;
	font-weight: normal;
	font-size: 11px;
	line-height: 14px;
	text-align: center;
	letter-spacing: 0.04em;
	color: rgba(255, 255, 255, 0.6);
`;

export const QRWrap = styled.div`
	grid-column: 2;
	grid-row: 2;
	display: flex;
	justify-content: center;
	align-items: center;

	& > canvas {
		border: 5px solid ${colors.mainOrange};
	}
`;

export const ContentWrapper = styled(ContentGrid)`
	grid-row: 2;
	grid-column: 1;
	grid-template-columns: 100px 1fr;
	grid-template-rows: 1fr 3fr 1fr 1fr;
`;
