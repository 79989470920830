const SCAN_SUCCESS = 'SCAN_SUCCESS';
const BANKS_DATA_REQUEST = 'BANKS_DATA_REQUEST';
const CONTACTS_DATA_REQUEST = 'CONTACTS_DATA_REQUEST';
const BANKS_DATA_RECEIVED = 'BANKS_DATA_RECEIVED';
const CONTACTS_DATA_RECEIVED = 'CONTACTS_DATA_RECEIVED';
const ADD_PREFERABLE_METHOD = 'ADD_PREFERABLE_METHOD';
const ADD_PAYEE_ACCOUNT = 'ADD_PAYEE_ACCOUNT';
const CLEAR_DATA_OF_PAYMENT_RECIPIENT = 'CLEAR_DATA_OF_PAYMENT_RECIPIENT';
const SET_PAYMENT_AMOUNT = 'SET_PAYMENT_AMOUNT';
const SELECT_TRANSACTION = 'SELECT_TRANSACTION';
const REQUEST_PAYMENT_LINK = 'REQUEST_PAYMENT_LINK';
const ERROR_REQUEST_PAYMENT_LINK = 'ERROR_REQUEST_PAYMENT_LINK';
const PAYMENT_LINK_RESPONSE = 'PAYMENT_LINK_RESPONSE';
const CLEAR_PAYMENT_LINK = 'CLEAR_PAYMENT_LINK';
const BOPP_PAYEE_PAYMENT_LINK = 'BOPP_PAYEE_PAYMENT_LINK';
const GENERATE_BOPP_PAYEE_PAYMENT_LINK = 'GENERATE_BOPP_PAYEE_PAYMENT_LINK';
const SET_PAYMENT_FROM_URL = 'SET_PAYMENT_FROM_URL';
const SET_PAYEE_ACCOUNT = 'SET_PAYEE_ACCOUNT';
const SUBMIT_PAYMENT = 'SUBMIT_PAYMENT';
const REHYDRATE = 'REHYDRATE';
const PENDING = 'PENDING';
const REDIRECT = 'REDIRECT';
const SAVE_CONTACT = 'SAVE_CONTACT';
const ADD_RECEIVER_BANK_ACC = 'ADD_RECEIVER_BANK_ACC';
const SET_PREFER_RECEIVER_ACC = 'SET_PREFER_RECEIVER_ACC';

export {
	ADD_RECEIVER_BANK_ACC,
	SET_PREFER_RECEIVER_ACC,
	SCAN_SUCCESS,
	BANKS_DATA_REQUEST,
	CONTACTS_DATA_REQUEST,
	BANKS_DATA_RECEIVED,
	CONTACTS_DATA_RECEIVED,
	ADD_PREFERABLE_METHOD,
	ADD_PAYEE_ACCOUNT,
	CLEAR_DATA_OF_PAYMENT_RECIPIENT,
	SET_PAYMENT_AMOUNT,
	SELECT_TRANSACTION,
	REQUEST_PAYMENT_LINK,
	ERROR_REQUEST_PAYMENT_LINK,
	PAYMENT_LINK_RESPONSE,
	CLEAR_PAYMENT_LINK,
	BOPP_PAYEE_PAYMENT_LINK,
	GENERATE_BOPP_PAYEE_PAYMENT_LINK,
	SAVE_CONTACT,
	SET_PAYMENT_FROM_URL,
	SET_PAYEE_ACCOUNT,
	SUBMIT_PAYMENT,
	REHYDRATE,
	PENDING,
	REDIRECT,
};
