import React from 'react';
import { VerticalLink, Scanner, Layout } from '../../components';
import { ContentWrapper, VerticalText, Camera } from './style';
import { connect } from 'react-redux';
import { scanSuccess } from '../../actions';

const ScannerStartScreen = ({ scanSuccess, history }) => {
	const dev = () => {
		console.log('success');
		scanSuccess({
			amount: '121,333.99',
			account: {
				sortCode: '23 34 54',
				accountNumber: '11 11 11 11',
				accountName: 'Mrs. Somebody Random',
			},
		});
		history.push('/send-payment');
	};

	const devUnregisterSW = () => {
		navigator.serviceWorker.getRegistrations().then(function(registrations) {
			for (let registration of registrations) {
				registration.unregister();
			}
		});
	};

	return (
		<Layout black notifs next={() => history.push('/menu')} squish>
			<ContentWrapper>
				<VerticalLink href='/request-payment' />
				<VerticalText>
					<span>scan to pay</span>
				</VerticalText>
				<Camera>
					<Camera.Text>Scan a BOPP QR code to Pay</Camera.Text>
					<Camera.Container onClick={dev}>
						<Scanner history={history} />
					</Camera.Container>
				</Camera>
				<Camera.Disclaimer>
					BOPP is regulated by the UK
					<br />
					Financial Conduct Authority
					<br />
					<button onClick={devUnregisterSW}>DEV SW UNREGISTER</button>
				</Camera.Disclaimer>
			</ContentWrapper>
		</Layout>
	);
};

export default connect(
	null,
	{ scanSuccess }
)(ScannerStartScreen);
