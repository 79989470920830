import React, { useState } from 'react';
import { Layout } from '../../../components';
import {
	Content,
	VerticalContent,
	MainContent,
	WrapMobile,
	Text,
	Input,
} from './style';
import { VerticalText, ButtonWrap, NextButton } from '../../sharedStyles';

const Login = (props) => {
	const [v, set] = useState('');

	const handleChange = (e) => {
		set(e.target.value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (v === 'mpbopp$') {
			localStorage.f05a83f0 = '8be47f988395';
			if (props.location.hash && props.location.search) {
				const redirectPath = props.location.hash.substring(1) + props.location.search;
				props.history.push(redirectPath);
			} else {
				props.history.push('/');
			}
		} else {
			set('');
		}
	};
	return (
		<Layout clear fixed>
			<Content>
				<VerticalContent>
					<VerticalText height="50vh">Login</VerticalText>
				</VerticalContent>
				<MainContent>
					<WrapMobile>
						<Text>Enter password</Text>
						<Input type="password" onChange={handleChange} value={v} />
					</WrapMobile>
				</MainContent>
				<ButtonWrap>
					<NextButton onClick={handleSubmit}>Login</NextButton>
				</ButtonWrap>
			</Content>
		</Layout>
	);
};

export default Login;
