import styled from 'styled-components';

import { CardWithShadow, colors, Text, WrapCheckbox } from '../sharedStyles';
import { bank, iconSettingsOrange } from '../../assets/images/icons';

export const ContentWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    height: 100%;
`;

export const Account = styled(CardWithShadow)`
    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
    padding: 15px 0;
`;

Account.Header = styled.div`
    grid-column: 1 / 4;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 3fr 1fr;
`;

Account.IconLeft = styled.div`
    width: 40px;
    height: 40px;
    background-color: ${colors.darkOrange};
    position: relative;
    grid-row: 1 / 3;
    
	&:before {
		position: absolute;
		content: '';
		width: 24px;
		height: 24px;
		background-image: url(${bank});
		background-repeat: no-repeat;
		background-size: 100%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;

Account.IconRight = styled.div`
    width: 22px;
    height: 22px;
    position: relative;
    grid-row: 1 / 3;
    justify-self: end;
    align-self: center;
    margin-right: 11px;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background-image: url(${iconSettingsOrange});
        background-repeat: no-repeat;
        background-size: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

Account.Text = styled(Text)`
    justify-self: ${({ right }) => right ? 'end' : 'auto'};
    ${({ right }) => right && 'padding-right: 11px'};
    grid-row: ${({ row }) => row || 1};
    grid-column: ${({ column }) => column || 'auto'};

    @media screen and (min-width: 321px) {
		margin: 2px 0;
	}
`;

Account.Line = styled.div`
    grid-column: 2 / 4;
    border-top: 1px solid #000;
    margin: 8px 0;
`;

Account.WrapCheckbox = styled(WrapCheckbox)`
    grid-column: 1 / 5;
    margin-left: 10px;
`;
