import styled from 'styled-components';

import { colors } from '../sharedStyles';
import { cross } from '../../assets/images/icons';

export const Blackout = styled.div`
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    z-index: 1;
`;

export const Wrapper = styled.div`
    margin: auto;
    width: 85%;
    min-height: 10px;
    left: 10px;
    position: relative;
    z-index: 2;
    top: 30px;
`;

export const Background = styled.div`
    width: 100%;
    height: 100%;
    z-index: 0;
    position: absolute;
    background-color: ${colors.darkOrange};
    top: -60px;
    left: -20px;
`;

export const Title = styled.div`
    padding: 0 20px;
    width: 100%;
    font-size: 16px;
    line-height: 60px;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
`;

export const Close = styled.div`
    position: absolute;
    right: 20px;
    content: '';
    width: 24px;
    height: 24px;
    top: 50%;
    transform: translate(0, -50%);
    background: url(${cross});
    background-repeat: no-repeat;
    background-size: 100%;
`;

export const Content = styled.div`
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #fff;
    padding-bottom: 6px;
`;

export const Message = styled.div`
    padding: 30px 20px 30px 20px;
`;

export const Button = styled.button`
	border: none;
	height: 56px;
	margin: 4px 0;
	width: 90%;
	color: #ffffff;
	background-color: ${colors.mainBlack};
	font-style: normal;
	font-weight: 800;
	font-size: 16px;
    line-height: 22px;
    padding: 0 20px;
	text-transform: uppercase;
	display: flex;
    justify-content: flex-end;
    align-self: flex-end;
`;