import React from 'react';
import { AccountConfirmationScreen } from '../../screens';


export const AccountVerifiedPage = () => {
	const config = {
		vText: 'Account verified',
		bText: 'Next',
	};

	return (
		<AccountConfirmationScreen config={config} />
	);
};
