import React from 'react';
import { Wrapper, Text, LeftButton, RightButton } from './style';

const PlainHeader = ({ white, text, controls, rClick, lClick, children }) => {
	const { cross, burger, edit, back, plus, search } = controls;
	return (
		<Wrapper extend={children} white={white}>
			<LeftButton onClick={lClick} back={back} plus={plus} search={search} />
			<Text>
				<span>{text}</span>
			</Text>
			<RightButton onClick={rClick} cross={cross} edit={edit} burger={burger} />
			{children}
		</Wrapper>
	);
};

PlainHeader.defaultProps = {
	controls: {
		cross: false,
		burger: false,
		edit: false,
		back: false,
		plus: false,
		search: false,
	},
	rClick: () => {},
	lClick: () => {},
};

export default PlainHeader;
