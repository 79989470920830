import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Layout } from '../../components';
import { bank, check } from '../../assets/images/icons';
import { bankAccountBg } from '../../assets/images';
import {
	Text,
	colors,
	NextButton,
	VerticalText,
	CardWithShadow,
	CardWrapper,
} from '../../components/sharedStyles';

const BGIcon = styled.div`
	position: absolute;
	right: 0;
	bottom: 0;
	opacity: 0.1;
	width: 120px;
	height: 120px;
	background-image: url(${bankAccountBg});
	background-repeat: no-repeat;
	background-size: 100%;

	@media screen and (min-width: 321px) {
		width: 160px;
		height: 160px;
	}
`;

const Wrapper = styled.div`
	grid-row: 2;
	display: grid;
	grid-template-areas: 
		'v c c'
		'v c c'
		'b b b';
`;

const VerticalArea = styled(VerticalText)`
	grid-area: v;
`;

const TextTop = styled(Text)`
	width: 90%;
	text-align: start;
	margin-bottom: 20px;
	@media screen and (min-width: 321px) {
		margin-bottom: 30px;
	}
`;

const Content = styled.div`
	grid-area: c;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	margin-left: 30px;
`;

const Check = styled.div`
	width: 64px;
	height: 64px;
	background-image: url(${check});
	background-size: cover;
	background-repeat: no-repeat;
	margin-bottom: 15px;
`;

const ButtonContainer = styled.div`
	grid-area: b;
	display: flex;
	align-items: flex-end;
	padding-bottom: 20px; 
`;

const BankIcon = styled.div`
	width: 40px;
	height: 40px;
	background-image: url(${({ bankIcon }) => bankIcon ? bankIcon : bank});
	background-size: cover;
	background-repeat: no-repeat;
`;
const Line = styled.div`
	border: none;
	border-top: 1px solid ${colors.mainBlack};
	background-color: transparent;
	height: 1px;
	width: 100%;
	margin: 10px 0 10px;
`;

const AccDetails = styled.div`
	display: grid;
	grid-template-areas:
		'a b'
		'c d';
`;

const Card = styled(CardWrapper)`
	height: auto;
	min-height: 150px;
`;

const CardText = styled.div`
	font-style: normal;
	font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
	color: ${({ black }) => (black ? colors.mainBlack : `rgba(${colors.mainBlackOP}, 0.6)`)};
	text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
	font-size: ${({ small }) => (small ? '13px' : '15px')};
	line-height: 140%;
`;


const AccountConfirmation = (props) => {
	const {
		accountName,
		accountNumber,
		sortCode,
		bankName,
		history,
		config,
	} = props;
	const layoutConfig = {
		cross: true,
		rightButtonHandler: () => history.push('/'),
	};
	const next = () => history.push('/start-payment-request');

	return (
		<Layout config={layoutConfig}>
			<Wrapper>
				<VerticalArea fontSize={36} height="auto">{config.vText}</VerticalArea>
				<Content>
					<Check />
					<TextTop>Your bank account has been added</TextTop>
					<Card alignSelf="flex-end" width="100%">
						<CardWithShadow>
							<BankIcon bankIcon="" />
							<CardText bold black>{bankName}</CardText>
							<CardText small>{accountName}</CardText>
							<Line />
							<AccDetails>
								<CardText small>Account number</CardText>
								<CardText small bold black>{accountNumber}</CardText>
								<CardText small>Sort Code</CardText>
								<CardText small bold black>{sortCode}</CardText>
							</AccDetails>
						</CardWithShadow>
					</Card>
				</Content>
				<ButtonContainer>
					<NextButton onClick={next}>{config.bText}</NextButton>
				</ButtonContainer>
				<BGIcon />
			</Wrapper>
		</Layout>
	);
};

AccountConfirmation.defaultProps = {
	config: {
		vText: 'ADDED',
		bText: 'Next',
	},
};

AccountConfirmation.propTypes = {
	config: PropTypes.shape({
		vText: PropTypes.string,
		bText: PropTypes.string,
	}),
};

const mapStateToProps = (state) => ({
	accountName: state.preferableReceiverAccount.accountName,
	accountNumber: state.preferableReceiverAccount.accountNumber,
	sortCode: state.preferableReceiverAccount.sortCode,
	bankName: state.preferableReceiverAccount.bankName,
});

export const AccountConfirmationScreen = connect(mapStateToProps, null)(withRouter(AccountConfirmation));
