import styled from 'styled-components';
import { colors, SkeletonPulse } from '../../components/sharedStyles';
import { iconSearch } from '../../assets/images/icons';

export const SkeletonItem = styled(SkeletonPulse)`
	width: 100%;
	height: 50px;
	margin-bottom: 10px;
`;

export const List = styled.div`
	overflow-x: auto;
	padding: 15px 20px;
`;

export const Search = styled.div`
	width: 90%;
	height: 100%;
	grid-column: 1 / -1;
	grid-row: 2;
	justify-self: center;
	align-self: center;
	position: relative;

	&:before {
		position: absolute;
		content: '';
		width: 24px;
		height: 24px;
		top: 15px;
		left: 12px;
		background-size: 100%;
		background-repeat: no-repeat;
		background-image: url(${iconSearch});
	}
`;

export const Input = styled.input`
	outline: none;
	width: 100%;
	height: 50px;
	border: none;
	border-radius: 0px;
	border-bottom: 1px solid ${colors.mainBlack};
	background-color: rgba(${colors.mainBlackOP}, 0.1);
	padding: 15px 10px 15px 45px;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 140%;

	&::placeholder {
		color: ${colors.mainBlack};
		opacity: 0.7;
	}
`;
