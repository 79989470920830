import styled from 'styled-components';
import { ContentGrid, colors } from '../sharedStyles';
import lock from '../../assets/images/lock-gray.svg';

export const ContentWrapper = styled(ContentGrid)`
	grid-row: 2;
	grid-column: 1;
	grid-template-rows: 1.5fr 3fr;
	grid-template-columns: 30px 0.7fr auto;
`;

export const VerticalText = styled.div`
	grid-row: 1 / 2;
	grid-column: 2;
	width: 100px;
	height: 335px;
	position: relative;
	& > span {
		font-weight: 800;
		display: block;
		height: 100px;
		width: 335px;
		position: absolute;
		bottom: 0;
		left: 0;
		transform: rotate(270deg) translateX(-30%);
		transform-origin: 0 0;
		text-transform: uppercase;
		font-size: 40px;
		line-height: 120%;
		letter-spacing: 0.05em;
		color: #ffffff;
		text-align: right;
		padding: 18px 0;
	}
`;

export const Camera = styled.div`
	grid-row: 1;
	grid-column: 3;
	display: flex;
	flex-direction: column;
	padding-right: 20px;
`;

Camera.Text = styled.div`
	color: rgba(255, 255, 255, 0.6);
	font-size: 15px;
	line-height: 140%;
	padding-bottom: 10px;
`;

Camera.Container = styled.div`
	max-width: 220px;
	max-height: 220px;
	background-color: #3c434d;
	position: relative;
	box-sizing: border-box;

	&::after {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: ${colors.mainBlack};
		content: '';
		top: 17px;
		left: -17px;
		z-index: -1;
	}

	&::before {
		position: absolute;
		width: calc(100% + 20px);
		height: 3px;
		top: 0;
		left: -10px;
		background-color: ${colors.mainOrange};
		z-index: 1;
		content: '';
		animation: scanning 1.2s linear infinite;

		@media screen and (max-width: 320px) {
			animation: scanning5s 1.2s linear infinite;
		}
	}

	@keyframes scanning {
		100% {
			transform: translate(0, 220px);
		}
	}

	@keyframes scanning5s {
		100% {
			transform: translate(0, 170px);
		}
	}
`;

Camera.Disclaimer = styled.div`
	grid-row: 2;
	grid-column: 3;
	color: rgba(255, 255, 255, 0.8);
	align-self: center;
	position: relative;

	&:before {
		width: 16px;
		height: 16px;
		position: absolute;
		left: -20px;
		content: '';
		background-size: 100%;
		background-repeat: no-repeat;
		background-image: url(${lock});
	}
`;
