import React, { useEffect } from 'react';
import { VerticalLink, Layout, NumPanel, Amount, ButtonWithArrow, ButtonWLeftIcon } from '../../components';
import { ContentWrapper, ReqPaymentContent, Wrap, Text, ButtonSection } from './style';
import { VerticalText } from '../sharedStyles';
import { connect } from 'react-redux';
import { generatePayeeLink } from '../../actions';

const RequestPaymentScreen = ({ account, amount, url, boppLink, history, ...props }) => {
	useEffect(() => {
		if (url || boppLink) {
			history.push('/scan-qr-code-to-pay');
		}
	}, [url, boppLink]);

	return (
		<Layout next={() => history.push('/menu')} squish fixed>
			<ContentWrapper>
				<VerticalLink href='/' black={true} />
				<Wrap>
					<VerticalText height={'40vh'}>Get payment</VerticalText>
				</Wrap>
				<ReqPaymentContent>
					<ReqPaymentContent.Header>Enter the amount you want</ReqPaymentContent.Header>
					<Amount amount={amount} />
					<NumPanel />
				</ReqPaymentContent>
				<ButtonSection>
					<Text>to</Text>
					{account ? (
						<ButtonWLeftIcon
							icon={'userInvert'}
							largeIcon
							twoRows
							desc={`${account.sortCode}, ${account.accountNumber}`}
							white
							to='/account-details'
							change
						>
							{account.accountName}
						</ButtonWLeftIcon>
					) : (
						<ButtonWLeftIcon to='/account-details' icon='plus'>
							Add Account Details
						</ButtonWLeftIcon>
					)}
				</ButtonSection>
				{account && (
					<ButtonWithArrow
						onClick={props.generatePayeeLink}
						bottom
						shortText
						header={'Create payment request'}
					/>
				)}
			</ContentWrapper>
		</Layout>
	);
};

function mapStateToProps(state) {
	return {
		amount: state.accountDetails.amount,
		account: state.accountDetails.account,
		url: state.openBanking.redirectURL,
		boppLink: state.openBanking.link,
	};
}

export default connect(
	mapStateToProps,
	{ generatePayeeLink }
)(RequestPaymentScreen);
