import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Background, ClearLayout, colors } from '../sharedStyles';
import { PlainHeader } from '../../components';
import TransactionsGroup from './TransactionsGroup';
import { selectTransaction } from '../../actions';
import { close, iconSearch } from '../../assets/images/icons';

const TransactionsScreen = ({ selectTransaction, history, transactionsGroups }) => {
	const [ search, setSearch ] = useState(false);
	const [ input, setInput ] = useState('');
	const [ list, setList ] = useState([]);

	const onSelect = transaction => {
		selectTransaction(transaction);
		history.push('/transaction-details');
	}

	const filterList = (e, clear) => {
		if (clear) {
			setInput('');
			setList(transactionsGroups);
		} else {
			setInput(e.target.value);
			const filteredList = [];
			if (transactionsGroups.length) {
				transactionsGroups.forEach((tGroup) => {
					const transactions = tGroup.transactions.filter((t) => {
						return t.account.accountName.toLowerCase().search(e.target.value.toLowerCase()) !== -1 ||
							   t.by.toLowerCase().search(e.target.value.toLowerCase()) !== -1
					})
					if (transactions.length) {
						filteredList.push({ transactions, date: tGroup.date })
					}
				});
			}
			setList(filteredList);
		}
	};

	const closeSearch = () => {
		filterList(null, true)
		setSearch(false)
	}

	useEffect(
		() => {
			setList(transactionsGroups);
		},
		[ transactionsGroups ],
	);

	return (
		<Background>
			<ClearLayout tRows={'1fr 10fr'}>
				<PlainHeader lClick={() => setSearch(true)} rClick={() => history.push('/menu')} white controls={{ burger: true, search: true }} text={'Transactions'}>
					{search &&
						<Search>
							<Icon icon={iconSearch}/>
							<Input
								placeholder={`Search a transaction..`}
								value={input}
								onChange={filterList}
							/>
							<Icon onClick={closeSearch} right={true} icon={close}/>
						</Search>}
				</PlainHeader>
				<ContentWrap>
					{list.map((tGroup, i) => (
						i === 1
						?
							<TransactionsGroup key={tGroup.date} date={tGroup.date} transactionsList={tGroup.transactions} onSelectTransaction={onSelect}/>
						:
							<TransactionsGroup refresh={true} key={tGroup.date} date={tGroup.date} transactionsList={tGroup.transactions} onSelectTransaction={onSelect}/>
					))}
				</ContentWrap>
			</ClearLayout>
		</Background>
	);
};

TransactionsScreen.defaultProps = {
	transactionsGroups: [{
		date: 1566571803468,
		transactions: [{
			time: '12:31',
			amount: '-12.99',
			details: 'International House, 12 Constance Street, London. E16 2DQ',
			by: 'Barclays',
			account: {
				accountName: 'Central London Cafe',
				accountType: 'bank',
				sortCode: '1243 1452 3512',
			},
			categories: 'food',
		}, {
			time: '12:29',
			amount: '-7.99',
			details: 'International House, 12 Constance Street, London. E16 2DQ',
			by: 'Mom',
			account: {
				accountName: 'Mango Outlet',
				accountType: 'user',
				sortCode: '1243 1452 3512',
			},
			categories: 'shopping',
		}],
	},
	{
		date: 1667574803769,
		transactions: [{
			time: '13:57',
			amount: '-15.99',
			details: 'International House, 12 Constance Street, London. E16 2DQ',
			by: 'Barclays',
			account: {
				accountName: 'Central London Cafe',
				accountType: 'user',
				sortCode: '1243 1452 3512',
			},
			categories: 'food',
		}],
	}],
};

function mapStateToProps(state) {
	return {
		selectedTransaction: state.transaction,
	};
}

export default connect(
	mapStateToProps,
	{ selectTransaction },
)(withRouter(TransactionsScreen));

const ContentWrap = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
	grid-row: 2;
	width: 100%;
	height: 100%;
`;

export const Search = styled.div`
	width: 100%;
	height: 100%;
	justify-self: center;
	align-self: center;
	position: absolute;
	background-color: white;
`;

export const Input = styled.input`
	outline: none;
	width: 100%;
	height: 100%;
	border: none;
	background-color: #fff;
	padding: 15px 10px 15px 55px;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 140%;

	&::placeholder {
		color: ${colors.mainBlack};
		opacity: 0.7;
	}
`;

const Icon = styled.div`
	position: absolute;
	content: '';
	width: 24px;
	height: 24px;
	top: 50%;
	transform: translate(0, -50%);
	right: ${({ right }) => right ? `23px` : `auto`};
	left: ${({ right }) => !right ? `22px` : `auto`};
	background-size: 100%;
	background-repeat: no-repeat;
	background-image: ${({ icon }) => `url(${icon})`};
`;
