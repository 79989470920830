import React, { useEffect, useState } from 'react';
import { Wrapper, NumButton } from './style';
import { connect } from 'react-redux';
import { setAmountForPayer } from '../../actions';

const NumPanel = props => {
	const buttons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 'c', 0, 'b'];
	const [amount, setAmount] = useState(props.amount);

	useEffect(() => {
		props.setAmountForPayer(amount);
	}, [amount, setAmountForPayer]);

	const formatAmount = (amount, num, remove) => {
		const [pound, pence] = amount.replace(/,/g, '').split('.');
		const poundArr = pound.split('');
		const penceArr = pence.split('');
		const amnt = poundArr.concat(penceArr);
		if (remove) {
			amnt.pop();
			if (amnt.length < 3) {
				amnt.unshift(0);
			}
		} else {
			if (amnt.length === 9) return amount;
			if (amnt[0] === 0 || amnt[0] === '0') {
				amnt.shift();
			}
			amnt.push(num);
		}

		return (
			amnt
				.slice(0, -2)
				.join('')
				.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') +
			'.' +
			amnt.slice(-2).join('')
		);
	};

	const buttonClick = val => {
		switch (val) {
			case 'c':
				return setAmount('0.00');
			case 'b':
				return setAmount(formatAmount(amount, 0, true));
			default:
				return setAmount(formatAmount(amount, val));
		}
	};

	return (
		<Wrapper>
			{buttons.map(value => {
				const isNumeric = value !== 'c' && value !== 'b';
				return (
					<NumButton inner={value} key={value} onClick={() => buttonClick(value)} isNumeric={isNumeric}>
						{!isNumeric || value}
					</NumButton>
				);
			})}
		</Wrapper>
	);
};

function mapStateToProps(state) {
	return {
		amount: state.accountDetails.amount,
	};
}

export default connect(
	mapStateToProps,
	{ setAmountForPayer, a: null }
)(NumPanel);
