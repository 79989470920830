import React from 'react';
import { Layout, ButtonWLeftIcon } from '../../components';
import { connect } from 'react-redux';
import { Text } from '../sharedStyles';
import styled from 'styled-components';
import { ContentGrid } from '../sharedStyles';

const PaymentPreferences = ({ history, transaction, ...props }) => {
	return (
		<Layout cross goBack={() => history.push('/')}>
			<WrapContentGrid>
				<Text>Please, confirm the payment</Text>
				<Text big bold>
					£{transaction.amount}
				</Text>
				<ButtonWLeftIcon
					white
					width='90%'
					desc={'sdf'}
					largeIcon
					icon='bankInvert'
					to='/add-payment-method'
					arrow
					twoRows
				>
					Add Payment Method
				</ButtonWLeftIcon>
				<ButtonWLeftIcon width='90%' icon='plus' to='/add-payment-method'>
					Add Payment Method
				</ButtonWLeftIcon>
			</WrapContentGrid>
		</Layout>
	);
};

function mapStateToProps(state) {
	return {
		transaction: state.dataForOutgoingPayment,
	};
}

export default connect(
	mapStateToProps,
	null
)(PaymentPreferences);

const WrapContentGrid = styled(ContentGrid)`
	display: flex;
	flex-direction: column;
	grid-row: 2;
	grid-column: 1;
	position: relative;
	padding: 0 20px;
`;
