import {
	PAYMENT_LINK_RESPONSE,
	CLEAR_PAYMENT_LINK,
	BOPP_PAYEE_PAYMENT_LINK,
	ERROR_REQUEST_PAYMENT_LINK,
	REHYDRATE,
	PENDING,
	REDIRECT,
} from '../actions/constants';

const initState = { pending: false, result: false };

const openBanking = (state = initState, action) => {
	switch (action.type) {
		case REHYDRATE:
			return { ...state, ...action.localData };
		case PENDING:
			return { ...state, pending: true };
		case REDIRECT:
			return { ...state, result: true };
		case PAYMENT_LINK_RESPONSE:
			return { ...state, pending: false, ...action.payload };
		case BOPP_PAYEE_PAYMENT_LINK:
			return { ...state, link: action.link };
		case CLEAR_PAYMENT_LINK:
			return { ...initState };
		case ERROR_REQUEST_PAYMENT_LINK:
			return { ...state, pending: false };
		default:
			return state;
	}
};

export default openBanking;
