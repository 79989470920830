const preferableMethods = (state = { accountName: 'Barclays', listType: 'bank', api: 'barclays' }, action) => {
	switch (action.type) {
		case 'ADD_PREFERABLE_METHOD':
			return { ...action.prefMethod };
		default:
			return state;
	}
};

export default preferableMethods;
