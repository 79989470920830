import React from 'react';
import { StyledLink, Arrow, Text } from './style';


const VerticalLink = ({ href, black, row }) => {
	return (
		<StyledLink className='vertical-link' row={row} to={href} black={black ? 1 : 0} >
			<Arrow className='arrow' black={black} />
			<Text className='link-text' black={black} >{black ? 'Make\u00A0Payment' : 'Request\u00A0Payment'}</Text>
		</StyledLink>
	);
};

export default VerticalLink;