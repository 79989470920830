import React from 'react';
import styled from 'styled-components';
import { colors, VerticalText, ContentGrid } from '../sharedStyles';
import { PinPanel, PinInput, ButtonWithArrow } from '..';
import { Link } from 'react-router-dom';

const Step2 = ({ loginData, handleChange, prevStep, ...props }) => {
	return (
		<Content>
			<VerticalContent>
				<VerticalText height={'50vh'} fontSize={14}>
					{`${loginData.countryCode} ${loginData.phone}`}
				</VerticalText>
				<VerticalText height={'50vh'}>Enter your PIN</VerticalText>
			</VerticalContent>
			<PinWrapper>
				<PinInput pin={loginData.pin} />
				<PinPanel handler={handleChange('pin', 'pin')} />
			</PinWrapper>
			<WrapButton>
				<TextButton onClick={prevStep}>Change Mobile</TextButton>
				<TextButton as={Link} to='/'>
					Forgot PIN?
				</TextButton>
			</WrapButton>
			<ButtonWithArrow bottom header={'Get Started'} descr={'Don’t you have an account?'} />
		</Content>
	);
};

export default Step2;

const TextButton = styled.button`
	border: none;
	background: none;
	text-transform: uppercase;
	font-style: normal;
	font-weight: 800;
	font-size: 14px;
	line-height: 24px;
	width: 130px;
	height: 24px;
	color: ${colors.mainBlack};
`;

const WrapButton = styled.div`
	grid-column: 1 / -1;
	grid-row: 2;
	display: flex;
	flex-direction: row;
	align-items: centrer;
	width: 100%;
	padding-left: 20px;
	justify-content: space-evenly;
`;

const PinWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding: 0 15%;
`;

const Content = styled(ContentGrid)`
	grid-row: 2;
	grid-template-columns: 70px 1fr;
    grid-template-rows: 2fr 1fr;
	row-gap: 15px;
    
    @media screen and (min-width: 321px) {
        grid-template-columns: 90px 1fr;
	}
`;

const VerticalContent = styled.div`
	display: flex;
	margin-left: 20px;
	grid-row: 1;
	grid-column: 1;
`;
