const initialState = { account: {}, amount: '0.00' };

const accountDetails = (state = initialState, action) => {
	switch (action.type) {
		case 'ADD_PAYEE_ACCOUNT':
			return { ...state, account: { ...action.account } };
		case 'CLEAR_DATA_OF_PAYMENT_RECIPIENT':
			return { ...initialState };
		case 'SET_PAYMENT_AMOUNT': {
			return { ...state, amount: action.amount };
		}
		default:
			return state;
	}
};

export default accountDetails;
